import { AuthenticatedRoutesWrapper } from '@multiversx/sdk-dapp/wrappers/AuthenticatedRoutesWrapper';
import React from 'react';
import { useLocation } from 'react-router-dom';
import routes, { routeNames } from 'routes';
import bgVideo from '../../assets/bg-video.mp4';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  const location = useLocation();
  if (Object.values(routeNames).indexOf(location.pathname) < 0) {
    window.location.href = '/';
    return <></>;
  }
  return (
    <div className='d-flex flex-column flex-fill wrapper appMainBackground'>
      <Navbar />
      <main className='d-flex flex-column flex-grow-1 appBodyBackground'>
        {/* <video autoPlay muted playsInline loop className='bg-video'>
          <source src={bgVideo} type='video/mp4' />
        </video>
        <div className='appBodyBackground body-shadow'></div> */}
        <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${search}`}
        >
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
