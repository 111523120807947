import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Timer from 'components/timer';
import CommonHelper from 'Helper/common-helper';
import { routeNames } from 'routes';
import { PawnNFT } from './types/PawnLoanType';
import { maxNftSelection } from 'config';

function PawnedNftCard(props: any) {
  const pawnedNFT: PawnNFT = props.pawnedNFT;
  const { action, isSelected, selectedCount } = props;
  const navigate = useNavigate();
  let nonceInHex = pawnedNFT.nonce.toString(16);
  if (nonceInHex.length % 2 == 1) {
    nonceInHex = `0${nonceInHex}`;
  }
  const [loanExpired, setLoanExpired] = React.useState<boolean>(false);
  const [isFlipped, setIsFlipped] = React.useState(false);
  const [cardSelected, setCardSelected] = React.useState(false);
  const checkDetails = () => {
    // let nonceInHex = pawnedNFT.nonce.toString(16);
    // if (nonceInHex) {
    //   if (nonceInHex.length % 2 == 1) {
    //     nonceInHex = `0${nonceInHex}`;
    //   }
    // }
    navigate(routeNames.claimNFT, {
      state: {
        selectedNftIdentifiers: [
          `${pawnedNFT.tokenIdentifier}-${nonceInHex}-${pawnedNFT.nftCounter}`
        ]
      }
    });
  };

  const selectNFT = () => {
    isSelected(
      !cardSelected,
      `${pawnedNFT.tokenIdentifier}-${nonceInHex}-${pawnedNFT.nftCounter}`
    );
  };
  const isloanExpired = (data: boolean) => {
    setLoanExpired(data);
  };
  return (
    <Col
      key={pawnedNFT.tokenIdentifier}
      xs={6}
      md={4}
      lg={3}
      className='scene scene--card'
    >
      <Card border='info' className={isFlipped ? 'is-flipped' : ''}>
        <div className='NFT__Card card__face card__face--front note'>
          <div
            className='NFT__Card--Img_Container cur-pointer'
            onClick={() => setIsFlipped(!isFlipped)}
          >
            <video
              poster={pawnedNFT.url}
              autoPlay
              muted
              playsInline
              loop
              className='NFT__Card--Img'
            >
              <source src={pawnedNFT.url} type='video/mp4' />
            </video>
          </div>
          <Card.Body className='NFT__CardBody'>
            <div className='NFT__CardBody-details'>
              <Card.Title className='NFT__CardBody--Title'>
                {/* {pawnedNFT.tokenIdentifier}-{nonceInHex} */}
                {pawnedNFT.nftCommonName}
              </Card.Title>
            </div>
            {/* <Card.Title className='NFT__CardBody--Title'>BOOSTED: {boostLevel}</Card.Title>
              <Card.Title className='NFT__CardBody--Title'>
                LOAN TERM: {Math.floor(Difference_In_Days)}{' '}
                {Math.floor(Difference_In_Days) > 1 ? 'days' : 'day'}
              </Card.Title> */}
            <Card.Title className='NFT__CardBody--Title'>
              Amount Due:{' '}
              {CommonHelper.fixedNum(
                (pawnedNFT.loanAmount + pawnedNFT.pawnFee) / 10 ** 18,
                4
              )}{' '}
              EGLD
            </Card.Title>
            {/* <Card.Title className='NFT__CardBody--Title'>LOAN BONUS: {loanBoostAmount}</Card.Title>
              <Card.Title className='NFT__CardBody--Title'>FEE DUE: {pawnFee}</Card.Title>
              <Card.Title className='NFT__CardBody--Title'>FEE Discount: {feeDiscount}</Card.Title> */}
            <Card.Title className='NFT__CardBody--Title'>
              <span>
                Loan Due by:{' '}
                <span className={loanExpired ? 'text-red' : ''}>
                  <Timer
                    startTime={new Date().getTime()}
                    endTime={pawnedNFT.extendedExpiryDate * 1000}
                    isloanExpired={(data: boolean) => isloanExpired(data)}
                  ></Timer>
                </span>
              </span>
            </Card.Title>
            {action == 'active' ? (
              <button
                className={
                  (cardSelected ? 'selected' : '') +
                  ' NFT__CardBody--ButtonStack btn btn-primary'
                }
                onClick={() => {
                  selectNFT();
                  setCardSelected(!cardSelected);
                }}
                disabled={selectedCount >= maxNftSelection && !cardSelected}
              >
                {cardSelected ? 'SELECTED' : 'SELECT'}
              </button>
            ) : (
              <button
                className='NFT__CardBody--ButtonStack btn btn-primary'
                onClick={() => checkDetails()}
                // disabled={loanExpired}
              >
                CHECK DETAILS
              </button>
            )}
          </Card.Body>
        </div>
        <div
          className='card__face card__face--back '
          onClick={() => setIsFlipped(!isFlipped)}
        >
          <div className='card__face--back-container note'>
            <Card.Title className='orange-text font-18 text-center'>
              {pawnedNFT.tokenIdentifier}-{nonceInHex}
            </Card.Title>
            <Card.Title className='orange-text font-18 text-center'>
              Tags:
            </Card.Title>
            {pawnedNFT.tags.length ? (
              <div className='nft-tags-container'>
                {pawnedNFT.tags.map((tag: string, index: number) => (
                  <span className='nft-tags' key={index}>
                    {tag}
                  </span>
                ))}
              </div>
            ) : (
              <Card.Title className='text-white'>No Tags Available</Card.Title>
            )}
          </div>
        </div>
      </Card>
    </Col>
  );
}
PawnedNftCard.defaultProps = {
  tags: [],
  action: ''
};
export default PawnedNftCard;
