import * as React from 'react';
import { useRef } from 'react';
import {
  useGetAccountInfo,
  useGetLoginInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { LoginMethodsEnum } from '@multiversx/sdk-dapp/types';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import converter from 'bech32-converting';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import ConfirmModal from 'components/ConfirmModal';
import {
  baseURL,
  explorer_url,
  passCollectionIdentifier,
  pawnShopContractAddress //,
  // stakingContractAddress
} from 'config';
import CommonHelper from 'Helper/common-helper';
import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';
import StakingSmartContract from 'Helper/staking-smart-contract';
import { routeNames } from 'routes';
import Bishop from '../../src/assets/Bishop.png';
import DeadRare from '../../src/assets/DeadRare.png';
import FrameIt from '../../src/assets/FrameIt.png';
import FullSet from '../../src/assets/FullSet.png';
import King from '../../src/assets/King.png';
import Knight from '../../src/assets/Knight.png';
import Mint from '../../src/assets/Mint.png';
import NoBoost from '../../src/assets/NoBoost.png';
import Pawn from '../../src/assets/Pawn.png';
import Queen from '../../src/assets/Queen.png';
import Rook from '../../src/assets/Rook.png';
import Xoxno from '../../src/assets/Xoxno.png';
import loader from '../assets/loader.gif';
import NftCard from './NftCard';
import TrackTransaction from './TrackTransaction';
import { NFTmessage } from './types/NFTMessage';
import {
  BoostInfo,
  BoostLevel,
  NFTCollection,
  PawnFeeInfo,
  PawnNFT
} from './types/PawnLoanType';
import { PassInfo } from './types/StakingTypes';
import StepsModule from 'components/stepsModule';
import ModalCard from 'components/ModalCard';

const PawnNFTComponent = () => {
  const { address } = useGetAccountInfo();
  const { loginMethod } = useGetLoginInfo();
  const { network } = useGetNetworkConfig();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [availableStakedPasses, setAvailableStakedPasses] = React.useState<{
    [level: number]: PassInfo[];
  }>({});
  // const [whalePassAvailable, setWhalePassAvailable] =
  //   React.useState<boolean>(false);
  const [pawnFeeInfo, setPawnFeeInfo] = React.useState<PawnFeeInfo[]>([]);
  const [boostersInfo, setBoostersInfo] = React.useState<BoostInfo[]>([]);
  const [selectedNFTsForPawn, setSelectedNFTsForPawn] = React.useState<
    PawnNFT[]
  >([]);
  // const [tierLoanInfo, setTierLoanInfo] = React.useState<TierLoanInfo>();
  const [nftInfo, setNftInfo] = React.useState<any>();
  const [tags, setTags] = React.useState<string[]>([]);
  const [sessionStateId, setSessionId] = React.useState<string>('');
  const [selectedBooster, setSelectedBooster] = React.useState<BoostInfo>();
  const [reqLoanAmt, setReqLoanAmt] = React.useState<any>();
  const [msgObj, setMsgObj] = React.useState<NFTmessage>();
  const [confirmPawn, setConfirmPawn] = React.useState<NFTmessage>();
  const [legalCb, setLegalCb] = React.useState<boolean>(false);
  const [selectedPawnDuration, setSelectedPawnDuration] =
    React.useState<PawnFeeInfo>();
  const [nftCollectionInfo, setNFTCollectionInfo] =
    React.useState<NFTCollection>();
  const [activeStepState, setActiveStepState] = React.useState<number>(0);
  const [openModal, setOpenModal] = React.useState(false);

  // const [pawnOperationCompleted, setPawnOperationCompleted] =
  //   React.useState<boolean>(false);
  const pawnOperationCompletedRef = useRef(false);
  const CarouselImages: any = {
    Bishop,
    King,
    Knight,
    Pawn,
    Queen,
    Rook,
    FullSet,
    NoBoost
  };
  // console.log(CarouselImages);
  const reqLoan = React.useRef<any>();
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  const location: any = useLocation();
  const [urlParams] = useSearchParams();
  let activeStep = 2;
  let { selectedNftIdentifiers } = location.state ? location.state : '';
  if (!location.state && isLoading) {
    activeStep = 3;
  }
  if (selectedNftIdentifiers == undefined || null) {
    selectedNftIdentifiers = urlParams.get('nftIdentifier')?.split(',');
    if (selectedNftIdentifiers == undefined || null) {
      window.location.href = routeNames.collections;
      //Page redirect to collections page
    }
  }

  const nftCollection = `${selectedNftIdentifiers[0].split('-')[0]}-${
    selectedNftIdentifiers[0].split('-')[1]
  }`;

  // const nftNonce = nftIdentifier.split('-')[2];

  async function loadPawnFeeDetails() {
    // get all available duration and fee
    const pawnDurationsWithFeeFromSC =
      await PawnShopSmartContract.getAllPawnFeeInfo(proxy);
    const allPawnFeeInfo: PawnFeeInfo[] = [];
    for (let index = 0; index < pawnDurationsWithFeeFromSC.length; index++) {
      const feeInfo = pawnDurationsWithFeeFromSC[index];
      const duration = feeInfo.loan_duration_ticks.toNumber();
      let durationToDisplay = '';
      if (duration % (24 * 60 * 60) == 0) {
        durationToDisplay = `${duration / (24 * 60 * 60)} Days`;
      } else if (duration % (60 * 60) == 0) {
        durationToDisplay = `${duration / (60 * 60)} Hours`;
      } else if (duration % 60 == 0) {
        durationToDisplay = `${duration / 60} Minute`;
      }

      const pawnFee: PawnFeeInfo = {
        loanDurationTicks: feeInfo.loan_duration_ticks.toNumber(),
        loanDurationForDisplay: durationToDisplay,
        feePercentage: feeInfo.fee_percentage.toNumber(),
        maxAllowedLoanExtensionTicks:
          feeInfo.max_allowed_loan_extension_ticks.toNumber()
      };
      allPawnFeeInfo.push(pawnFee);
    }
    const sortedAllPawnFeeInfo = allPawnFeeInfo.sort((p1, p2) =>
      p1.loanDurationTicks < p2.loanDurationTicks
        ? 1
        : p1.loanDurationTicks > p2.loanDurationTicks
        ? -1
        : 0
    );
    // allPawnFeeInfo.reverse();
    setPawnFeeInfo(sortedAllPawnFeeInfo);
    // const x = [
    //   { duration: 3, feePercentage: 10 },
    //   { duration: 5, feePercentage: 15 },
    //   { duration: 7, feePercentage: 20 }
    // ];
    // setPawnFeeInfo(x);
  }
  React.useEffect(() => {
    setSelectedPawnDuration(pawnFeeInfo[0]);
  }, [pawnFeeInfo]);

  // get all Boost Info
  async function loadAllBoostInfoDetails() {
    const boostersInfoFromSC = await PawnShopSmartContract.getAllBoostersInfo(
      proxy
    );
    const allBoostersInfo: BoostInfo[] = [];
    for (let index = 0; index < boostersInfoFromSC.length; index++) {
      const boosterInfoFromSC = boostersInfoFromSC[index];
      const boosterInfo: BoostInfo = {
        level: boosterInfoFromSC.level.name.toString(),
        loanBoostPercentage: boosterInfoFromSC.loan_boost_percentage.toNumber(),
        loanFeeDiscountPercentage:
          boosterInfoFromSC.loan_fee_discount_percentage.toNumber()
      };
      allBoostersInfo.push(boosterInfo);
    }

    setBoostersInfo(allBoostersInfo);
  }

  // get loan info for this tier
  // async function loadCollectionTierLoanDetails() {
  //   const tierLoanInfoFromSC = await PawnShopSmartContract.getLoanInfoByTier(
  //     proxy,
  //     0
  //   );
  //   const tierLoanInfoObj: TierLoanInfo = {
  //     collectionTier: tierLoanInfoFromSC.collection_tier.name.toString(),
  //     collectionMaxLoanRatePercentage:
  //       tierLoanInfoFromSC.collection_max_loan_rate_percentage.toNumber()
  //   };

  //   // setTierLoanInfo(tierLoanInfoObj);
  // }

  //get all staked passes for user
  async function loadAllStakedPassesForUser() {
    const stakedPassesFromSc =
      await StakingSmartContract.getStakedPassesForUser(
        address,
        passCollectionIdentifier,
        proxy
      );
    const passes: { [level: number]: PassInfo[] } = {};

    // const passes: PassInfo[] = [];
    for (let index = 0; index < stakedPassesFromSc.length; index++) {
      const stakedPass = stakedPassesFromSc[index];
      if (!stakedPass.pass_in_use) {
        const pass: PassInfo = {
          tokenIdentifier: stakedPass.token_identifier.toString(),
          nftNonce: stakedPass.nft_nonce.toNumber(),
          level: stakedPass.level.toNumber(),
          piece: stakedPass.piece.name.toString(),
          material: stakedPass.material.name.toString(),
          color: stakedPass.color.name.toString(),
          stakingDate: stakedPass.staking_date.toNumber(),
          passInUse: stakedPass.pass_in_use,
          passInUseDate: stakedPass.pass_in_use_date.toNumber()
        };
        if (pass.level) {
          // passes.push(pass);
          if (passes[pass.level] == undefined) {
            passes[pass.level] = [pass];
          } else {
            passes[pass.level].push(pass);
          }
        }
      }
    }

    let fullSetCount = 999999;
    for (const piece in BoostLevel) {
      if (!isNaN(Number(piece))) {
        if (Number(piece) !== 0 && Number(piece) !== 7) {
          if (passes[piece] != undefined) {
            const currentPassLength = passes[piece].length;
            if (currentPassLength < fullSetCount) {
              fullSetCount = currentPassLength;
            }
          } else {
            fullSetCount = 0;
            break;
          }
        }
      }
    }

    for (let index = 0; index < fullSetCount; index++) {
      if (passes[BoostLevel.FullSet] == undefined) {
        passes[BoostLevel.FullSet] = [
          {
            tokenIdentifier: passCollectionIdentifier
          }
        ];
      } else {
        passes[BoostLevel.FullSet].push({
          tokenIdentifier: passCollectionIdentifier
        });
      }
    }

    //NoBoost is default applicable to all.
    passes[BoostLevel.NoBoost] = [
      {
        tokenIdentifier: passCollectionIdentifier
      }
    ];
    // console.log(passes);
    setAvailableStakedPasses(passes);

    // if (stakedPassesFromSc.length == 0) {
    //   const passNFTsCountUrl = `${baseURL}accounts/${address}/nfts/count?collections=${passCollectionIdentifier}`;
    //   const nftsCount = await axios.get(passNFTsCountUrl);
    //   if (nftsCount == null || undefined) {
    //     throw Error('nfts info could not found');
    //   }
    //   if (nftsCount.data > 0) {
    //     setWhalePassAvailable(true);
    //   }
    // } else {
    //   setWhalePassAvailable(true);
    // }
  }

  async function loadNFTData() {
    if (selectedNftIdentifiers) {
      const nftURL = `${baseURL}nfts?identifiers=${selectedNftIdentifiers.join()}`;
      const nftsInfo = await axios.get(nftURL);
      if (nftsInfo == null || undefined) {
        throw Error('nfts info could not found');
      }
      if (nftInfo.attributes) {
        const attributes = atob(nftsInfo.data[0].attributes);
        const tagStartIndex = attributes.indexOf('tags:');
        if (tagStartIndex > 0) {
          const lastSemicolonIndex = attributes.indexOf(';', tagStartIndex);
          const tagsData = attributes.substring(
            tagStartIndex,
            lastSemicolonIndex
          );
          const tagsRawData = tagsData.split(':').pop();
          if (tagsRawData) {
            const tagsArray = tagsRawData.split(',');
            setTags(tagsArray);
          }
        }
      }

      setNftInfo(nftsInfo.data[0]);
      const nftsForPawn: PawnNFT[] = [];
      for (let index = 0; index < nftsInfo.data.length; index++) {
        const nft = nftsInfo.data[index];
        let tagsArray: string[] = [];
        if (nftInfo.attributes) {
          const attributes = atob(nftsInfo.data[index].attributes);
          const tagStartIndex = attributes.indexOf('tags:');
          if (tagStartIndex > 0) {
            const lastSemicolonIndex = attributes.indexOf(';', tagStartIndex);
            const tagsData = attributes.substring(
              tagStartIndex,
              lastSemicolonIndex
            );
            const tagsRawData = tagsData.split(':').pop();

            if (tagsRawData) {
              tagsArray = tagsRawData.split(',');
            }
          }
        }
        const nftType = nft.url.split('.').pop();
        const imageurl =
          nftType.toLowerCase() != 'gif' && nftType.toLowerCase() != 'mp4'
            ? nft.media[0].thumbnailUrl
            : nft.url;
        const pawnNFT: PawnNFT = {
          tokenIdentifier: nft.identifier,
          nonce: nft.nonce,
          loanAmount: 0,
          pawnDate: 0,
          actualExpiryDate: 0,
          extendedExpiryDate: 0,
          loanDurationTicks: 0,
          pawnFee: 0,
          boosterNfts: [],
          boostLevel: BoostLevel.NoBoost,
          loanBoostAmount: 0,
          feeDiscount: 0,
          holder: '',
          nftCounter: 0,
          currentState: '',
          nftCommonName: nft.name,
          url: imageurl,
          tags: tagsArray
        };

        nftsForPawn.push(pawnNFT);
      }

      setSelectedNFTsForPawn(nftsForPawn);
    }
  }

  async function loadCollectionDetails() {
    const collectionInfoFromSC = await CommonHelper.loadCollectionDetails(
      proxy,
      nftCollection
    );
    //console.log(collectionInfoFromSC);
    if (collectionInfoFromSC !== null || undefined) {
      setNFTCollectionInfo(collectionInfoFromSC);
    }
  }

  React.useEffect(() => {
    loadCollectionDetails();
  }, []);

  React.useEffect(() => {
    const currentSessionId = sessionStorage.getItem('SessionId');
    if (currentSessionId) {
      setSessionId(currentSessionId);
    }
    loadPawnFeeDetails();
    loadAllBoostInfoDetails();
    // loadCollectionTierLoanDetails();
    loadAllStakedPassesForUser();
    loadNFTData();
  }, []);

  // console.log(nftCollectionInfo);
  // console.log(availableStakedPasses);
  // console.log(pawnFeeInfo);
  // console.log(boostersInfo);
  // console.log(tierLoanInfo);

  React.useEffect(() => {
    setSelectedBooster(boostersInfo[0]);
  }, [boostersInfo]);

  // const signedTransaction = useGetSignedTransactions();

  // React.useEffect(() => {
  //   console.log('my signed pawn transaction');
  //   console.log(JSON.stringify(signedTransaction));
  //   if(signedTransaction)
  //   {
  //     signedTransaction.signedTransactionsArray[''];
  //   }

  // }, [signedTransaction]);

  const pawnNFTAction = async () => {
    loaderHandler(true, false);
    setConfirmPawn({ msg: '', type: '', title: '' });
    //const nonce = selectedNftIdentifiers[0].split('-')[2];

    //convert SC address in Hex
    let hexPawnShopContractAddress = converter('erd').toHex(
      pawnShopContractAddress
    );
    hexPawnShopContractAddress = hexPawnShopContractAddress.substring(
      2,
      hexPawnShopContractAddress.length
    );
    const collectionInHex = CommonHelper.convertToHex(nftCollection);
    const actionInHex = CommonHelper.convertToHex('pawn_nfts');

    //calculate amount in Hex
    const amountInProperDecimal = Math.floor(parseFloat(reqLoanAmt) * 10 ** 5);
    const amountInEGLDUnit = amountInProperDecimal * 10 ** 13;
    if (amountInEGLDUnit % 10 ** 13 !== 0) {
      throw Error('Loan Amount is Invalid');
    }
    let amountInHex = amountInEGLDUnit.toString(16);
    if (amountInHex.length % 2 == 1) {
      amountInHex = `0${amountInHex}`;
    }
    //calculate duration in Hex
    if (selectedPawnDuration == undefined) {
      throw Error('Pawn Duration Not Set');
    }

    let durationInHex = selectedPawnDuration.loanDurationTicks.toString(16);
    if (durationInHex.length % 2 == 1) {
      durationInHex = `0${durationInHex}`;
    }

    //selectedBooster in Hex
    if (selectedBooster == undefined) {
      throw Error('Booster Not Set');
    }

    const boostLevel = getValueForStringForLevelEnum(
      selectedBooster.level.toString()
    );
    const boosterCommand = getSelectedBoosterNFTsDataForTransaction(boostLevel);
    let boosterLevelInHex = boostLevel.toString(16);
    if (boosterLevelInHex.length % 2 == 1) {
      boosterLevelInHex = `0${boosterLevelInHex}`;
    }

    let totalNFTsInHex = selectedNftIdentifiers.length.toString(16);
    if (totalNFTsInHex.length % 2 == 1) {
      totalNFTsInHex = `0${totalNFTsInHex}`;
    }
    let command = `MultiESDTNFTTransfer@${hexPawnShopContractAddress}@${totalNFTsInHex}`;

    const nftDetails = [];
    for (let index = 0; index < selectedNftIdentifiers.length; index++) {
      const selectedNFT = selectedNftIdentifiers[index];
      const nonce = selectedNFT.split('-')[2];
      nftDetails.push({
        token_identifier: collectionInHex,
        nonce: nonce
      });
      command = `${command}@${collectionInHex}@${nonce}@01`;
    }

    command = `${command}@${actionInHex}@${amountInHex}@${durationInHex}@${boosterLevelInHex}${boosterCommand}`;
    let gasAmount;
    if (selectedNftIdentifiers.length <= 2) {
      gasAmount = 50000000;
    } else if (selectedNftIdentifiers.length < 5) {
      gasAmount = 25000000 * selectedNftIdentifiers.length;
    } else {
      gasAmount = 18000000 * selectedNftIdentifiers.length;
    }

    const pawnTransaction = {
      value: '0',
      data: command,
      receiver: address,
      gasLimit: gasAmount
    };

    const boosterNFTs = [];
    if (boosterCommand.length > 0) {
      const boostersNFTsInfo = boosterCommand.split('@');
      for (let index = 1; index < boostersNFTsInfo.length; ) {
        const boosterNFT = {
          token_identifier: boostersNFTsInfo[index++],
          nonce: boostersNFTsInfo[index++]
        };
        boosterNFTs.push(boosterNFT);
      }
    }

    const pawnNFTPlainObject = {
      action: 'pawn_nfts',
      nftDetails: nftDetails,
      sender: address,
      scAddress: hexPawnShopContractAddress,
      loanAmount: amountInHex,
      duration: durationInHex,
      boostlevel: boosterLevelInHex,
      boosterNFTs: boosterNFTs
    };

    await refreshAccount();
    let callbackRoute;
    if (loginMethod == LoginMethodsEnum.wallet) {
      callbackRoute = `/pawnNFT?nftIdentifier=${selectedNftIdentifiers.join()}`;
    }
    const { sessionId, error } = await sendTransactions({
      transactions: pawnTransaction,
      callbackRoute: callbackRoute,
      transactionsDisplayInfo: {
        processingMessage: 'pawn transaction is in progress',
        errorMessage: 'An error has occured during staking/unstaking process',
        successMessage: 'pawn transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = pawnNFTPlainObject;
      // var sessionTransactionObject[`${sessionId}`] = pawnNFTPlainObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setSessionId(sessionId);
      }
      // OnTransactionSent();
    }
  };

  function getValueForStringForLevelEnum(level: string) {
    switch (level) {
      case 'NoBoost':
      case '0':
        return BoostLevel.NoBoost;
      case 'Pawn':
      case '1':
        return BoostLevel.Pawn;
      case 'Knight':
      case '2':
        return BoostLevel.Knight;
      case 'Bishop':
      case '3':
        return BoostLevel.Bishop;
      case 'Rook':
      case '4':
        return BoostLevel.Rook;
      case 'Queen':
      case '5':
        return BoostLevel.Queen;
      case 'King':
      case '6':
        return BoostLevel.King;
      case 'FullSet':
      case '7':
        return BoostLevel.FullSet;
      default:
        throw new Error('Invalid Boost Level');
    }
  }

  function getSelectedBoosterNFTsDataForTransaction(inputLevel: BoostLevel) {
    const collectionInHex = CommonHelper.convertToHex(passCollectionIdentifier);
    let command = '';
    switch (inputLevel) {
      case BoostLevel.NoBoost:
        command = '';
        break;
      case BoostLevel.Pawn:
      case BoostLevel.Knight:
      case BoostLevel.Bishop:
      case BoostLevel.Rook:
      case BoostLevel.Queen:
      case BoostLevel.King:
        const levelPasses = availableStakedPasses[inputLevel];
        if (levelPasses && levelPasses.length > 0) {
          let nonceInHex = levelPasses[0].nftNonce?.toString(16);
          if (nonceInHex) {
            if (nonceInHex.length % 2 == 1) {
              nonceInHex = `0${nonceInHex}`;
            }
            command = `@${collectionInHex}@${nonceInHex}`;
          }
        } else {
          throw new Error('Pass Not Found');
        }

        break;
      case BoostLevel.FullSet:
        for (const level in BoostLevel) {
          if (!isNaN(Number(level))) {
            if (
              Number(level) != BoostLevel.NoBoost &&
              Number(level) != BoostLevel.FullSet
            ) {
              command += getSelectedBoosterNFTsDataForTransaction(
                Number(level)
              );
            }
          }
        }

        break;
    }

    return command;
  }

  async function loaderHandler(
    status: boolean,
    refreshData: boolean,
    response?: any,
    message?: NFTmessage
  ) {
    // console.log('empty function');

    if (response) {
      if (response.status === 'success') {
        pawnOperationCompletedRef.current = true;
        let properMsgCreated = false;
        if (response.operations && response.operations.length > 0) {
          for (let index = 0; index < response.operations.length; index++) {
            const operationResult = response.operations[index];
            if (
              operationResult.action.toUpperCase() === 'TRANSFER' &&
              operationResult.receiver == address &&
              operationResult.type.toUpperCase() == 'EGLD'
            ) {
              message = {
                msg: `Pawn NFT Successful, ${CommonHelper.fixedNum(
                  parseInt(operationResult.value) / 10 ** 18,
                  4
                )} EGLD Transferred to ${address}.<br/> To see the transaction on blockchain explorer. <a target='_blank' href=${explorer_url}/transactions/${
                  response.txHash
                }>Click Here</a>`,
                type: 'message',
                title: ''
              };
              properMsgCreated = true;
            }
          }
        }
        if (!properMsgCreated) {
          message = {
            msg: `Transaction Successful.To see the transaction on blockchain explorer. <br/> <a target='_blank' href=${explorer_url}/transactions/${response.txHash}>Click Here</a>`,
            type: 'message',
            title: ''
          };
        }
        //}
      } else {
        pawnOperationCompletedRef.current = false;
      }
    } else {
      pawnOperationCompletedRef.current = false;
    }

    setIsLoading(status);
    if (message) {
      message = CommonHelper.CreateReadableMsg(message);
      setMsgObj(message);
      setActiveStepState(3);
    }
  }

  const beforechange = (current: number, next: number) => {
    const booster = boostersInfo[next] && boostersInfo[next].level;
    if (booster) {
      const boosterCount =
        availableStakedPasses[
          getValueForStringForLevelEnum(booster.toString())
        ];
      // console.log(boosterCount);
      if (boosterCount && boosterCount.length) {
        setSelectedBooster(boostersInfo[next]);
      } else {
        return false;
      }
    }
  };
  const chkDisabled = (event: any, BoosterCount: PassInfo[]) => {
    if (!(BoosterCount && BoosterCount.length)) {
      //event.stopPropagation();
      return false;
    }
  };
  const settings = {
    className: 'center',
    focusOnSelect: true,
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: boostersInfo.length > 7 ? 7 : boostersInfo.length,
    speed: 1000,
    swipeToSlide: true,
    arrows: true,
    accessibility: false,
    beforeChange: (current: number, next: number) =>
      beforechange(current, next),
    //afterChange: (current:any) => afterchange(current),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: boostersInfo.length > 5 ? 5 : boostersInfo.length,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: boostersInfo.length > 3 ? 3 : boostersInfo.length,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: boostersInfo.length > 2 ? 2 : boostersInfo.length,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  let baseLoan = '';
  if (nftCollectionInfo) {
    baseLoan = CommonHelper.fixedNum(
      (nftCollectionInfo.floorPrice *
        nftCollectionInfo.collection_max_loan_percentage) /
        100,
      4
    );
  }
  const boostAmount = selectedBooster
    ? CommonHelper.fixedNum(
        (parseFloat(baseLoan) * selectedBooster.loanBoostPercentage) / 100,
        4
      )
    : '0';
  const maxLoan =
    CommonHelper.fixedNum(parseFloat(baseLoan) + parseFloat(boostAmount), 4) *
    selectedNftIdentifiers.length;
  const pawnFee =
    selectedPawnDuration &&
    CommonHelper.fixedNum(
      (reqLoanAmt * selectedPawnDuration.feePercentage) / 100,
      4
    );

  let pawnFeeAftrDsc = '';
  let totalLoan = '0.0';
  if (pawnFee) {
    pawnFeeAftrDsc = selectedBooster
      ? (
          parseFloat(pawnFee) -
          (parseFloat(pawnFee) * selectedBooster.loanFeeDiscountPercentage) /
            100
        ).toFixed(6)
      : '';

    totalLoan =
      reqLoanAmt && !isNaN(reqLoanAmt)
        ? (parseFloat(reqLoanAmt) + parseFloat(pawnFeeAftrDsc)).toFixed(6)
        : '0.0';
  }

  const okFunc = () => {
    setActiveStepState(2);
    setMsgObj({ msg: '', type: '', title: '' });
    if (pawnOperationCompletedRef.current) {
      navigate(routeNames.pawned, {
        state: {}
      });
    }
  };

  const chkVal = function () {
    const max = maxLoan;
    const min = 0;
    const input: any = reqLoan && reqLoan.current && reqLoan.current['value'];
    if (!/^\d*(\.\d{0,2})?$/.test(input)) {
      reqLoan.current.value = input.slice(0, -1);
    }
    if (isNaN(input)) {
      reqLoan.current.value = '';
    } else if (input > max) {
      reqLoan.current.value = max;
    } else if (input < min) {
      reqLoan.current.value = min;
    }
    setReqLoanAmt(reqLoan.current.value);
  };
  React.useEffect(() => {
    reqLoan.current.value = CommonHelper.fixedNum(maxLoan, 2);
    setReqLoanAmt(maxLoan);
  }, [nftCollectionInfo, maxLoan]);
  React.useEffect(() => {
    chkVal();
  }, [selectedBooster]);
  const test = React.useRef<any>();
  React.useEffect(() => {
    setTimeout(() => {
      test && test.current && test.current.slickGoTo(0);
    }, 100);
  }, []);
  // console.log(availableStakedPasses);
  // console.log(selectedPawnDuration);
  const openConfirmPawn = () => {
    setActiveStepState(3);
    const legalDisclaimer = `
    <div class='legal-disclaimer'>
        <p>
            <b>INVOLVED PARTIES:</b> This agreement is between Pawn Whale[LENDER] and the owner of MultiversX
            wallet address <span class="orange-text">${address}</span> [BORROWER] and the Pawn Whale Lending Smart Contract <span class="orange-text">${pawnShopContractAddress}</span>[ESCROW
            AGENT]
        </p>
        <p>
            <b>SEVERABILITY CLAUSE:</b> Any part, provision, representation or warranty of this Agreement which is
            prohibited or which is held to be void or unenforceable shall be ineffective to the extent of such
            prohibition or unenforceability without invalidating the remaining provisions hereof. Any part, provision,
            representation or warranty of this Agreement which is prohibited or unenforceable or is held to be void or
            unenforceable in any jurisdiction shall be ineffective, as to such jurisdiction, to the extent of such
            prohibition or unenforceability without invalidating the remaining provisions hereof, and any such
            prohibition or unenforceability in any jurisdiction as to any Loan shall not invalidate or render
            unenforceable such provision in any other jurisdiction. To the extent permitted by applicable law, the
            parties hereto waive any provision of law which prohibits or renders void or unenforceable any provision
            hereof.
        </p>
        <p>
            <b>ENTIRE AGREEMENT CLAUSE:</b> This Agreement supersedes and cancels all previous agreements,
            verbal or written or based on alleged past practices, and constitutes the entire agreement between the
            parties. Any amendment or agreement supplemental hereto shall not be binding upon either party.
        </p>
        <p>
            <b>LOAN TERMS:</b> The BORROWER will submit their digital asset to the ESCROW AGENT as collateral
            for a short term loan of digital cryptocurrency from the LENDER. The loan is due in full, including fees,
            prior to the expiration of this contract and failure to repay within the time period listed in this agreement
            will result in default with additional penalties.
        </p>
        <p>
            <b>LOAN AMOUNT:</b> The loan amount is <span class="orange-text">${reqLoanAmt}</span> eGLD
        </p>
        <p>
            <b>LOAN FEE:</b> The loan fee is <span class="orange-text">${pawnFeeAftrDsc}</span> eGLD
        </p>
        <p>
            <b>LOAN LENGTH:</b> The contract length is 
            <span class="orange-text">
            ${
              selectedPawnDuration &&
              selectedPawnDuration.loanDurationForDisplay
            }</span>
        </p>
        <p>
            <b>PRE-PAYMENT TERMS:</b> The loan can be paid back in full, including fees, prior to the contract
            expiration without resulting in any fees or penalties.
        </p>
        <p>
            <b>LOAN DEFAULT TERMS:</b> If the loan is not paid in full, including fees, prior to the contract
            expiration, the underlying digital asset being used as collateral will be forfeited by the BORROWER and
            will be transferred by the ESCROW AGENT to the LENDER. Also, the BORROWER’s Wallet History
            of Asset Lending (WHALE) Score will be negatively affected if default occurs.
        </p>
        <p>
            <b>MANDATORY ARBITRATION:</b> Any controversy or claim arising out of or relating to this contract, or
            the breach thereof, shall be settled by arbitration administered by the [Comisión de Mediación y Arbitraje
            de la Cámara Nacional de Comercio de la Ciudad de México] under its Mexican Commerce Code. The
            number of arbitrators shall be one. The place of arbitration shall be [CDMX, Mexico]. Mexican law
            shall apply. Judgment on the award rendered by the arbitrator(s) may be entered in any court having
            jurisdiction thereof.
        </p>
    </div>`;
    setConfirmPawn({
      msg: legalDisclaimer,
      type: '',
      title: 'Pawn Whale Short Term Fixed Loan Terms and Conditions',
      activeStep: 3
    });
  };
  const cancelPawn = () => {
    setActiveStepState(2);
    setConfirmPawn({ msg: '', type: '', title: '' });
  };
  return (
    <>
      {isLoading ? (
        <div className='loader'>
          <div className='loader_content test'>
            {/* <img src={loader} /> */}
            <svg
              aria-hidden='true'
              focusable='false'
              data-prefix='fat'
              data-icon='spinner-third'
              className='svg-inline--fa fa-spinner-third fa-5x primary fa-spin fast-spin modal-layout-loader-icon'
              role='img'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 576 512'
            >
              <path
                fill='currentColor'
                d='M280 8C280 3.582 283.6 0 288 0C429.4 0 544 114.6 544 256C544 302.6 531.5 346.4 509.7 384C507.5 387.9 502.6 389.2 498.8 386.9C494.1 384.7 493.7 379.8 495.9 376C516.3 340.7 528 299.7 528 256C528 123.5 420.5 16 288 16C283.6 16 280 12.42 280 8V8z'
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        ''
      )}
      <StepsModule
        activeStep={activeStepState ? activeStepState : activeStep}
      ></StepsModule>
      <div className='row col-lg-10 col-md-11 col-12 pawnNft-container margin-auto'>
        <Col
          xs={11}
          sm={5}
          md={4}
          className='pawn-display-card nft-with-cta margin-auto'
        >
          {nftInfo && nftCollection ? (
            <div
              className='margin-auto'
              onClick={() => setOpenModal(!openModal)}
            >
              <NftCard
                nftInfo={nftInfo}
                action={'noBtn'}
                tags={tags}
                collectionInfo={nftCollection}
                count={selectedNftIdentifiers.length}
              />
            </div>
          ) : (
            ''
          )}
        </Col>
        <Col xs={11} sm={6} md={7} className='row margin-auto p-0'>
          <div className='pawnNft-container m-b-15'>
            <div className='new-nft-details col-12'>
              <h4 className='text-white'>
                <label className='blue-text'>Loan Duration</label>
                <label>
                  <select
                    onChange={(e: any) =>
                      setSelectedPawnDuration(pawnFeeInfo[e.target.value])
                    }
                  >
                    {pawnFeeInfo &&
                      pawnFeeInfo.map((item: any, index: number) => {
                        return (
                          <option key={index} value={index}>
                            {item.loanDurationForDisplay}
                          </option>
                        );
                      })}
                  </select>
                </label>
              </h4>
            </div>

            <div className='new-nft-details col-12'>
              <h4 className='text-white'>
                <label className='blue-text'>Floor Price</label>
                <label>
                  {nftCollectionInfo ? nftCollectionInfo.floorPrice : ''}
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='logotype'
                  >
                    <path
                      d='M11.7406 15.0514L21.2 10.1586L19.6097 7.19922L10.9479 10.5911C10.7248 10.6786 10.4776 10.6786 10.2545 10.5911L1.5903 7.19922L0 10.1586L9.45939 15.0514L0 19.9418L1.5903 22.9012L10.2521 19.5093C10.4752 19.4218 10.7224 19.4218 10.9455 19.5093L19.6073 22.9012L21.1976 19.9418L11.7382 15.049L11.7406 15.0514Z'
                      fill='#23F7DD'
                    ></path>
                  </svg>
                </label>
              </h4>
            </div>

            <div className='new-nft-details col-12'>
              <h4 className='text-white'>
                <label className='blue-text'>Max Loan</label>
                <label>
                  {maxLoan}
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='logotype'
                  >
                    <path
                      d='M11.7406 15.0514L21.2 10.1586L19.6097 7.19922L10.9479 10.5911C10.7248 10.6786 10.4776 10.6786 10.2545 10.5911L1.5903 7.19922L0 10.1586L9.45939 15.0514L0 19.9418L1.5903 22.9012L10.2521 19.5093C10.4752 19.4218 10.7224 19.4218 10.9455 19.5093L19.6073 22.9012L21.1976 19.9418L11.7382 15.049L11.7406 15.0514Z'
                      fill='#23F7DD'
                    ></path>
                  </svg>
                </label>
              </h4>
            </div>

            <div className='new-nft-details col-12'>
              <h4 className='text-white'>
                <label className='blue-text'>Required Loan</label>
                <label>
                  <input
                    type='text'
                    onChange={chkVal}
                    ref={reqLoan}
                    className='reqLoan text-right float-right'
                  />
                </label>
              </h4>
            </div>

            <div className='new-nft-details col-12'>
              <h4 className='text-white'>
                <label className='blue-text'>
                  Boost Amount (
                  {selectedBooster ? selectedBooster.loanBoostPercentage : ''}%)
                </label>
                <label>{boostAmount}</label>
              </h4>
            </div>

            <div className='new-nft-details col-12'>
              <h4 className='text-white'>
                <label className='blue-text'>
                  Loan Fee (
                  {selectedPawnDuration
                    ? selectedPawnDuration.feePercentage
                    : ''}
                  %)
                </label>
                <label>
                  {pawnFee}
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='logotype'
                  >
                    <path
                      d='M11.7406 15.0514L21.2 10.1586L19.6097 7.19922L10.9479 10.5911C10.7248 10.6786 10.4776 10.6786 10.2545 10.5911L1.5903 7.19922L0 10.1586L9.45939 15.0514L0 19.9418L1.5903 22.9012L10.2521 19.5093C10.4752 19.4218 10.7224 19.4218 10.9455 19.5093L19.6073 22.9012L21.1976 19.9418L11.7382 15.049L11.7406 15.0514Z'
                      fill='#23F7DD'
                    ></path>
                  </svg>
                </label>
                {/* <label>
                {selectedPawnDuration ? selectedPawnDuration.feePercentage : ''}
              </label> */}
              </h4>
            </div>

            <div className='new-nft-details col-12'>
              <h4 className='text-white'>
                <label className='blue-text'>Total Due </label>
                <label>
                  {totalLoan}
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='logotype'
                  >
                    <path
                      d='M11.7406 15.0514L21.2 10.1586L19.6097 7.19922L10.9479 10.5911C10.7248 10.6786 10.4776 10.6786 10.2545 10.5911L1.5903 7.19922L0 10.1586L9.45939 15.0514L0 19.9418L1.5903 22.9012L10.2521 19.5093C10.4752 19.4218 10.7224 19.4218 10.9455 19.5093L19.6073 22.9012L21.1976 19.9418L11.7382 15.049L11.7406 15.0514Z'
                      fill='#23F7DD'
                    ></path>
                  </svg>
                </label>
              </h4>
            </div>

            {/* <div className='nft-details col-5 col-md-3'>
            <h4 className='text-white'>
              <label className='blue-text'>Base Loan:</label>
              <label>{baseLoan}</label>
            </h4>
          </div>

          <div className='nft-details col-5 col-md-3'>
            <h4 className='text-white'>
              <label className='blue-text'>Boost Level:</label>
              <label> {selectedBooster && selectedBooster.level}</label>
            </h4>
          </div>

          <div className='nft-details col-5 col-md-3'>
            <h4 className='text-white'>
              <label className='blue-text'>
                Fee After (
                {selectedBooster
                  ? selectedBooster.loanFeeDiscountPercentage
                  : ''}
                %) Discount :
              </label>
              <label> {pawnFeeAftrDsc}</label>
            </h4>
          </div>
          
          <Col xs={12} className='row margin-auto'>
            <div className='nftListLabel orange-text egld-text'>
              **All Values are in EGLD**
            </div>
          </Col> */}
          </div>
        </Col>
        {/* 
        <Col>
            Floor Price : 1

            Base Loan :  {(nftCollectionInfo.floorPrice *
                    nftCollectionInfo.collection_max_loan_percentage) /
                    100} : .6
            
            Loan Duration: drop down // PawnFeeInfo
 
            Boost Level: <Based on below selection>

            Boost Amount: Boosted % of Base Loan
            
            MAx Loan: Base + Boost

            Required Loan : Input Box <Type Number> Max = MAx Loan/ Validate on focus out
            
            Pawn Fee : feePercentage of Required Loan <Based on duration selection>
            
            Fee After Boost Discount: Pawn Fee - Fee Dis % Boost of Pawn Fee

            Total Payable Loan: Required Loan + Fee After Boost Discount
            
            level: "NoBoost"
loanBoostPercentage: 0
loanFeeDiscountPercentage: 0
        </Col> */}
        {selectedNftIdentifiers.length === 1 ? (
          <Col xs={11} className='slider-container margin-auto m-b-15'>
            <h3 className='blue-text'>Select Boost Level:</h3>
            <Slider {...settings} ref={test}>
              {boostersInfo &&
                boostersInfo.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={
                        availableStakedPasses[
                          getValueForStringForLevelEnum(item.level)
                        ] &&
                        availableStakedPasses[
                          getValueForStringForLevelEnum(item.level)
                        ].length
                          ? 'slider-item-container'
                          : 'disabled slider-item-container'
                      }
                      onClick={(event) =>
                        chkDisabled(
                          event,
                          availableStakedPasses[
                            getValueForStringForLevelEnum(item.level)
                          ]
                        )
                      }
                    >
                      <div className='slider-item'>
                        {availableStakedPasses[
                          getValueForStringForLevelEnum(item.level)
                        ] ? (
                          <label className='booster-count'>
                            {
                              availableStakedPasses[
                                getValueForStringForLevelEnum(item.level)
                              ].length
                            }
                          </label>
                        ) : (
                          <label className='booster-count'>0</label>
                        )}
                        <img
                          className='slick-img'
                          src={CarouselImages[item.level]}
                        ></img>
                        <label>Level: {item.level}</label>
                        <label>Loan Boost: {item.loanBoostPercentage}%</label>
                        <label>
                          Fee Discount: {item.loanFeeDiscountPercentage}%
                        </label>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </Col>
        ) : (
          ''
        )}
        <div className='show-for-large col-11 margin-auto m-b-15'>
          <Col xs={12}>
            <div className='nftListLabel orange-text text-center font-size-22'>
              **Stake Pawn Whale Passes to borrow more money and pay less
              fees.**
            </div>
          </Col>
          <Col xs={12}>
            <div className='nftListLabel orange-text text-center font-size-22 d-block'>
              <span>**buy Pawn Whale Passes at</span>
              <a
                href='https://deadrare.io/collection/PAWNWHALE-ef8360'
                target='blank'
                className='d-inline'
              >
                <img className='company-img' src={DeadRare}></img>
              </a>
              <a
                href='https://xoxno.com/collection/PAWNWHALE-ef8360'
                target='blank'
                className='d-inline'
              >
                <img className='company-img' src={Xoxno}></img>
              </a>
              <a
                href='https://www.frameit.gg/marketplace/PAWNWHALE-ef8360/items'
                target='blank'
                className='d-inline'
              >
                <img className='company-img' src={FrameIt}></img>
              </a>
            </div>
          </Col>
          <Col xs={12}>
            <div className='nftListLabel orange-text text-center font-size-22 d-block'>
              <span>**Mint Pawn Whale Passes at </span>
              <a
                href='https://www.cryptopittzlaunchpad.com/'
                target='blank'
                className='d-inline'
              >
                <img className='company-img' src={Mint}></img>
              </a>
            </div>
          </Col>
        </div>
        {selectedNftIdentifiers.length > 1 ? (
          ''
        ) : (
          <div className='hide-for-large col-11 margin-auto m-b-15'>
            <Col xs={12}>
              <div className='nftListLabel text-center font-size-22'>
                Stake Pawn Whale Passes to boost your loan. A boosted loan can
                borrow more money and pay less fees.
              </div>
            </Col>
          </div>
        )}
        <Col xs={11} className='p-0 margin-auto'>
          <button
            className='NFT__CardBody--ButtonStack btn btn-primary pawn-btn col-12'
            onClick={() => openConfirmPawn()}
            disabled={!parseFloat(reqLoanAmt)}
          >
            GET LOAN
          </button>
        </Col>
      </div>

      {sessionStateId ? (
        <TrackTransaction
          sessionId={sessionStateId}
          loaderHandler={loaderHandler}
          status={true}
        ></TrackTransaction>
      ) : (
        ''
      )}
      {msgObj && msgObj.msg ? (
        <ConfirmModal data={msgObj}>
          <button
            className='ok-btn'
            onClick={() => {
              okFunc();
            }}
          >
            Ok
          </button>
        </ConfirmModal>
      ) : (
        ''
      )}
      {confirmPawn && confirmPawn.msg ? (
        <div className='pawn-modal'>
          <ConfirmModal data={confirmPawn}>
            <p className='text-white text-left'>
              <input
                type='checkbox'
                id='legal-cb'
                checked={legalCb}
                onChange={(e) => setLegalCb(e.target.checked)}
              />
              <label className='terms-conditions' htmlFor='legal-cb'>
                I agree to terms and conditions
              </label>
            </p>
            <button
              className='ok-btn'
              onClick={() => pawnNFTAction()}
              disabled={!legalCb}
            >
              Ok
            </button>
            <button className='ok-btn' onClick={() => cancelPawn()}>
              Cancel
            </button>
          </ConfirmModal>
        </div>
      ) : (
        ''
      )}
      {selectedNftIdentifiers && openModal ? (
        <div className='pawn-modal'>
          <div className='confirm-modal'>
            <div className='backdrop'></div>
            <div className='confirm-modal-content p-0'>
              <div className='custom-modal-header orange-text nftListLabel'>
                Selected NFT(s)
                <span
                  className='close orange-text'
                  onClick={() => setOpenModal(false)}
                >
                  X
                </span>
              </div>
              <div className='row nft_container nft-with-cta scroll-container'>
                {selectedNFTsForPawn.map((nftInfo: any, index: number) => {
                  return (
                    <ModalCard key={index} selectedNFTs={nftInfo}></ModalCard>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default PawnNFTComponent;
