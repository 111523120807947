import '../src/';
import React from 'react';
// import '@multiversx/sdk-dapp/dist/index.css';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import axios from 'axios';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import {
  environment,
  pawnShopContractAddress,
  stakingContractAddress
} from 'config';
import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';
import StakingSmartContract from 'Helper/staking-smart-contract';
import PageNotFound from 'pages/pageNotFound';
import { routeNames } from 'routes';
import routes from 'routes';
import { Unlock } from 'pages/unlock';
import { walletConnectV2ProjectId } from 'config';

// const {
//   TransactionsToastList,
//   SignTransactionsModals,
//   NotificationModal,
//   DappCorePages: { UnlockPage }
// } = DappUI;

const App = () => {
  async function loadAllABIs() {
    const stakingAbiJson = await axios.get(
      './pawn_shop_staking_contract.abi.json'
    );
    const pawnShopAbiJson = await axios.get(
      './pawn_shop_smart_contract.abi.json'
    );

    await StakingSmartContract.initializeStakingSmartContract(
      stakingContractAddress,
      stakingAbiJson.data
    );

    await PawnShopSmartContract.initializePawnShopSmartContract(
      pawnShopContractAddress,
      pawnShopAbiJson.data
    );
  }
  React.useEffect(() => {
    loadAllABIs();
  }, []);
  const currentRoute = sessionStorage.getItem('currentRoute') || 'home';
  return (
    <Router>
      <DappProvider
        environment={environment}
        customNetworkConfig={{
          name: 'customConfig',
          apiTimeout: 15000,
          walletConnectV2ProjectId
        }}
        dappConfig={{
          shouldUseWebViewProvider: true
        }}
      >
        <Layout>
          <TransactionsToastList />
          <NotificationModal />
          <SignTransactionsModals className='custom-class-for-modals' />
          <Routes>
            <Route path={routeNames.unlock} element={<Unlock />} />
            {routes.map((route, index) => (
              <Route
                path={route.path}
                key={'route-key-' + index}
                element={<route.component />}
              />
            ))}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Layout>
      </DappProvider>
    </Router>
  );
};

export default App;
