import React from 'react';
import { logout } from '@multiversx/sdk-dapp/utils';

import {
  useGetAccountInfo,
  useGetLoginInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { LoginMethodsEnum } from '@multiversx/sdk-dapp/types';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { baseURL } from 'config';
import CommonHelper from 'Helper/common-helper';
import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';
import { routeNames } from 'routes';
import ElrondLogo from '../../../assets/logo.png';
import LoginButton from '../../LoginButton';

const Navbar = () => {
  const { address } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();
  const location = useLocation();
  const [walletBalance, setWalletBalance] = React.useState<number>();
  const [showBalance, setShowBalance] = React.useState<boolean>(false);
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  const currentRoute =
    location.pathname.substring(1, location.pathname.length) || 'home';
  const handleLogout = () => {
    sessionStorage.removeItem('currentRoute');
    logout(`${window.location.origin}/unlock`);
  };

  const isLoggedIn = Boolean(address);

  interface NavBarProps {
    label: string;
    cn: string;
    route: keyof typeof routeNames;
  }

  const NavBarItem: React.FC<NavBarProps> = ({ label, route, cn }) => {
    return route ? (
      <div
        className={cn + ' navBar__centerPanel--Item'}
        onClick={() => (window.location.href = routeNames[route])}
      >
        {label}
      </div>
    ) : (
      <div className='navBar__centerPanel--Item'>{label}</div>
    );
  };
  const [operatorLoggedIn, setOperatorLoggedIn] =
    React.useState<boolean>(false);
  const [showNavbar, setShowNavbar] = React.useState(true);
  const handleNavbar = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width > 767) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  };
  window.addEventListener('resize', handleNavbar, false);
  React.useEffect(() => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 768) {
      setShowNavbar(false);
    }
  }, []);

  async function loadWalletBalance() {
    if (address) {
      const accountInfoURL = `${baseURL}accounts/${address}`;
      const accountInfo = await axios.get(accountInfoURL);
      // const accountInfo = await refreshAccount();
      if (accountInfo) {
        setWalletBalance(parseInt(accountInfo.data.balance));
      }
    }
  }

  const openBalanceSideBar = async () => {
    if (!showBalance) {
      await loadWalletBalance();
    }

    setShowBalance(!showBalance);
  };

  React.useEffect(() => {
    setShowBalance(false);
  }, [currentRoute]);

  async function loadOperatorAddresses() {
    const operatorAddresses = await PawnShopSmartContract.getOperatorAddresses(
      proxy
    );
    // console.log(operatorAddresses);
    if (address) {
      const addressFound = operatorAddresses.find(
        (x: any) => x.bech32() == address
      );
      if (addressFound) {
        setOperatorLoggedIn(true);
      }
    }
  }
  React.useEffect(() => {
    loadOperatorAddresses();
    loadWalletBalance();
  }, [address]);

  return (
    <>
      <BsNavbar className='navBar  px-4 py-3 '>
        <div className='col-3 col-md-2'>
          <Link
            className='d-flex align-items-center navbar-brand mr-0'
            to={isLoggedIn ? routeNames.home : routeNames.home}
          >
            <img src={ElrondLogo} className='pw-logo' />
            <span className='logo-text orange-text'>PAWN WHALE</span>
            {/* <span className='dapp-name text-muted'>{dAppName}</span> */}
          </Link>
        </div>
        {showNavbar ? (
          <div className='col-12 col-md-6 nav-items'>
            <div className='navBar__centerPanel'>
              <NavBarItem
                label={'Home'}
                route={'home'}
                cn={currentRoute === 'home' ? 'orange-text' : ''}
              />
              <NavBarItem
                label={'Get Loan'}
                route={'collections'}
                cn={
                  currentRoute === 'collections' ||
                  currentRoute === 'collectionNFTs' ||
                  currentRoute === 'pawnNFT'
                    ? 'orange-text'
                    : ''
                }
              />
              <NavBarItem
                label={'Pay Loan'}
                route={'pawned'}
                cn={
                  currentRoute === 'pawned' || currentRoute == 'claimNFT'
                    ? 'orange-text'
                    : ''
                }
              />
              <NavBarItem
                label={'Stake'}
                route={'overview'}
                cn={currentRoute === 'overview' ? 'orange-text' : ''}
              />

              {operatorLoggedIn ? (
                <div
                  className={
                    'has-sub-menu navBar__centerPanel--Item ' +
                    (currentRoute === 'AdminStats' ||
                    currentRoute === 'AdminClaim' ||
                    currentRoute === 'AdminSettings'
                      ? 'orange-text'
                      : '')
                  }
                >
                  Admin
                  <div className='navBar-sub-menu'>
                    <NavBarItem
                      label={'Statistics'}
                      route={'AdminStats'}
                      cn={currentRoute === 'AdminStats' ? 'orange-text' : ''}
                    />
                    <NavBarItem
                      label={'Claim NFTs'}
                      route={'AdminClaim'}
                      cn={currentRoute === 'AdminClaim' ? 'orange-text' : ''}
                    />
                    <NavBarItem
                      label={'Settings'}
                      route={'AdminSettings'}
                      cn={currentRoute === 'AdminSettings' ? 'orange-text' : ''}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='hide-for-large'>
                {isLoggedIn && (
                  <NavItem>
                    <button
                      className='btn btn-link navBar--Logout btn-primary'
                      onClick={handleLogout}
                    >
                      Disconnect
                    </button>
                  </NavItem>
                )}
                {!isLoggedIn && <LoginButton />}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className='navBar--RightSection col-6 col-md-3 col-lg-2'>
          {/* <span className='navBar--Address' data-tip={address}>
            {isLoggedIn
              ? 'Wallet Address: ' + address.substring(0, 5) + '...'
              : ''}
          </span>
          <ReactTooltip /> */}
          <Nav className='ml-auto'>
            {isLoggedIn && (
              <NavItem>
                <button
                  className='btn btn-link navBar--Logout btn-primary'
                  onClick={handleLogout}
                >
                  Disconnect
                </button>
              </NavItem>
            )}
          </Nav>

          {!isLoggedIn && <LoginButton />}
        </div>
        <div className='col-1 mobile-menu'>
          <span onClick={() => setShowNavbar(!showNavbar)}>
            {showNavbar ? 'X' : '☰'}
          </span>
        </div>
      </BsNavbar>
      {isLoggedIn ? (
        <div
          className={showBalance ? 'balance open' : 'balance'}
          onClick={() => openBalanceSideBar()}
        >
          <label className='text-white'>Balance</label>
          <label className='orange-text'>
            {CommonHelper.fixedNum(
              walletBalance ? walletBalance / 10 ** 18 : 0,
              4
            )}{' '}
            EGLD
          </label>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Navbar;
