import axios from 'axios';
import { nodeAPIBaseURL } from 'config';
export default class ApiInteraction {
  static async sendApiTransaction(transaction: any) {
    const plainTransaction = transaction.plainTransaction;
    const action = plainTransaction.action.toString();
    switch (action.toUpperCase()) {
      case 'PAWN_NFTS':
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/pawn`,
          transaction
        );
      case 'CLAIM_NFTS':
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/claim`,
          transaction
        );
      case 'STAKE':
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/stake`,
          transaction
        );
      case 'UNSTAKE':
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/unstake`,
          transaction
        );
      case 'renew_loans'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/renew`,
          transaction
        );
      case 'claim_defaulted_loan_nfts_by_nft_details'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/claimDefaultedNFTsByNFTsDetails`,
          transaction
        );
      case 'update_nft_company_info'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/updateCompanyInfo`,
          transaction
        );
      case 'update_nft_collection'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/updateCollectionInfo`,
          transaction
        );
      case 'update_tier_loan_info'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/updateTierLoanInfo`,
          transaction
        );
      case 'update_booster_info'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/updateBoosterInfo`,
          transaction
        );
      case 'update_pawn_fee_info'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/updatePawnFeeInfo`,
          transaction
        );
      case 'freeze_contract'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/freezeUnfreezeContract`,
          transaction
        );
      case 'unfreeze_contract'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/freezeUnfreezeContract`,
          transaction
        );
      case 'add_recovery_amount'.toUpperCase():
      case 'set_pawn_enable'.toUpperCase():
        return await this.sendTransactionInternal(
          `${nodeAPIBaseURL}/updateContract`,
          transaction
        );
    }
  }

  private static async sendTransactionInternal(api: string, transaction: any) {
    try {
      const response = await axios.post(api, JSON.stringify(transaction), {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('MY api response : ' + JSON.stringify(response, null, 2));
      return response;
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  }
}
