import * as React from 'react';
// import ConfirmModal from 'components/ConfirmModal';

import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import {
  useGetAccountInfo,
  useGetLoginInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { LoginMethodsEnum } from '@multiversx/sdk-dapp/types';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from 'components/ConfirmModal';
import ExpiredNFTsList from 'components/expiredNFTsList';
import { explorer_url, pawnShopContractAddress } from 'config';
import CommonHelper from 'Helper/common-helper';
import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';
import { routeNames } from 'routes';
import loader from '../assets/loader.gif';
import TrackTransaction from './TrackTransaction';
import { NFTmessage } from './types/NFTMessage';
import { PawnNFT } from './types/PawnLoanType';

const AdminClaim = () => {
  sessionStorage.setItem('currentRoute', 'AdminClaim');
  const { address } = useGetAccountInfo();
  const navigate = useNavigate();
  const { network } = useGetNetworkConfig();
  const { loginMethod } = useGetLoginInfo();
  const [sessionStateId, setSessionId] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [msgObj, setMsgObj] = React.useState<NFTmessage>();
  const [expiredNFTs, setExpiredNFTs] = React.useState<PawnNFT[]>([]);
  const [permissionAllowed, setPermissionAllowed] =
    React.useState<boolean>(false);
  const [transactionPending, setTransactionPending] =
    React.useState<boolean>(true);
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });

  async function loadOperatorAddresses() {
    const operatorAddresses = await PawnShopSmartContract.getOperatorAddresses(
      proxy
    );
    // console.log(operatorAddresses);
    if (address) {
      const addressFound = operatorAddresses.find(
        (x: any) => x.bech32() == address
      );
      if (!addressFound) {
        navigate(routeNames.home, {
          state: {}
        });
      } else {
        setPermissionAllowed(true);
      }
    } else {
      navigate(routeNames.home, {
        state: {}
      });
    }
  }
  React.useEffect(() => {
    loadOperatorAddresses();
  }, [address]);

  async function loadExpiredNFTs() {
    const getExpiredNFTs = await CommonHelper.getAllExpiredLoanNFTs(proxy);
    setExpiredNFTs(getExpiredNFTs);
  }

  React.useEffect(() => {
    const currentSessionId = sessionStorage.getItem('SessionId');
    if (currentSessionId) {
      setTransactionPending(true);
      setSessionId(currentSessionId);
    } else {
      setTransactionPending(false);
    }
  }, []);

  React.useEffect(() => {
    if (!transactionPending && permissionAllowed) {
      loadExpiredNFTs();
    }
  }, [permissionAllowed]);

  const claimDefaultedNFTsAction = async (defaultedNFTs: any[]) => {
    loaderHandler(true, false);
    let command = '';
    const nftsDetail = [];
    for (let index = 0; index < defaultedNFTs.length; index++) {
      const nftInfo = defaultedNFTs[index];
      const collectionInHex = CommonHelper.convertToHex(
        nftInfo.tokenIdentifier
      );
      let nftCounterInHex = parseInt(nftInfo.nftCounter).toString(16);
      if (nftCounterInHex.length % 2 == 1) {
        nftCounterInHex = `0${nftCounterInHex}`;
      }

      command += `@${collectionInHex}@${nftInfo.nonce}@${nftCounterInHex}`;
      nftsDetail.push({
        tokenIdentifier: nftInfo.tokenIdentifier,
        tokenNonce: nftInfo.nonce,
        nftCounter: parseInt(nftInfo.nftCounter)
      });
    }

    const claimTransaction = {
      value: 0,
      data: `claim_defaulted_loan_nfts_by_nft_details${command}`,
      receiver: pawnShopContractAddress,
      gasLimit: 50000000
    };

    const plainClaimDefaultedNFTsObject = {
      action: 'claim_defaulted_loan_nfts_by_nft_details',
      nftsDetail: nftsDetail,
      sender: address,
      scAddress: pawnShopContractAddress
    };

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: claimTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'claim defaulted nfts transaction is in progress',
        errorMessage:
          'An error has occured during claim defaulted nfts process',
        successMessage: 'claim defaulted nfts transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      // loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = plainClaimDefaultedNFTsObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setTransactionPending(true);
        setSessionId(sessionId);
      }
    }
  };

  async function loaderHandler(
    status: boolean,
    refreshData: boolean,
    response?: any,
    message?: NFTmessage
  ) {
    // console.log('empty function');

    if (refreshData) {
      await loadExpiredNFTs();
      setTransactionPending(false);
    }

    if (response) {
      if (response.status === 'success') {
        if (response.operations && response.operations.length > 0) {
          message = {
            msg: `Transaction Successful.To see the transaction on blockchain explorer. <br/> <a target='_blank' href=${explorer_url}/transactions/${response.txHash}>Click Here</a>`,
            type: 'message',
            title: ''
          };
        }
        //}
      }
    }

    setIsLoading(status);
    if (message) {
      setMsgObj(message);
    }
  }
  const okFunc = () => {
    setMsgObj({ msg: '', type: '', title: '' });
  };

  return (
    <>
      {isLoading ? (
        <div className='loader'>
          <div className='loader_content'>
            {/* <img src={loader} /> */}
            <svg
              aria-hidden='true'
              focusable='false'
              data-prefix='fat'
              data-icon='spinner-third'
              className='svg-inline--fa fa-spinner-third fa-5x primary fa-spin fast-spin modal-layout-loader-icon'
              role='img'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 576 512'
            >
              <path
                fill='currentColor'
                d='M280 8C280 3.582 283.6 0 288 0C429.4 0 544 114.6 544 256C544 302.6 531.5 346.4 509.7 384C507.5 387.9 502.6 389.2 498.8 386.9C494.1 384.7 493.7 379.8 495.9 376C516.3 340.7 528 299.7 528 256C528 123.5 420.5 16 288 16C283.6 16 280 12.42 280 8V8z'
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        ''
      )}
      {sessionStateId ? (
        <TrackTransaction
          sessionId={sessionStateId}
          loaderHandler={loaderHandler}
          status={true}
        ></TrackTransaction>
      ) : (
        ''
      )}
      {msgObj && msgObj.msg ? (
        <ConfirmModal data={msgObj}>
          <button
            className='ok-btn'
            onClick={() => {
              okFunc();
            }}
          >
            Ok
          </button>
        </ConfirmModal>
      ) : (
        ''
      )}
      {transactionPending ? (
        ''
      ) : expiredNFTs.length ? (
        <ExpiredNFTsList
          data={expiredNFTs}
          claimBtn={(data: any) => claimDefaultedNFTsAction(data)}
        ></ExpiredNFTsList>
      ) : (
        <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container'>
          <div className='nftListLabel'>No Expired Loans to Claim</div>
        </div>
      )}
    </>
  );
};

export default AdminClaim;
