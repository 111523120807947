import React, { useRef } from 'react';
import { SignedTransactionType } from '@multiversx/sdk-dapp/types';
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { removeAllSignedTransactions } from '@multiversx/sdk-dapp/services';
import axios from 'axios';
import { baseURL } from 'config';
import ApiInteraction from 'Helper/api-interaction';
import { NFTmessage } from './types/NFTMessage';

function TrackTransaction(props: any) {
  const { sessionId, loaderHandler, status } = props;
  console.log(sessionId);
  const [txHash, setTxHash] = React.useState<string>('');
  const [transactionFound, setTransactionFound] =
    React.useState<boolean>(false);
  const transactionFoundRef = useRef(transactionFound);
  const transactionData = useTrackTransactionStatus({
    transactionId: sessionId
  });

  // var transactionFound = false;
  let newTransaction = status;
  React.useEffect(() => {
    if (transactionData.transactions === undefined) {
      sessionStorage.removeItem('SessionId');
      setTimeout(() => {
        cancelTransactionTracking();
      }, 30000);
    }
  }, []);

  async function cancelTransactionTracking() {
    if (!transactionFoundRef.current) {
      // alert('This should not happen');
      loaderHandler(false, true, null);
    }
  }

  async function sendManualTransaction(
    signedTransaction: SignedTransactionType
  ) {
    const currentTransactionInString =
      sessionStorage.getItem('currentTransaction');
    if (currentTransactionInString == null || undefined) {
      const message: NFTmessage = {
        msg: 'Current transaction is not properly set.',
        type: 'error',
        title: ''
      };
      loaderHandler(false, true, null, message);
      return;
    }

    const currentTransaction = JSON.parse(currentTransactionInString);
    if (currentTransaction[sessionId] == null || undefined) {
      const message: NFTmessage = {
        msg: 'Current transaction not found for this session.',
        type: 'error',
        title: ''
      };
      sessionStorage.removeItem('SessionId');
      loaderHandler(false, true, null, message);
      return;
    }

    const transactionToSend = {
      plainTransaction: currentTransaction[sessionId],
      signedTransaction: signedTransaction
    };

    try {
      const response = await ApiInteraction.sendApiTransaction(
        transactionToSend
      );
      if (response == null) {
        const message: NFTmessage = {
          msg: 'Some error occured. Transaction not completed',
          type: 'error',
          title: ''
        };
        sessionStorage.removeItem('SessionId');
        loaderHandler(false, true, null, message);
        return;
      }

      setTxHash(response.data.hash);
      sessionStorage.removeItem('SessionId');
    } catch (ex: any) {
      console.error(ex);
      sessionStorage.removeItem('SessionId');
      const message: NFTmessage = {
        msg: `Some error occured. '${ex?.response?.data?.error}'`,
        type: 'error',
        title: ''
      };
      loaderHandler(false, true, null, message);
    }
  }

  React.useEffect(() => {
    if (
      sessionId &&
      transactionData !== undefined &&
      transactionData.transactions !== undefined
    ) {
      if (newTransaction) {
        // transactionFound = true;
        setTransactionFound(true);
        transactionFoundRef.current = true;
        loaderHandler(true, false, null);
        sendManualTransaction(transactionData.transactions[0]);
        //setTxHash(transactionData.transactions[0].hash);
      }
    }
  }, [transactionData.status]);
  let tryCount = 0;
  const getTransactionData = async () => {
    console.log('called getTransactionData');
    newTransaction = false;
    // const identifiers = [];
    const getTransactions = baseURL + 'transactions/' + txHash;
    let message: NFTmessage = {
      msg: '',
      type: '',
      title: ''
    };
    axios
      .get(getTransactions)
      .then((data) => {
        const txData = data.data;
        if (
          data.data.status === 'success' &&
          (txData.action.name == 'update_nft_company_info' ||
            txData.action.name == 'update_nft_collection' ||
            txData.action.name == 'update_tier_loan_info' ||
            txData.action.name == 'update_booster_info' ||
            txData.action.name == 'update_pawn_fee_info' ||
            txData.action.name == 'freeze_contract' ||
            txData.action.name == 'unfreeze_contract' ||
            txData.action.name == 'add_recovery_amount' ||
            txData.action.name == 'set_pawn_enable' ||
            txData.action.name == 'renew_loans')
        ) {
          sessionStorage.removeItem('SessionId');
          loaderHandler(false, true, txData, message);
        } else if (data.data.status === 'success' && txData.operations) {
          sessionStorage.removeItem('SessionId');
          loaderHandler(false, true, txData, message);
        } else if (data.data.status === 'fail' && txData.operations) {
          console.error('Transaction Status: Failed');
          for (let i = 0; i < txData.operations.length; i++) {
            if (
              txData.operations[i].action === 'signalError' &&
              txData.operations[i].type === 'error'
            ) {
              console.log(txData.operations[i].message);
              message = {
                msg: txData.operations[i].message,
                type: 'error',
                title: ''
              };
            }
          }
          loaderHandler(false, true, txData, message);
          sessionStorage.removeItem('SessionId');
        } else {
          if (tryCount <= 9) {
            tryCount++;
            setTimeout(getTransactionData, 5000);
          } else {
            message = {
              msg: `Time out occured, could not identify the status of this transaction.Please check on blockchain explorer. <br/> <a target='_blank' href=https://explorer.elrond.com/transactions/${txHash}>Click Here</a>`,
              type: 'error',
              title: ''
            };
            loaderHandler(false, true, null, message);
            sessionStorage.removeItem('SessionId');
          }
        }

        removeAllSignedTransactions();
      })
      .catch((error) => {
        if (error.response && error.response.status == 404 && tryCount <= 9) {
          tryCount++;
          setTimeout(getTransactionData, 5000);
        } else {
          console.error(`Error getting Transaction Data ${error}`);
          sessionStorage.removeItem('SessionId');
          message = {
            msg: `Some error occured, could not identify the status of this transaction.Please check on blockchain explorer. <br/> <a target='_blank' href=https://explorer.elrond.com/transactions/${txHash}>Click Here</a>`,
            type: 'error',
            title: ''
          };
          loaderHandler(false, true, null, message);
          removeAllSignedTransactions();
        }
      });
  };

  React.useEffect(() => {
    txHash ? getTransactionData() : '';
  }, [txHash]);

  return <></>;
}

export default TrackTransaction;
