import CommonHelper from 'Helper/common-helper';
import Timer from 'components/timer';
import { maxNftSelection } from 'config';
import NftCard from 'pages/NftCard';
import WalletNFTs from 'pages/WalletNFTs';
import React from 'react';
import { Col, Card } from 'react-bootstrap';

export interface MyProps {
  selectedNFTs: any;
}
const ModalCard = (props: React.PropsWithChildren<MyProps>) => {
  const { selectedNFTs } = props;
  const [isFlipped, setIsFlipped] = React.useState(false);
  const [loanExpired, setLoanExpired] = React.useState<boolean>(false);
  const isloanExpired = (data: boolean) => {
    setLoanExpired(data);
  };

  console.log(selectedNFTs);
  return (
    <>
      <Col xs={6} md={4} lg={3} className='scene scene--card'>
        <Card border='info' className={isFlipped ? ' is-flipped' : ''}>
          <div className='NFT__Card card__face card__face--front note'>
            <div
              className='NFT__Card--Img_Container cur-pointer'
              onClick={() => setIsFlipped(!isFlipped)}
            >
              <video
                poster={selectedNFTs.url}
                autoPlay
                playsInline
                muted
                loop
                className={'NFT__Card--Img'}
              >
                <source src={selectedNFTs.url} type='video/mp4' />
              </video>
            </div>
            <Card.Body
              className={
                (selectedNFTs.extendedExpiryDate ? '' : 'p-0 ') +
                'NFT__CardBody'
              }
            >
              <div className='NFT__CardBody-details'>
                {/* <Card.Title className='NFT__CardBody--Title'>{name}</Card.Title> */}
                <Card.Title className='NFT__CardBody--Title'>
                  {/* {identifier} */}
                  {selectedNFTs.nftCommonName}
                </Card.Title>
              </div>
              {selectedNFTs.extendedExpiryDate ? (
                <>
                  <div className='NFT__CardBody--id card-title h5'>
                    Loan Amount:{' '}
                    {CommonHelper.fixedNum(
                      selectedNFTs.loanAmount / 10 ** 18,
                      6
                    )}
                  </div>
                  <div className='NFT__CardBody--id card-title h5'>
                    Fee:{' '}
                    {CommonHelper.fixedNum(selectedNFTs.pawnFee / 10 ** 18, 6)}
                  </div>
                  <div className='NFT__CardBody--id card-title h5'>
                    <label className={loanExpired ? 'timer-expired' : ''}>
                      Due by:{' '}
                      <Timer
                        startTime={new Date().getTime()}
                        endTime={selectedNFTs.extendedExpiryDate * 1000}
                        isloanExpired={(data: boolean) => isloanExpired(data)}
                      ></Timer>
                    </label>
                  </div>
                </>
              ) : (
                ''
              )}
            </Card.Body>
          </div>
          <div
            className='card__face card__face--back '
            onClick={() => setIsFlipped(!isFlipped)}
          >
            <div className='card__face--back-container note'>
              <Card.Title className='orange-text font-18 text-center'>
                {selectedNFTs.tokenIdentifier}
              </Card.Title>
              <Card.Title className='orange-text font-18 text-center'>
                Tags:
              </Card.Title>
              {selectedNFTs.tags.length ? (
                <div className='nft-tags-container'>
                  {selectedNFTs.tags.map((tag: string, index: number) => (
                    <span className='nft-tags' key={index}>
                      {tag}
                    </span>
                  ))}
                </div>
              ) : (
                <Card.Title className='text-white'>
                  No Tags Available
                </Card.Title>
              )}
            </div>
          </div>
        </Card>
      </Col>
    </>
  );
};

export default ModalCard;
