import * as React from 'react';
// import ConfirmModal from 'components/ConfirmModal';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import TransactionHistory from 'components/transactionHistory';
import CommonHelper from 'Helper/common-helper';
import { routeNames } from 'routes';
import homeLogo from '../assets/Home-Logo.png';

// import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';
import { PawnNFT } from './types/PawnLoanType';
// import { PawnNFT, PawnShopStats } from './types/PawnLoanType';

const Home = () => {
  sessionStorage.setItem('currentRoute', 'home');
  const navigate = useNavigate();
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const { network } = useGetNetworkConfig();
  // const [pawnShopStats, setPawnShopStats] = React.useState<PawnShopStats>();
  const [totalOwedByUser, setTotalOwedByUser] = React.useState<number>(0);
  const [pawnedNFTsByUser, setPawnedNFTsByUser] = React.useState<PawnNFT[]>([]);
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  async function loadSystemStats() {
    // const stats = await CommonHelper.loadSystemStats(proxy);
    // setPawnShopStats(stats);

    if (isLoggedIn) {
      const pawnedNFTsForUser: PawnNFT[] =
        await CommonHelper.loadPawnedNFTsForUser(proxy, address);
      let totalOwedValue = 0;
      for (let index = 0; index < pawnedNFTsForUser.length; index++) {
        const pawnedNFT = pawnedNFTsForUser[index];
        totalOwedValue += pawnedNFT.loanAmount + pawnedNFT.pawnFee;
      }

      setTotalOwedByUser(totalOwedValue);
      setPawnedNFTsByUser(pawnedNFTsForUser);
    }
  }

  React.useEffect(() => {
    loadSystemStats();
  }, []);

  return (
    <div className='home-page'>
      <div className='row col-xl-7 col-lg-10 col-md-11 col-12 margin-auto p-0 m-t-40'>
        <Col xs={12} className='text-center'>
          <img className='img-fluid' src={homeLogo}></img>
        </Col>
      </div>
      &nbsp;
      <div className='row col-lg-10 col-md-11 col-12 margin-auto home-content'>
        {isLoggedIn ? (
          <Col xs={6} md={3} className='home-content-1'>
            <div className='nft-details w-100'>
              <h4 className='orange-text'>
                <label>Active Loans</label>
                <label>{pawnedNFTsByUser.length}</label>
              </h4>
            </div>
          </Col>
        ) : (
          ''
        )}
        <Col xs={6} md={3} className='home-content-2'>
          <button
            className='pawn-btn btn margin-auto btn-light'
            onClick={() => navigate(routeNames.collections)}
          >
            GET LOAN
          </button>
        </Col>
        <Col xs={6} md={3} className='home-content-3'>
          <button
            className='claim-btn btn margin-auto btn-light'
            onClick={() => navigate(routeNames.pawned)}
          >
            PAY LOAN
          </button>
        </Col>
        {isLoggedIn ? (
          <Col xs={6} md={3} className='home-content-4'>
            <div className='nft-details w-100'>
              <h4 className='orange-text'>
                <label>Amount Due</label>
                <label>
                  {CommonHelper.fixedNum(totalOwedByUser / 10 ** 18, 6)} EGLD
                </label>
              </h4>
            </div>
          </Col>
        ) : (
          ''
        )}
      </div>
      <TransactionHistory></TransactionHistory>
    </div>
  );
};

export default Home;
