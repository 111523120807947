import React from 'react';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { useNavigate } from 'react-router-dom';
import CommonHelper from 'Helper/common-helper';
import { NFTCollection, TierLoanInfo } from 'pages/types/PawnLoanType';
import { routeNames } from 'routes';

export interface collection {
  collection_token_identifier: string;
  floor_price: number;
  active: false;
  company_name: string;
}
export interface MyProps {
  nftCollectionInfo: { [name: string]: NFTCollection[] };
}
const CollectionsTabs = (props: React.PropsWithChildren<MyProps>) => {
  const { network } = useGetNetworkConfig();
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  const { nftCollectionInfo } = props;
  const tiers = Object.keys(nftCollectionInfo).reverse();
  const [baseTierInfoFromSC, setBaseTierInfoFromSC] =
    React.useState<TierLoanInfo[]>();
  const navigate = useNavigate();
  React.useEffect(() => {
    loadTierInfo();
  }, []);

  async function loadTierInfo() {
    const tiersInfo = await CommonHelper.getAllTiersLoanInfo(proxy);
    setBaseTierInfoFromSC(tiersInfo);
  }
  const pawn = (data: NFTCollection) => {
    navigate(routeNames.collectionNFTs, {
      state: data.collectionTokenIdentifier
    });
  };
  console.log(nftCollectionInfo);
  console.log(tiers);
  let count = 0;
  for (let i = 0; i < tiers.length; i++) {
    for (let j = 0; j < nftCollectionInfo[tiers[i]].length; j++) {
      if (nftCollectionInfo[tiers[i]][j].active) {
        count++;
      }
    }
  }
  return (
    <>
      <div className='row col-lg-10 col-md-11 col-12 margin-auto'>
        {tiers.length && baseTierInfoFromSC ? (
          <article className='tabs v-tabs'>
            <div className='tab-labels row col-12 m-p-0 custom-card'>
              <div className='nft-with-cta row'>
                {count < 1 ? (
                  <div className='pawnNft-container'>
                    <div className='nftListLabel text-center'>
                      No Collections Available
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {tiers.map((tier: string, index: number) => (
                  <React.Fragment key={index}>
                    {nftCollectionInfo[tier].length
                      ? nftCollectionInfo[tier].map(
                          (collection: NFTCollection) =>
                            collection.active ? (
                              <React.Fragment
                                key={collection.collectionTokenIdentifier}
                              >
                                <div className='collection-item col-md-3 col-6'>
                                  <div
                                    className='card border-info cur-pointer'
                                    onClick={() => pawn(collection)}
                                  >
                                    <div className='NFT__Card'>
                                      <div className='NFT__Card--Img_Container'>
                                        <video
                                          poster={collection.url}
                                          autoPlay
                                          playsInline
                                          muted
                                          loop
                                          className='NFT__Card--Img'
                                        >
                                          <source
                                            src={collection.url}
                                            type='video/mp4'
                                          />
                                        </video>
                                      </div>
                                      <div className='NFT__CardBody card-body'>
                                        <div className='NFT__CardBody-details'>
                                          <div className='NFT__CardBody--Title card-title h5'>
                                            <b>
                                              {/* {collection.collectionTokenIdentifier} */}
                                              {collection.collectionName}
                                            </b>
                                          </div>
                                        </div>
                                        <div className='NFT__CardBody--id card-title h5'>
                                          Floor Price: {collection.floorPrice}
                                        </div>

                                        <div className='NFT__CardBody--id card-title h5'>
                                          Base Loan:{' '}
                                          {CommonHelper.fixedNum(
                                            (collection.floorPrice *
                                              collection.collection_max_loan_percentage) /
                                              100,
                                            2
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : (
                              ''
                            )
                        )
                      : ''}
                    {/* {nftCollectionInfo[tier].length &&
                    !nftCollectionInfo[tier].find((o) => o.active === true) ? (
                      <div className='nftListLabel'>
                        No Collections Available in {tier} Tier
                      </div>
                    ) : (
                      ''
                    )} */}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </article>
        ) : (
          <div className='pawnNft-container'>
            <div className='nftListLabel text-center'>
              No Collections Available
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CollectionsTabs;
