import React from 'react';

import { Link } from 'react-router-dom';
import { routeNames } from 'routes';

function LoginButton() {
  return (
    <div>
      <Link
        to={routeNames.unlock}
        className='loginButton btn btn-primary'
        data-testid='loginBtn'
      >
        Connect
      </Link>
    </div>
  );
}

export default LoginButton;
