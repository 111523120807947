import {
  AbiRegistry,
  Address,
  SmartContractAbi,
  SmartContract,
  Interaction,
  Transaction,
  ResultsParser
} from '@multiversx/sdk-core';

import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

export default class SmartContractService {
  // static contractMapping: { [contractAddress: string]: SmartContract } = {};
  static resultParser: ResultsParser = new ResultsParser();
  static createContract = async (
    contractAddress: string,
    abiJson: any,
    interfaces: string[]
  ) => {
    // const abi = await AbiRegistry.load({
    //   urls: [abiLocation]
    // });

    const abi = await AbiRegistry.create(abiJson);

    const contract = new SmartContract({
      address: new Address(contractAddress),
      abi: new SmartContractAbi(abi, interfaces)
    });

    return contract;
  };

  // static getContract = (contractAddress: string) => {
  //   const contract = SmartContractService.contractMapping[contractAddress];
  //   return contract;
  // };

  static async getContractResult(
    interaction: Interaction,
    proxy: ProxyNetworkProvider
  ) {
    const query = interaction.buildQuery();
    const queryResult = await proxy.queryContract(query);
    const { firstValue } = SmartContractService.resultParser.parseQueryResponse(
      queryResult,
      interaction.getEndpoint()
    );

    return { firstValue };
  }

  static async sendTransaction(tx: Transaction, proxy: ProxyNetworkProvider) {
    const transactionHash = await proxy.sendTransaction(tx);
    return transactionHash;
  }
}
