import withPageTitle from 'components/PageTitle';
import { dAppName } from 'config';
import AdminClaim from 'pages/adminClaim';
import AdminSettings from 'pages/AdminSettings';
import AdminStats from 'pages/adminViewStats';
import ClaimNFT from 'pages/claimNFT';
import CollectionNFTs from 'pages/collectionNFTs';
import Collections from 'pages/collections';
import Home from 'pages/home';
import Overview from 'pages/overview';
import Pawned from 'pages/pawned';
import PawnNFT from 'pages/pawnNFT';

export const routeNames: { [name: string]: string } = {
  home: '/',
  unlock: '/unlock',
  overview: '/overview',
  collections: '/collections',
  pawned: '/pawned',
  collectionNFTs: '/collectionNFTs',
  pawnNFT: '/pawnNFT',
  claimNFT: '/claimNFT',
  AdminStats: '/AdminStats',
  AdminClaim: '/AdminClaim',
  AdminSettings: '/AdminSettings'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.collections,
    title: 'Explore Collections',
    component: Collections
  },
  {
    path: routeNames.pawned,
    title: 'Pawned NFTs',
    component: Pawned
  },
  {
    path: routeNames.overview,
    title: 'Stake/Unstake',
    component: Overview
  },
  {
    path: routeNames.collectionNFTs,
    title: 'Collection NFTs',
    component: CollectionNFTs
  },
  {
    path: routeNames.pawnNFT,
    title: 'Pawn NFT',
    component: PawnNFT
  },
  {
    path: routeNames.claimNFT,
    title: 'Claim NFT',
    component: ClaimNFT
  },
  {
    path: routeNames.AdminStats,
    title: 'Statistics',
    component: AdminStats
  },
  {
    path: routeNames.AdminClaim,
    title: 'Claim NFTs',
    component: AdminClaim
  },
  {
    path: routeNames.AdminSettings,
    title: 'Settings',
    component: AdminSettings
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} •  ${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
