import React from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { baseURL, maxNftSelection } from 'config';
import NftCard from './NftCard';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';

function WalletNFTs(props: any) {
  const {
    loaderHandler,
    OnTransactionSent,
    collectionIdentifiers,
    action,
    collectionInfo,
    msg
  } = props;
  const [unstackedNFTsData, setUnstackedNFTsData] = React.useState<any>();
  const { address } = useGetAccountInfo();
  const [tags, setTags] = React.useState<{ [name: string]: string[] }>({});
  const [identifierArr, setIdentifierArr] = React.useState<string[]>([]);
  // const [ranks, setRanks] = React.useState<number[]>([]);
  const pageId = 4;
  async function loadData() {
    let from = 0;
    const size = 25;
    const totalNFTs = [];
    let currentNFTsCount = 0;

    for (let index = 0; index < collectionIdentifiers.length; index++) {
      const collectionToken = collectionIdentifiers[index];
      const collectionTokenTicker = collectionToken.toString();
      from = 0;

      do {
        const unstakedNFTsUrl = `${baseURL}accounts/${address}/nfts?from=${from}&size=${size}&collections=${collectionTokenTicker}`;
        const nfts = await axios.get(unstakedNFTsUrl);
        if (nfts == null || undefined) {
          throw Error('nfts info could not found');
        }
        totalNFTs.push(...nfts.data);
        from += nfts.data.length;
        currentNFTsCount = nfts.data.length;
      } while (totalNFTs.length <= 125 && currentNFTsCount > 0);
    }
    const nftTags: { [name: string]: string[] } = {};
    for (let nftIndex = 0; nftIndex < totalNFTs.length; nftIndex++) {
      const nft = totalNFTs[nftIndex];
      if (nft.attributes) {
        const attributes = atob(nft.attributes);
        const tagStartIndex = attributes.indexOf('tags:');
        if (tagStartIndex > 0) {
          const lastSemicolonIndex = attributes.indexOf(';', tagStartIndex);
          const tagsData = attributes.substring(
            tagStartIndex,
            lastSemicolonIndex
          );
          const tagsRawData = tagsData.split(':').pop();
          if (tagsRawData) {
            const tagsArray = tagsRawData.split(',');
            nftTags[nft.identifier] = tagsArray;
          }
        }
      }
    }

    setTags(nftTags);
    // const allRanks = [];
    // for (let nftIndex = 0; nftIndex < totalNFTs.length; nftIndex++) {
    //   const nft = totalNFTs[nftIndex];
    //   const attributes = atob(nft.attributes);
    //   const rankStartIndex = attributes.indexOf('Rank-');
    //   const lastSemicolonIndex = attributes.indexOf(';', rankStartIndex);
    //   const rankAttribute = attributes.substring(
    //     rankStartIndex,
    //     lastSemicolonIndex
    //   );
    //   const rankInString = rankAttribute.split('-').pop();
    //   let rank = 0;
    //   if (rankInString !== undefined) {
    //     rank = parseInt(rankInString);
    //   }
    //   allRanks.push(rank);
    // }
    // setRanks(allRanks);

    setUnstackedNFTsData(totalNFTs);
  }
  React.useEffect(() => {
    loadData();
  }, [props.refresh]);

  const navigate = useNavigate();
  const pawnNow = () => {
    // alert('Pawn Now');
    console.log(identifierArr);
    if (identifierArr.length > maxNftSelection) {
      return;
    } else {
      navigate(routeNames.pawnNFT, {
        state: {
          selectedNftIdentifiers: identifierArr
        }
      });
    }
  };
  const cardInteraction = (selected: boolean, nftIdentifierStr: string) => {
    // console.log(selected);
    // console.log(nftIdentifierStr);
    let arr = identifierArr;
    if (selected) {
      arr.push(nftIdentifierStr);
    } else {
      arr = arr.filter((e) => e !== nftIdentifierStr);
    }
    setIdentifierArr([...arr]);
  };
  return (
    <>
      {action == 'pawn' && identifierArr.length ? (
        <div className='loan-btn-container'>
          <button
            className='NFT__CardBody--ButtonStack btn btn-primary pawn-btn col-12'
            onClick={() => pawnNow()}
            disabled={identifierArr.length > maxNftSelection}
          >
            GET LOAN for {identifierArr.length} NFT(s)
          </button>
          {/* {identifierArr.length >= maxNftSelection ? (
            <label className='orange-text'>
              {' '}
              Maximum {maxNftSelection} allowed message here{' '}
            </label>
          ) : (
            ''
          )} */}
        </div>
      ) : (
        ''
      )}
      <div className='NFTContainerBody nft-with-cta'>
        {!unstackedNFTsData ? (
          <div>
            <div className='nftListLabel'>{msg}</div>
          </div>
        ) : (
          ''
        )}
        {unstackedNFTsData && unstackedNFTsData.length === 0 ? (
          <div>
            <div className='nftListLabel'>{'No NFTs Available'}</div>
          </div>
        ) : (
          ''
        )}
        <div className='nft_container col-lg-10 col-md-11 col-12 margin-auto'>
          {unstackedNFTsData && unstackedNFTsData.length
            ? unstackedNFTsData.map((nftInfo: any, index: number) => {
                return (
                  <NftCard
                    nftInfo={nftInfo}
                    key={index}
                    action={action}
                    loaderHandler={loaderHandler}
                    pageId={pageId}
                    OnTransactionSent={OnTransactionSent}
                    tags={
                      tags[nftInfo.identifier] ? tags[nftInfo.identifier] : []
                    }
                    selectedCount={identifierArr.length}
                    collectionInfo={collectionInfo}
                    isSelected={(selected: boolean, nftIdentifierStr: string) =>
                      cardInteraction(selected, nftIdentifierStr)
                    }
                  />
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
}
WalletNFTs.defaultProps = {
  refresh: '',
  action: 'stake',
  msg: 'Loading NFTs...'
};

export default WalletNFTs;

// 5175
