import React, { useState, useEffect, useCallback } from 'react';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { Col } from 'react-bootstrap';
import { useTable, usePagination, useSortBy } from 'react-table';
import CommonHelper from 'Helper/common-helper';
import LoginButton from '../LoginButton';

export declare class ApiData {
  nftCommonName: string;
  token_identifier: string;
  token_nonce: string;
  loan_amount: string;
  pawn_date: string;
  actual_expiry_date: string;
  extended_expiry_date: string;
  loan_duration_ticks: string;
  pawn_fee: string;
  nft_counter: string;
  current_state: string;
  url: string;
}
[];

export declare class TableData {
  nftCommonName: string;
  identifier: string;
  loan_amount: string;
  pawn_date: string;
  actual_expiry_date: string;
  extended_expiry_date: string;
  loan_duration_ticks: string;
  pawn_fee: string;
  current_state: string;
  url: string;
}
[];

function Table({
  columns,
  data,
  fetchData,
  onSort,
  onSearch,
  loading,
  dataCount,
  pageCount: controlledPageCount
}: {
  columns: any;
  data: TableData[];
  fetchData: (tableArg: customTableArg) => void;
  onSort: (tableArg: customTableArg) => void;
  onSearch: (tableArg: customTableArg) => void;
  loading: boolean;
  pageCount: number;
  dataCount: number;
}): JSX.Element {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: true,
      autoResetSortBy: true,
      pageCount: controlledPageCount
    },
    useSortBy,
    usePagination
  );
  const [search, setSearch] = React.useState('');
  const [sortingValue, setSortingValue] = React.useState('');
  const [sortingOrder, setSortingOrder] = React.useState('');
  const [actualData, setActualData] = React.useState<TableData[]>([]);
  const { address } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();
  const [pawnHistory, setPawnHistoryData] = React.useState<ApiData[]>([]);

  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });

  async function LoadHistoryForAddress() {
    const historyFromSC = await CommonHelper.getTransactionHistory(
      proxy,
      address
    );
    const getPawnHistory: ApiData[] = [];
    for (let i = 0; i < historyFromSC.length; i++) {
      const historyObj = historyFromSC[i];
      let nonceInHex = historyObj.nonce.toString(16);
      if (nonceInHex.length % 2 == 1) {
        nonceInHex = `0${nonceInHex}`;
      }
      const individualHistoryRecord: ApiData = {
        nftCommonName: historyObj.nftCommonName,
        token_identifier: historyObj.tokenIdentifier.toString(),
        token_nonce: nonceInHex,
        loan_amount: historyObj.loanAmount.toString(),
        pawn_date: (historyObj.pawnDate * 1000).toString(),
        actual_expiry_date: (historyObj.actualExpiryDate * 1000).toString(),
        extended_expiry_date: (historyObj.extendedExpiryDate * 1000).toString(),
        loan_duration_ticks: historyObj.loanDurationTicks.toString(),
        pawn_fee: historyObj.pawnFee.toString(),
        nft_counter: historyObj.nftCounter.toString(),
        current_state: historyObj.currentState,
        url: historyObj.url
      };
      getPawnHistory.push(individualHistoryRecord);
    }
    // console.log(getPawnHistory);
    setPawnHistoryData(getPawnHistory);

    // console.log(pawnHistory);
  }
  useEffect(() => {
    LoadHistoryForAddress();
  }, []);

  useEffect(() => {
    const array1: TableData[] = [];
    const array2: TableData[] = [];

    for (let i = 0; i < pawnHistory.length; i++) {
      const dataObj: TableData = {
        nftCommonName: '',
        identifier: '',
        loan_amount: '',
        pawn_date: '',
        actual_expiry_date: '',
        extended_expiry_date: '',
        loan_duration_ticks: '',
        pawn_fee: '',
        current_state: '',
        url: ''
      };

      dataObj.identifier = `${pawnHistory[i].token_identifier}-${pawnHistory[i].token_nonce}`;
      dataObj.nftCommonName = pawnHistory[i].nftCommonName;
      dataObj.loan_amount = `${CommonHelper.fixedNum(
        parseFloat(pawnHistory[i].loan_amount) / 10 ** 18,
        4
      )}`;
      dataObj.pawn_date = CommonHelper.getDate(pawnHistory[i].pawn_date);
      dataObj.actual_expiry_date = CommonHelper.getDate(
        pawnHistory[i].actual_expiry_date
      );
      dataObj.extended_expiry_date = CommonHelper.getDate(
        pawnHistory[i].extended_expiry_date
      );
      let durationToDisplay = '';
      const duration = parseInt(pawnHistory[i].loan_duration_ticks);
      if (duration) {
        if (duration / (24 * 60 * 60) >= 1) {
          durationToDisplay = `${Math.floor(duration / (24 * 60 * 60))} Days`;
        } else if (duration / (60 * 60) >= 1) {
          durationToDisplay = `${Math.floor(duration / (60 * 60))} Hours`;
        } else if (duration % 60 == 0) {
          durationToDisplay = `${duration / 60} Minute`;
        }
      }
      dataObj.loan_duration_ticks = durationToDisplay;
      dataObj.pawn_fee = `${CommonHelper.fixedNum(
        parseFloat(pawnHistory[i].pawn_fee) / 10 ** 18,
        6
      )}`;
      dataObj.current_state =
        pawnHistory[i].current_state == 'Pawned' &&
        new Date() > new Date(dataObj.extended_expiry_date)
          ? 'Expired'
          : pawnHistory[i].current_state;

      // if (priceHex.length % 2) {
      //   priceHex = '0' + priceHex;
      // }
      if (dataObj.current_state == 'Pawned') {
        array1.push(dataObj);
      } else {
        array2.push(dataObj);
      }
      dataObj.url = pawnHistory[i].url;
    }
    function sortByExpiry(a: any, b: any) {
      return (
        new Date(a.extended_expiry_date).getTime() -
        new Date(b.extended_expiry_date).getTime()
      );
    }
    function sortByPawnDate(a: any, b: any) {
      return new Date(b.pawn_date).getTime() - new Date(a.pawn_date).getTime();
    }
    array1.sort(sortByExpiry);
    array2.sort(sortByPawnDate);

    setActualData([...array1, ...array2]);
  }, [pawnHistory]);
  useEffect(() => {
    onSort({ actualData, sortBy, pageIndex, pageSize, search });
  }, [search, onSort, sortBy, fetchData, pageIndex, pageSize]);
  useEffect(() => {
    fetchData({ actualData, sortBy, pageIndex, pageSize, search });
  }, [actualData]);

  useEffect(() => {
    // console.log(data.length , pageIndex*pageSize)
    if (!data.length) {
      gotoPage(0);
    }
  }, [data]);

  const handleSearch = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const searchVal = event.target.value;
    onSearch({ actualData, sortBy, pageIndex, pageSize, search });
    setSearch(searchVal);
  };

  useEffect(() => {
    if (sortingValue == 'none' || !sortingValue) {
      setSortBy([]);
    } else {
      setSortBy([
        {
          id: sortingValue,
          desc: sortingOrder == 'dec' ? true : false
        }
      ]);
    }
  }, [sortingValue, sortingOrder]);

  return (
    <>
      {pawnHistory.length ? (
        <>
          <div className='nftListLabel text-center'>Transaction History</div>
          <Col xs={12}>
            <div className='row responsive'>
              <div className='table-dropdown-container col-12 col-sm-6 text-right'>
                <label className='text-white h6'>Rows per page:</label>
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 15, 20].map((pageSizeOptions) => (
                    <option key={pageSizeOptions} value={pageSizeOptions}>
                      {pageSizeOptions}
                    </option>
                  ))}
                </select>
              </div>
              <div className='table-sort-container col-12 m-b-15'>
                <div className='row'>
                  <div className='col-6'>
                    <label className='col-12 text-white m-p-0'>Sort By: </label>
                    <select
                      className='col-12'
                      onChange={(e) => setSortingValue(e.target.value)}
                    >
                      <option value='none'>None</option>
                      {headerGroups.map((headerGroup) =>
                        headerGroup.headers.map((column, index) =>
                          column.render('canSort') ? (
                            <option
                              value={column.render('id')?.toString()}
                              key={'th-th' + index}
                            >
                              {column.render('Header')}
                            </option>
                          ) : (
                            ''
                          )
                        )
                      )}
                    </select>
                  </div>
                  <div className='col-6'>
                    <label className='col-12 text-white m-p-0'>
                      Sort Order:{' '}
                    </label>
                    <select
                      className='col-12'
                      onChange={(e) => setSortingOrder(e.target.value)}
                    >
                      <option value='acs'>Ascending</option>
                      <option value='dec'>Descending</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='table-search-container col-12 col-sm-6'>
                {/* <input class="form-control border-end-0 border rounded-pill" /> */}
                <input
                  id='search'
                  className='form-control border-end-0 col-12 col-sm-6'
                  placeholder='Search in Table'
                  type='search'
                  onChange={handleSearch}
                />
              </div>
              <div className='col-12'>
                <table
                  className='tb-transaction table table-responsive table-striped text-white'
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map((headerGroup, i) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={'th-tr' + i}
                      >
                        {headerGroup.headers.map((column, index) => (
                          // we can add them into the header props
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={'th-th' + index}
                          >
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' ▼'
                                  : ' ▲'
                                : ''}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={'tb-tr' + index}>
                          {row.cells.map((cell, i) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                key={index + 'tb-td' + i}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    {loading ? (
                      <tr>
                        {/* // Use our custom loading state to show a loading indicator */}
                        <td colSpan={10000}>Loading...</td>
                      </tr>
                    ) : (
                      <tr className='d-none'></tr>
                      // <tr>
                      //   <td colSpan={10000} className='text-center'>
                      //     Showing {pageSize * pageIndex + 1} to{' '}
                      //     {page.length == pageSize
                      //       ? pageSize * (pageIndex + 1)
                      //       : pageSize * pageIndex + page.length}{' '}
                      //     of {dataCount} results
                      //   </td>
                      // </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='text-white text-center col-12'>
                Showing {pageSize * pageIndex + 1} to{' '}
                {page.length == pageSize
                  ? pageSize * (pageIndex + 1)
                  : pageSize * pageIndex + page.length}{' '}
                of {dataCount} results
              </div>
            </div>
          </Col>
          <Col className='w-100'>
            <div className='pagination row'>
              <div className='btn-group col-6 col-sm-4'>
                <button
                  className='btn btn-light'
                  onClick={() => gotoPage(0)}
                  disabled={pageIndex + 1 <= 1}
                >
                  {'<< First'}
                </button>
                <button
                  className='btn btn-light'
                  onClick={() => previousPage()}
                  disabled={pageIndex + 1 <= 1}
                >
                  {'< Prev'}
                </button>
              </div>
              <div className='page-counter text-center col-12 col-sm-4'>
                <span className='text-white'>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
              </div>
              <div className='btn-group col-6 col-sm-4'>
                <button
                  className='btn btn-light'
                  onClick={() => nextPage()}
                  disabled={pageIndex + 1 >= pageOptions.length}
                >
                  {'Next >'}
                </button>
                <button
                  className='btn btn-light'
                  onClick={() => gotoPage(pageOptions.length - 1)}
                  disabled={pageIndex + 1 >= pageOptions.length}
                >
                  {'Last >>'}
                </button>
              </div>
            </div>
          </Col>
        </>
      ) : (
        <div className='nftListLabel'>No Transaction History</div>
      )}
    </>
  );
}

const TransactionHistory = () => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);

  const [filterdData, setFilterdData] = useState<TableData[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [dataCount, setDataCount] = React.useState(0);

  const fetchIdRef = React.useRef(0);
  const sortIdRef = React.useRef(0);
  const columns: {
    Header: string;
    accessor: string;
  }[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'nftCommonName',
        Cell: ({ cell: { value } }: any) => (
          <div>
            <span className='mb-header'>Name: </span>
            <span className={value}>{value}</span>
          </div>
        )
      },
      {
        Header: 'NFT',
        accessor: 'url',
        Cell: ({ cell: { value } }: any) => (
          <div>
            <span className='mb-header'>Expiry Date: </span>
            <video
              poster={value}
              autoPlay
              muted
              playsInline
              loop
              className='NFT__Card--Img'
            >
              <source src={value} type='video/mp4' />
            </video>
          </div>
        ),
        disableSortBy: true
      },
      {
        Header: 'Identifier',
        accessor: 'identifier',
        Cell: ({ cell: { value } }: any) => (
          <div>
            <span className='mb-header'>Identifier: </span>
            <span className={value}>{value}</span>
          </div>
        )
      },
      {
        Header: 'Loan (EGLD)',
        accessor: 'loan_amount',
        Cell: ({ cell: { value } }: any) => (
          <div>
            <span className='mb-header'>Loan (EGLD): </span>
            <span className={value}>{value}</span>
          </div>
        )
      },
      {
        Header: 'Fee (EGLD)',
        accessor: 'pawn_fee',
        Cell: ({ cell: { value } }: any) => (
          <div>
            <span className='mb-header'>Fee (EGLD): </span>
            <span className={value}>{value}</span>
          </div>
        )
      },
      {
        Header: 'Loan Duration',
        accessor: 'loan_duration_ticks',
        Cell: ({ cell: { value } }: any) => (
          <div>
            <span className='mb-header'>Loan Duration: </span>
            <span className={value}>{value}</span>
          </div>
        )
      },
      {
        Header: 'Pawn Date',
        accessor: 'pawn_date',
        Cell: ({ cell: { value } }: any) => (
          <div>
            <span className='mb-header'>Pawn Date: </span>
            <span className={value}>{value}</span>
          </div>
        )
      },
      // {
      //   Header: 'Actual Expiry Date',
      //   accessor: 'actual_expiry_date'
      // },
      {
        Header: 'Expiry Date',
        accessor: 'extended_expiry_date',
        Cell: ({ cell: { value } }: any) => (
          <div>
            <span className='mb-header'>Expiry Date: </span>
            <span className={value}>{value}</span>
          </div>
        )
      },

      // {
      //   Header: 'Nft Counter',
      //   accessor: 'nft_counter'
      // },
      {
        Header: 'Current State',
        accessor: 'current_state',
        Cell: ({ cell: { value } }: any) => (
          <div>
            <span className='mb-header'>Current State: </span>
            <span className={value}>{value}</span>
          </div>
        )
      }
    ],
    []
  );

  const fetchData = React.useCallback((tableArg: customTableArg) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = tableArg.pageSize * tableArg.pageIndex;
        const endRow = startRow + tableArg.pageSize;
        setDataCount(tableArg.actualData.length);
        setFilterdData(tableArg.actualData.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(tableArg.actualData.length / tableArg.pageSize));

        setLoading(false);
      }
    }, 1000);
  }, []);
  const searchResult = (
    actualData: TableData[],
    search: string,
    pageSize: number
  ) => {
    const newArray = actualData.filter(function (el: TableData) {
      for (let i = 0; i < Object.values(el).length; i++) {
        if (Object.values(el)[i].toLowerCase().includes(search.toLowerCase())) {
          return 1;
        }
      }
    });
    setPageCount(Math.ceil(newArray.length / pageSize));
    return newArray;
  };
  const handleSort = useCallback((tableArg: customTableArg) => {
    // Give this sort an ID
    const sortId = ++sortIdRef.current;
    // Set the loading state
    setLoading(true);
    // Doing multisort
    const newArray: TableData[] = searchResult(
      tableArg.actualData,
      tableArg.search,
      tableArg.pageSize
    );
    if (sortId === sortIdRef.current) {
      const sorted = newArray.slice();
      sorted.sort((a: any, b: any) => {
        for (let i = 0; i < tableArg.sortBy.length; ++i) {
          if (
            tableArg.sortBy[i].id == 'pawn_date' ||
            tableArg.sortBy[i].id == 'actual_expiry_date' ||
            tableArg.sortBy[i].id == 'extended_expiry_date'
          ) {
            const time1 = a[tableArg.sortBy[i].id].split(' ');
            const time2 = b[tableArg.sortBy[i].id].split(' ');
            const part1 = time1[0].split('-');
            const part2 = time2[0].split('-');
            const d1 = `${part1[2]}-${part1[1]}-${part1[0]} ${time1[1]}`;
            const d2 = `${part2[2]}-${part2[1]}-${part2[0]} ${time2[1]}`;
            const sortVal = Date.parse(d1) > Date.parse(d2) ? 1 : -1;
            return tableArg.sortBy[i].desc ? -1 * sortVal : sortVal;
          } else if (tableArg.sortBy[i].id == 'loan_duration_ticks') {
            const part1 = a[tableArg.sortBy[i].id].split(' ');
            const part2 = b[tableArg.sortBy[i].id].split(' ');
            const t1 =
              part1[1] == 'Days'
                ? '3Days' + part1[0]
                : part1[1] == 'Hours'
                ? '2Hours' + part1[0]
                : '1Minute' + part1[0];
            const t2 =
              part2[1] == 'Days'
                ? '3Days' + part2[0]
                : part2[1] == 'Hours'
                ? '2Hours' + part2[0]
                : '1Minute' + part2[0];
            // console.log(t1, t2);
            const sortVal = t1.localeCompare(t2, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
            return tableArg.sortBy[i].desc ? -1 * sortVal : sortVal;
          } else {
            const sortVal = a[tableArg.sortBy[i].id].localeCompare(
              b[tableArg.sortBy[i].id],
              undefined,
              {
                numeric: true,
                sensitivity: 'base'
              }
            );
            return tableArg.sortBy[i].desc ? -1 * sortVal : sortVal;
          }
          // if (a[sortBy[i].id] > b[sortBy[i].id])
          //   return sortBy[i].desc ? -1 : 1;
          // if (a[sortBy[i].id] < b[sortBy[i].id])
          //   return sortBy[i].desc ? 1 : -1;
        }
        return 0;
      });
      const startRow = tableArg.pageSize * tableArg.pageIndex;
      const endRow = startRow + tableArg.pageSize;
      setDataCount(sorted.length);
      setFilterdData(sorted.slice(startRow, endRow));
      setLoading(false);
    }
  }, []);
  const handleSearch = useCallback((tableArg: customTableArg) => {
    // const newArray = actualData.filter(function (el: TableData) {
    //   for (let i = 0; i < Object.values(el).length; i++) {
    //     if (
    //       Object.values(el)[i].toLowerCase().includes(search.toLowerCase())
    //     ) {
    //       return 1;
    //     }
    //   }
    // });
    const newArray: TableData[] = searchResult(
      tableArg.actualData,
      tableArg.search,
      tableArg.pageSize
    );
    const startRow = tableArg.pageSize * tableArg.pageIndex;
    const endRow = startRow + tableArg.pageSize;
    setDataCount(newArray.length);
    setFilterdData(newArray.slice(startRow, endRow));
  }, []);
  return (
    <>
      {isLoggedIn ? (
        <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container'>
          <Table
            columns={columns}
            data={filterdData}
            onSort={handleSort}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            onSearch={handleSearch}
            dataCount={dataCount}
          />
        </div>
      ) : (
        <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container'>
          <div className='nftListLabel text-center'>
            Login to view Transaction History
            <LoginButton />
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionHistory;

export declare class customTableArg {
  actualData: TableData[];
  sortBy: any[] | [];
  pageIndex: number;
  pageSize: number;
  search: string | '';
}
