import React from 'react';

function calculateTimeLeft(
  d1: string | number | Date,
  d2: string | number | Date
) {
  const difference = +new Date(d2) - +new Date(d1);
  return difference;
}
function counter(difference: number) {
  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      d: Math.floor(difference / (1000 * 60 * 60 * 24)),
      h: Math.floor((difference / (1000 * 60 * 60)) % 24),
      m: Math.floor((difference / 1000 / 60) % 60),
      s: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
}

export default function Timer(props: {
  startTime: any;
  endTime: any;
  isloanExpired: (arg0: boolean) => void;
}) {
  const { startTime, endTime } = props;
  const [timeDiff, setTimeDiff] = React.useState<number>(
    calculateTimeLeft(startTime, endTime)
  );
  const [timeLeft, setTimeLeft] = React.useState<any>({});

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeDiff(timeDiff > -2 ? timeDiff - 1000 : -2);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timeDiff]);

  React.useEffect(() => {
    setTimeLeft(counter(timeDiff));
  }, [timeDiff]);

  const timerComponents =
    timeLeft &&
    Object.keys(timeLeft).map((interval) => {
      //   if (!timeLeft[interval]) {
      //     return;
      //   }

      return (
        <React.Fragment key={interval}>
          {timeLeft[interval]}
          {interval}{' '}
        </React.Fragment>
      );
    });
  React.useEffect(() => {
    if (!timerComponents.length && timeDiff < 0) {
      props.isloanExpired(true);
    } else {
      props.isloanExpired(false);
    }
  }, [timeDiff]);

  return timerComponents.length ? (
    <span className={timeDiff <= 86400000 ? 'error' : ''}>
      {timerComponents}
    </span>
  ) : (
    <span className='error'>Expired</span>
  );
}
Timer.defaultProps = {
  isloanExpired: () => {
    ('');
  }
};
