export declare class NFTCollection {
  collectionTokenIdentifier: string;
  collectionName: string;
  floorPrice: number;
  active: boolean;
  collectionTier: string;
  collection_max_loan_percentage: number;
  companyName: string;
  url: string;
}
export declare class NFTCollectionAdmin {
  collectionTokenIdentifier: string;
  collectionName: string;
  floorPrice: number;
  active: boolean;
  collectionTier: string;
  companyName: string;
}
export enum BoostLevel {
  NoBoost,
  Pawn,
  Knight,
  Bishop,
  Rook,
  Queen,
  King,
  FullSet
}

export declare enum CollectionTier {
  Bronze,
  Silver,
  Gold,
  Platinum,
  Diamond
}

export declare class NFTCompany {
  companyName: string;
  maxLoanPoolLimitPercentageForCompany: number;
  loansAllowedForCompany: boolean;
}

export declare class TierLoanInfo {
  collectionTier: CollectionTier;
  collectionMaxLoanRatePercentage: number;
}

export declare class BoostInfo {
  level: BoostLevel;
  loanBoostPercentage: number;
  loanFeeDiscountPercentage: number;
}

export declare class PawnFeeInfo {
  loanDurationTicks: number;
  loanDurationForDisplay: string;
  feePercentage: number;
  maxAllowedLoanExtensionTicks: number;
}
export declare class PawnFeeInfoAdmin {
  loanDurationTicks: number;
  feePercentage: number;
  maxAllowedLoanExtensionTicks: number;
}
export declare class PawnNFT extends PawnHistory {
  boosterNfts: [];
  boostLevel: BoostLevel;
  loanBoostAmount: number;
  feeDiscount: number;
  holder: string;
}

export declare class ComapnyLoanIssuedInfo {
  companyName: string;
  loanIssued: number;
}

export declare class PawnHistory {
  tokenIdentifier: string;
  nonce: number;
  loanAmount: number;
  pawnDate: number;
  actualExpiryDate: number;
  extendedExpiryDate: number;
  loanDurationTicks: number;
  pawnFee: number;
  nftCounter: number;
  nftCommonName: string;
  url: string;
  tags: string[];
  currentState: string;
}

export declare class PawnShopStats {
  totalPoolAmount: number;
  totalPoolAmountDelta: number;
  totalBorrowedAmount: number;
  totalBorrowedAmountDelta: number;
  currentBorrowedAmount: number;
  currentBorrowedAmountDelta: number;
  totalLoanRepaid: number;
  totalLoanRepaidDelta: number;
  totalFeeCollected: number;
  totalFeeCollectedDelta: number;
  totalDefaultedFee: number;
  totalDefaultedFeeDelta: number;
  recoveredDefaultedLoan: number;
  recoveredDefaultedFee: number;
  totalActiveLoans: number;
  totalNumberOfGoodLoans: number;
  totalNumberOfBadLoans: number;
  totalUsedStakedPasses: number;
  totalAmountWithdrawn: number;
  totalDefaultedLoan: number;
  totalLoanToRecover: number;
  totalEGLDProfit: number;
  userAddedPoolAmount: number;
}

export declare class PawnShopTradingInfo {
  tokenIdentifier: string;
  newFloorPrice: number;
  weeklyTradeCount: number;
  weekMinSalePrice: number;
  monthlyTradeCount: number;
  monthMinSalePrice: number;
  currentMktFloorPrice: number;
}
