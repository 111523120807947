import * as React from 'react';
// import ConfirmModal from 'components/ConfirmModal';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import { Col } from 'react-bootstrap';
import CollectionsTabs from 'components/collectionsTabs';
import { baseURL } from 'config';
import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';
import { NFTCollection } from './types/PawnLoanType';
import omniscientLogo from '../assets/omniscientLogo.png';
import StepsModule from 'components/stepsModule';

const Collections = () => {
  sessionStorage.setItem('currentRoute', 'collections');
  const { network } = useGetNetworkConfig();
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  const [tierCollectionInfo, setTierCollectionInfo] = React.useState<{
    [name: string]: NFTCollection[];
  }>();
  async function loadNFTCollections() {
    const tiersLoanInfo = await PawnShopSmartContract.getAllTiersLoanInfo(
      proxy
    );
    if (tiersLoanInfo == null) {
      throw new Error('Could not load tier loan info');
    }

    const nftCollections = await PawnShopSmartContract.getAllNFTCollections(
      proxy
    );
    if (nftCollections == null) {
      throw new Error('Could not load NFT collections');
    }
    const tierCollectionInfolocal: { [name: string]: NFTCollection[] } = {};
    for (let index = 0; index < tiersLoanInfo.length; index++) {
      const tierLoanInfo = tiersLoanInfo[index];
      tierCollectionInfolocal[tierLoanInfo.collection_tier.name] = [];
    }
    let allNFTsCommaSaperated = '';
    let count = 0;
    const nftsDetails = [];
    //traverse through this collection and create a dictionary with key tier and corresponding collections.
    for (let index = 0; index < nftCollections.length; index++) {
      const collection = nftCollections[index];
      if (
        collection.collection_token_identifier.toUpperCase() === 'VPRSFT-832941'
      ) {
        allNFTsCommaSaperated += `${collection.collection_token_identifier}-01,`;
      } else if (
        collection.collection_token_identifier.toUpperCase() ===
        'SUBJECTX-2C184D'
      ) {
        allNFTsCommaSaperated += `${collection.collection_token_identifier}-216e,`;
      } else {
        allNFTsCommaSaperated += `${collection.collection_token_identifier}-02,`;
      }
      count++;
      if (count % 25 == 0 || count == nftCollections.length) {
        allNFTsCommaSaperated = allNFTsCommaSaperated.substring(
          0,
          allNFTsCommaSaperated.length - 1
        );

        const collectionNFTsUrl = `${baseURL}nfts?identifiers=${allNFTsCommaSaperated}`;
        const collectionNFTs = await axios.get(collectionNFTsUrl);
        if (collectionNFTs == null || undefined) {
          throw Error('nfts info could not found');
        }
        nftsDetails.push(...collectionNFTs.data);
        allNFTsCommaSaperated = '';
      }
    }

    //Iterate through array
    for (let index = 0; index < nftsDetails.length; index++) {
      const nft = nftsDetails[index];
      const nftType = nft.url.split('.').pop();
      let imageurl;
      if (nft.collection === 'ZOIDSTERS-9121a9') {
        imageurl = nft.url;
      } else {
        imageurl =
          nftType.toLowerCase() != 'gif' && nftType.toLowerCase() != 'mp4'
            ? nft.media[0].thumbnailUrl
            : nft.url;
      }

      for (let i = 0; i < nftCollections.length; i++) {
        const collection = nftCollections[i];
        if (collection.collection_token_identifier == nft.collection) {
          const tierLoanInfo = tiersLoanInfo.find(
            (x: any) =>
              x.collection_tier.name.toString() ==
              collection.collection_tier.name.toString()
          );
          const collecitonObj: NFTCollection = {
            collectionTokenIdentifier: collection.collection_token_identifier,
            collectionName: collection.collection_name.toString(),
            floorPrice: collection.floor_price.toNumber() / 10 ** 18,
            active: collection.active,
            companyName: collection.company_name.toString(),
            collectionTier: collection.collection_tier.name.toString(),
            collection_max_loan_percentage:
              tierLoanInfo.collection_max_loan_rate_percentage.toNumber(),
            url: imageurl
          };

          if (
            tierCollectionInfolocal[collection.collection_tier.name] ===
            undefined
          ) {
            tierCollectionInfolocal[collection.collection_tier.name] = [
              collecitonObj
            ];
          } else {
            tierCollectionInfolocal[collection.collection_tier.name].push(
              collecitonObj
            );
          }

          break;
        }
      }
    }

    setTierCollectionInfo(tierCollectionInfolocal);
  }

  React.useEffect(() => {
    loadNFTCollections();
  }, []);

  // console.log(tierCollectionInfo);
  return (
    <div className='collections-page'>
      {tierCollectionInfo ? (
        <>
          <div className='row col-lg-10 col-md-11 col-12 margin-auto  m-b-15 m-t-40'>
            <div className='col-12 pawnNft-container '>
              <div className='nftListLabel text-center font-size-22'>
                Use your NFT as collateral to borrow cryptocurrency
              </div>
            </div>
          </div>
          <StepsModule activeStep={1}></StepsModule>
          <CollectionsTabs
            nftCollectionInfo={tierCollectionInfo}
          ></CollectionsTabs>
          <Col xs={12} md={11} lg={10} className='row margin-auto'>
            <div className='col-12 nftListLabel egld-text d-block m-p-o'>
              {/* <span className='orange-text float-left text-left'>
                **Floor Price is determined by the lowest current listing or
                sale in the past 7 days{' '}
                <a
                  target='_blank'
                  href='https://omniscient.tools/'
                  className='skyblue-text'
                  rel='noreferrer'
                >
                  <img className='omniscient-logo' src={omniscientLogo} />
                </a>
              </span> */}
              <span className='orange-text col-12 text-left float-left'>
                {' '}
                **All Values are in EGLD**
              </span>
            </div>
          </Col>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default Collections;
