import React from 'react';
import { useGetAccountInfo, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import { LoginMethodsEnum } from '@multiversx/sdk-dapp/types';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import converter from 'bech32-converting';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
import stamp from '../../src/assets/used.png';
import { maxNftSelection, stakingContractAddress } from '../config';

//Card component to show tiles
function NftCard(props: any) {
  const {
    stakedNFTInfoFromSC,
    action,
    loaderHandler,
    OnTransactionSent,
    tags,
    isSelected,
    count,
    selectedCount
  } = props;
  // console.log(props.nftInfo);
  const { identifier, media, collection, name } = props.nftInfo;
  const navigate = useNavigate();
  const collectionInHex = convertToHex(collection);
  const nftType = props.nftInfo.url.split('.').pop();
  const imageurl =
    nftType.toLowerCase() != 'gif' && nftType.toLowerCase() != 'mp4'
      ? media[0].thumbnailUrl
      : props.nftInfo.url;

  const { address } = useGetAccountInfo();
  const { loginMethod } = useGetLoginInfo();
  function convertToHex(str: string) {
    let hex = '';
    // console.log(str);
    for (let i = 0; i < str.length; i++) {
      hex += '' + str.charCodeAt(i).toString(16);
    }
    return hex;
  }

  const actionNFT = async () => {
    loaderHandler(true, false);
    let hexstakingContractAddressAddress = converter('erd').toHex(
      stakingContractAddress
    );
    hexstakingContractAddressAddress =
      hexstakingContractAddressAddress.substring(
        2,
        hexstakingContractAddressAddress.length
      );
    let stakingTransaction;
    const nonce = identifier.split('-')[2];
    let processingMessage = '';
    let successMessage = '';
    if (action === 'stake') {
      stakingTransaction = {
        value: '0',
        data: `ESDTNFTTransfer@${collectionInHex}@${nonce}@01@${hexstakingContractAddressAddress}@7374616b655f7061776e5f70617373`,
        receiver: address,
        gasLimit: 50000000
      };

      processingMessage = 'Staking Is In Process';
      successMessage = 'Staking Operation Successful';
    } else {
      stakingTransaction = {
        value: '0',
        data: `unstake_pawn_pass@${collectionInHex}@${nonce}`,
        receiver: stakingContractAddress,
        gasLimit: 50000000
      };

      processingMessage = 'Unstaking Is In Process';
      successMessage = 'Unstaking Operation Successful';
    }

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: stakingTransaction,
      transactionsDisplayInfo: {
        processingMessage: processingMessage,
        errorMessage: 'An error has occured during staking/unstaking process',
        successMessage: successMessage,
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      loaderHandler(false, false);
      // console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = {
        action: action
      };

      // var sessionTransactionObject[`${sessionId}`] = pawnNFTPlainObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        sessionStorage.setItem('SessionId', sessionId);
        OnTransactionSent();
      }
    }
  };
  const [isFlipped, setIsFlipped] = React.useState(false);
  const [cardSelected, setCardSelected] = React.useState(false);
  const pawnNow = () => {
    // alert('Pawn Now');
    //navigate(routeNames.pawnNFT, {
    //state: {
    isSelected(!cardSelected, identifier);
    // nftIdentifier: identifier;
    //}
    //});
  };
  // console.log(stakedNFTInfoFromSC);
  const cn =
    (cardSelected ? 'selected' : '') + (isFlipped ? ' is-flipped' : '');
  return (
    <>
      <Col key={identifier} xs={6} md={4} lg={3} className='scene scene--card'>
        {count > 1 ? <label className='nft-counter'>{count}</label> : ''}
        <Card border='info' className={cn}>
          <div className='NFT__Card card__face card__face--front note'>
            <div
              className='NFT__Card--Img_Container cur-pointer'
              onClick={() => (count > 0 ? '' : setIsFlipped(!isFlipped))}
            >
              <video
                poster={imageurl}
                autoPlay
                playsInline
                muted
                loop
                className={
                  stakedNFTInfoFromSC && stakedNFTInfoFromSC.pass_in_use
                    ? 'NFT__Card--Img nft-grayscale'
                    : 'NFT__Card--Img'
                }
              >
                <source src={imageurl} type='video/mp4' />
              </video>
              {stakedNFTInfoFromSC && stakedNFTInfoFromSC.pass_in_use ? (
                <img src={stamp} className='nft-stamp'></img>
              ) : (
                ''
              )}
            </div>
            <Card.Body className='NFT__CardBody'>
              <div className='NFT__CardBody-details'>
                {/* <Card.Title className='NFT__CardBody--Title'>{name}</Card.Title> */}
                <Card.Title className='NFT__CardBody--Title'>
                  {/* {identifier} */}
                  {name}
                </Card.Title>
              </div>
              {action === 'stake' ? (
                <button
                  className='NFT__CardBody--ButtonStack btn btn-primary'
                  onClick={() => actionNFT()}
                >
                  STAKE
                </button>
              ) : action === 'pawn' ? (
                <button
                  className={
                    (cardSelected ? 'selected' : '') +
                    ' NFT__CardBody--ButtonStack btn btn-primary'
                  }
                  onClick={() => {
                    pawnNow();
                    setCardSelected(!cardSelected);
                  }}
                  disabled={selectedCount >= maxNftSelection && !cardSelected}
                >
                  {cardSelected ? 'SELECTED' : 'SELECT'}
                </button>
              ) : action === 'noBtn' ? (
                ''
              ) : (
                <>
                  <button
                    disabled={stakedNFTInfoFromSC.pass_in_use}
                    className='NFT__CardBody--ButtonStack btn btn-primary'
                    onClick={() => actionNFT()}
                  >
                    UNSTAKE
                  </button>
                </>
              )}
            </Card.Body>
          </div>
          <div
            className='card__face card__face--back '
            onClick={() => setIsFlipped(!isFlipped)}
          >
            <div className='card__face--back-container note'>
              <Card.Title className='orange-text font-18 text-center'>
                {identifier}
              </Card.Title>
              <Card.Title className='orange-text font-18 text-center'>
                Tags:
              </Card.Title>
              {tags.length ? (
                <div className='nft-tags-container'>
                  {tags.map((tag: string, index: number) => (
                    <span className='nft-tags' key={index}>
                      {tag}
                    </span>
                  ))}
                </div>
              ) : (
                <Card.Title className='text-white'>
                  No Tags Available
                </Card.Title>
              )}
            </div>
          </div>
        </Card>
        {count > 1 ? (
          <Card border='info' className={cn + ' card-clone '}>
            <div className='NFT__Card card__face card__face--front note'>
              <div
                className='NFT__Card--Img_Container cur-pointer'
                onClick={() => setIsFlipped(!isFlipped)}
              >
                <video
                  poster={imageurl}
                  autoPlay
                  playsInline
                  muted
                  loop
                  className={
                    stakedNFTInfoFromSC && stakedNFTInfoFromSC.pass_in_use
                      ? 'NFT__Card--Img nft-grayscale'
                      : 'NFT__Card--Img'
                  }
                >
                  <source src={imageurl} type='video/mp4' />
                </video>
              </div>
              <Card.Body className='NFT__CardBody'>
                <div className='NFT__CardBody-details'>
                  {/* <Card.Title className='NFT__CardBody--Title'>{name}</Card.Title> */}
                  <Card.Title className='NFT__CardBody--Title'>
                    {/* {identifier} */}
                    &nbsp;
                  </Card.Title>
                </div>
              </Card.Body>
            </div>
          </Card>
        ) : (
          ''
        )}
      </Col>
    </>
  );
}
NftCard.defaultProps = {
  tags: [],
  collectionInfo: {},
  isSelected: () => ({}),
  count: 0,
  selectedCount: 0
};
export default NftCard;
