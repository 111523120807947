import React, { useState, useEffect, useCallback } from 'react';
import { Col } from 'react-bootstrap';
import { useTable, usePagination, useSortBy } from 'react-table';
import ReactTooltip from 'react-tooltip';
import CommonHelper from 'Helper/common-helper';

export declare class ApiData {
  nftCommonName: string;
  tokenIdentifier: string;
  nonce: string | number;
  nftCounter: string | number;
  loanAmount: string | number;
  pawnDate: string | number;
  actualExpiryDate: string | number;
  extendedExpiryDate: string | number;
  pawnFee: string | number;
  url: string;
  loanDurationTicks: string | number;
  tags: Array<any>;
  loanBoostAmount: string | number;
  holder: string;
  boostLevel: string;
  boosterNfts: Array<any>;
  feeDiscount: string | number;
}
[];

export declare class TableData {
  nftCommonName: string;
  url: string;
  identifier: string;
  loanAmount: string;
  pawnFee: string;
  extendedExpiryDate: string;
  holder: string;
  nftCounter: string;
}
[];

function Table({
  columns,
  data,
  fetchData,
  onSort,
  onSearch,
  initialData,
  loading,
  dataCount,
  pageCount: controlledPageCount,
  claimBtn
}: {
  columns: any;
  data: TableData[];
  fetchData: (tableArg: customTableArg) => void;
  onSort: (tableArg: customTableArg) => void;
  onSearch: (tableArg: customTableArg) => void;
  initialData: ApiData[];
  loading: boolean;
  pageCount: number;
  dataCount: number;
  claimBtn: any;
}): JSX.Element {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: true,
      autoResetSortBy: true,
      pageCount: controlledPageCount
    },
    useSortBy,
    usePagination
  );
  const [search, setSearch] = React.useState('');
  const [actualData, setActualData] = React.useState<TableData[]>(data);
  const [pawnHistory, setPawnHistoryData] = React.useState<ApiData[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<Array<string>>([]);

  useEffect(() => {
    // console.log(initialData);
    setPawnHistoryData(initialData);
  }, []);

  useEffect(() => {
    const array: TableData[] = [];
    for (let i = 0; i < pawnHistory.length; i++) {
      const dataObj: TableData = {
        nftCommonName: '',
        identifier: '',
        extendedExpiryDate: '',
        pawnFee: '',
        holder: '',
        url: '',
        loanAmount: '',
        nftCounter: ''
      };
      let hexNFTNonce = pawnHistory[i].nonce.toString(16);
      //console.log('before : '+ hexNFTIndex);
      if (hexNFTNonce.length % 2 == 1) {
        hexNFTNonce = '0' + hexNFTNonce;
      }
      dataObj.identifier = `${pawnHistory[i].tokenIdentifier}-${hexNFTNonce}`;
      dataObj.nftCommonName = pawnHistory[i].nftCommonName;
      dataObj.loanAmount = `${CommonHelper.fixedNum(
        parseFloat(pawnHistory[i].loanAmount.toString()) / 10 ** 18,
        4
      )}`;
      dataObj.extendedExpiryDate = CommonHelper.getDate(
        (
          parseInt(pawnHistory[i].extendedExpiryDate.toString()) * 1000
        ).toString()
      );
      dataObj.pawnFee = `${CommonHelper.fixedNum(
        parseFloat(pawnHistory[i].pawnFee.toString()) / 10 ** 18,
        6
      )}`;
      dataObj.url = pawnHistory[i].url;
      dataObj.holder = pawnHistory[i].holder;
      dataObj.nftCounter = pawnHistory[i].nftCounter.toString();
      array[i] = dataObj;
    }
    // console.log(array);
    setActualData(array);
  }, [pawnHistory]);
  useEffect(() => {
    onSort({ actualData, sortBy, pageIndex, pageSize, search });
  }, [search, onSort, sortBy, fetchData, pageIndex, pageSize]);
  useEffect(() => {
    fetchData({ actualData, sortBy, pageIndex, pageSize, search });
  }, [actualData]);

  useEffect(() => {
    // console.log(data.length , pageIndex*pageSize)
    if (!data.length) {
      gotoPage(0);
    }
  }, [data]);

  const handleSearch = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const searchVal = event.target.value;
    onSearch({ actualData, sortBy, pageIndex, pageSize, search });
    setSearch(searchVal);
  };
  const selectAllRows = (row: Array<any>, e: any) => {
    const arr = [];
    const checkBox: any = document.getElementsByName('chk');
    if (e.target.checked) {
      for (let i = 0; i < row.length; i++) {
        arr.push(`${row[i].cells[2].value}-${row[i].cells[7].value}`);
      }
      for (let i = 0; i < checkBox.length; i++) {
        if (checkBox[i].type == 'checkbox') checkBox[i].checked = true;
      }
    } else {
      for (let i = 0; i < checkBox.length; i++) {
        if (checkBox[i].type == 'checkbox') checkBox[i].checked = false;
      }
    }
    setSelectedRows(arr);
  };
  const selectRow = (row: string, nftCounter: string, e: any) => {
    const arr = [...selectedRows];
    const checkBox: any = document.getElementsByName('chk');
    const checkBoxAll: any = document.getElementsByName('chkAll');
    if (e.target.checked) {
      arr.push(`${row}-${nftCounter}`);
    } else {
      const index = arr.indexOf(`${row}-${nftCounter}`);
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    for (let i = 0; i < checkBox.length; i++) {
      if (checkBox[i].type == 'checkbox' && checkBox[i].checked) {
        checkBoxAll[0].checked = true;
      } else {
        checkBoxAll[0].checked = false;
        break;
      }
    }
    setSelectedRows(arr);
  };
  const claim = () => {
    const arr = [];

    if (claimBtn) {
      for (let i = 0; i < selectedRows.length; i++) {
        const obj = {
          tokenIdentifier: '',
          nonce: '',
          nftCounter: ''
        };
        const nftDetails = selectedRows[i].split('-');
        obj.tokenIdentifier = `${nftDetails[0]}-${nftDetails[1]}`;
        obj.nonce = nftDetails[2];
        obj.nftCounter = nftDetails[3];
        arr.push(obj);
      }
      claimBtn(arr);
    }
  };

  return (
    <>
      {pawnHistory.length ? (
        <>
          <div className='nftListLabel text-center'>Expired Loans</div>
          <Col xs={12}>
            <div className='row'>
              <div className='table-dropdown-container col-12 col-sm-6 text-right'>
                <label className='text-white h6'>Rows per page:</label>
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 15, 20].map((pageSizeOptions) => (
                    <option key={pageSizeOptions} value={pageSizeOptions}>
                      {pageSizeOptions}
                    </option>
                  ))}
                </select>
              </div>
              <div className='table-search-container col-12 col-sm-6'>
                {/* <input class="form-control border-end-0 border rounded-pill" /> */}
                <input
                  id='search'
                  className='form-control border-end-0 col-12 col-sm-6'
                  placeholder='Search in Table'
                  type='search'
                  onChange={handleSearch}
                />
              </div>
              <div className='col-12'>
                <table
                  className='tb-transaction tb-expiredNfts table table-responsive table-striped text-white'
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map((headerGroup, i) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={'th-tr' + i}
                      >
                        <th>
                          <input
                            name='chkAll'
                            type='checkbox'
                            onChange={(e) => selectAllRows(page, e)}
                          />
                        </th>
                        {headerGroup.headers.map((column, index) => (
                          // we can add them into the header props
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={'th-th' + index}
                          >
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' ▼'
                                  : ' ▲'
                                : ''}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={'tb-tr' + index}>
                          <td>
                            <input
                              name='chk'
                              type='checkbox'
                              onChange={(e) =>
                                selectRow(
                                  row.cells[2].value,
                                  row.cells[7].value,
                                  e
                                )
                              }
                            />
                          </td>
                          {row.cells.map((cell, i) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                key={index + 'tb-td' + i}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    {loading ? (
                      <tr>
                        {/* // Use our custom loading state to show a loading indicator */}
                        <td colSpan={10000}>Loading...</td>
                      </tr>
                    ) : (
                      <tr></tr>
                      // <tr>
                      //   <td colSpan={10000} className='text-center'>
                      //     Showing {pageSize * pageIndex + 1} to{' '}
                      //     {page.length == pageSize
                      //       ? pageSize * (pageIndex + 1)
                      //       : pageSize * pageIndex + page.length}{' '}
                      //     of {dataCount} results
                      //   </td>
                      // </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='text-white text-center col-12'>
                Showing {pageSize * pageIndex + 1} to{' '}
                {page.length == pageSize
                  ? pageSize * (pageIndex + 1)
                  : pageSize * pageIndex + page.length}{' '}
                of {dataCount} results
              </div>
            </div>
          </Col>
          <Col className='w-100'>
            <div className='pagination row'>
              <div className='btn-group col-6 col-sm-4'>
                <button
                  className='btn btn-light'
                  onClick={() => gotoPage(0)}
                  disabled={pageIndex + 1 <= 1}
                >
                  {'<< First'}
                </button>
                <button
                  className='btn btn-light'
                  onClick={() => previousPage()}
                  disabled={pageIndex + 1 <= 1}
                >
                  {'< Prev'}
                </button>
              </div>
              <div className='page-counter text-center col-12 col-sm-4'>
                <span className='text-white'>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
              </div>
              <div className='btn-group col-6 col-sm-4'>
                <button
                  className='btn btn-light'
                  onClick={() => nextPage()}
                  disabled={pageIndex + 1 >= pageOptions.length}
                >
                  {'Next >'}
                </button>
                <button
                  className='btn btn-light'
                  onClick={() => gotoPage(pageOptions.length - 1)}
                  disabled={pageIndex + 1 >= pageOptions.length}
                >
                  {'Last >>'}
                </button>
              </div>
            </div>
          </Col>
          <button
            className='NFT__CardBody--ButtonStack btn btn-primary pawn-btn col-12'
            disabled={!selectedRows.length}
            onClick={() => {
              claim();
            }}
          >
            CLAIM
          </button>
        </>
      ) : (
        <div className='nftListLabel'>No Expired Loan to Claim</div>
      )}
    </>
  );
}

const ExpiredNFTsList = (props: any) => {
  const [initialData, setInitialData] = useState<ApiData[]>([]);
  const [filterdData, setFilterdData] = useState<TableData[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [dataCount, setDataCount] = React.useState(0);

  const fetchIdRef = React.useRef(0);
  const sortIdRef = React.useRef(0);
  const columns: {
    Header: string;
    accessor: string;
  }[] = React.useMemo(
    () => [
      {
        Header: 'NFT',
        accessor: 'url',
        Cell: ({ cell: { value } }: any) => (
          <video
            poster={value}
            autoPlay
            playsInline
            muted
            loop
            className='NFT__Card--Img'
          >
            <source src={value} type='video/mp4' />
          </video>
        ),
        disableSortBy: true
      },
      {
        Header: 'Name',
        accessor: 'nftCommonName',
        disableSortBy: true
      },
      {
        Header: 'Identifier',
        accessor: 'identifier',
        disableSortBy: true
      },
      {
        Header: 'Loan Amount (EGLD)',
        accessor: 'loanAmount',
        disableSortBy: true
      },
      {
        Header: 'Pawn Fee (EGLD)',
        accessor: 'pawnFee',
        disableSortBy: true
      },
      {
        Header: 'Expired Date',
        accessor: 'extendedExpiryDate',
        disableSortBy: true
      },
      {
        Header: 'User Address',
        accessor: 'holder',
        disableSortBy: true,
        Cell: ({ cell: { value } }: any) => (
          <div className='userAdd-container'>
            <span className='userAdd' data-tip={value}>
              {value.substring(0, 5) +
                '...' +
                value.substring(value.length - 5, value.length)}
            </span>{' '}
            <ReactTooltip />
            <svg
              onClick={() => copyToClipboard(value, event)}
              viewBox='0 0 64 64'
              className='copyImg'
            >
              <g id='Text-files' className='copyIco' fill='currentColor'>
                <path
                  d='M53.9791489,9.1429005H50.010849c-0.0826988,0-0.1562004,0.0283995-0.2331009,0.0469999V5.0228
		C49.7777481,2.253,47.4731483,0,44.6398468,0h-34.422596C7.3839517,0,5.0793519,2.253,5.0793519,5.0228v46.8432999
		c0,2.7697983,2.3045998,5.0228004,5.1378999,5.0228004h6.0367002v2.2678986C16.253952,61.8274002,18.4702511,64,21.1954517,64
		h32.783699c2.7252007,0,4.9414978-2.1725998,4.9414978-4.8432007V13.9861002
		C58.9206467,11.3155003,56.7043495,9.1429005,53.9791489,9.1429005z M7.1110516,51.8661003V5.0228
		c0-1.6487999,1.3938999-2.9909999,3.1062002-2.9909999h34.422596c1.7123032,0,3.1062012,1.3422,3.1062012,2.9909999v46.8432999
		c0,1.6487999-1.393898,2.9911003-3.1062012,2.9911003h-34.422596C8.5049515,54.8572006,7.1110516,53.5149002,7.1110516,51.8661003z
		 M56.8888474,59.1567993c0,1.550602-1.3055,2.8115005-2.9096985,2.8115005h-32.783699
		c-1.6042004,0-2.9097996-1.2608986-2.9097996-2.8115005v-2.2678986h26.3541946
		c2.8333015,0,5.1379013-2.2530022,5.1379013-5.0228004V11.1275997c0.0769005,0.0186005,0.1504021,0.0469999,0.2331009,0.0469999
		h3.9682999c1.6041985,0,2.9096985,1.2609005,2.9096985,2.8115005V59.1567993z'
                />
                <path
                  d='M38.6031494,13.2063999H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0158005
		c0,0.5615997,0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4542999,1.0158997-1.0158997
		C39.6190491,13.6606998,39.16465,13.2063999,38.6031494,13.2063999z'
                />
                <path
                  d='M38.6031494,21.3334007H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0157986
		c0,0.5615005,0.4544001,1.0159016,1.0159006,1.0159016h22.3491974c0.5615005,0,1.0158997-0.454401,1.0158997-1.0159016
		C39.6190491,21.7877007,39.16465,21.3334007,38.6031494,21.3334007z'
                />
                <path
                  d='M38.6031494,29.4603004H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
		s0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4543991,1.0158997-1.0158997
		S39.16465,29.4603004,38.6031494,29.4603004z'
                />
                <path
                  d='M28.4444485,37.5872993H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
		s0.4544001,1.0158997,1.0159006,1.0158997h12.1904964c0.5615025,0,1.0158005-0.4543991,1.0158005-1.0158997
		S29.0059509,37.5872993,28.4444485,37.5872993z'
                />
              </g>
              <g
                fill='none'
                stroke='#22AE73'
                className='success-ico'
                strokeWidth='2'
              >
                <circle
                  cx='77'
                  cy='77'
                  r='72'
                  style={{
                    strokeDasharray: '480px, 480px',
                    strokeDashoffset: '960px'
                  }}
                ></circle>
                <circle
                  id='colored'
                  fill='#22AE73'
                  cx='77'
                  cy='77'
                  r='72'
                  style={{
                    strokeDasharray: '480px, 480px',
                    strokeDashoffset: '960px'
                  }}
                ></circle>
                <polyline
                  className='st0'
                  stroke='#fff'
                  strokeWidth='10'
                  points='43.5,77.8 63.7,97.9 112.2,49.4 '
                  style={{
                    strokeDasharray: '100px, 100px',
                    strokeDashoffset: '200px'
                  }}
                />
              </g>
            </svg>
          </div>
        )
      },
      {
        Header: 'NFT Counter',
        accessor: 'nftCounter',
        show: false,
        disableSortBy: true
      }
    ],
    []
  );
  function copyToClipboard(text: string, e: any) {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    const target = e.target.closest('.copyImg');
    target.classList.add('success');
    setTimeout(function () {
      target.classList.remove('success');
    }, 1800);
  }
  const fetchData = React.useCallback((tableArg: customTableArg) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = tableArg.pageSize * tableArg.pageIndex;
        const endRow = startRow + tableArg.pageSize;
        setDataCount(tableArg.actualData.length);
        setFilterdData(tableArg.actualData.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(tableArg.actualData.length / tableArg.pageSize));

        setLoading(false);
      }
    }, 1000);
  }, []);
  const searchResult = (
    actualData: TableData[],
    search: string,
    pageSize: number
  ) => {
    const newArray = actualData.filter(function (el: TableData) {
      for (let i = 0; i < Object.values(el).length; i++) {
        if (
          Object.values(el)
            [i].toString()
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return 1;
        }
      }
    });
    setPageCount(Math.ceil(newArray.length / pageSize));
    return newArray;
  };
  const handleSort = useCallback((tableArg: customTableArg) => {
    // Give this sort an ID
    const sortId = ++sortIdRef.current;
    // Set the loading state
    setLoading(true);
    // Doing multisort
    const newArray: TableData[] = searchResult(
      tableArg.actualData,
      tableArg.search,
      tableArg.pageSize
    );
    if (sortId === sortIdRef.current) {
      const sorted = newArray.slice();
      sorted.sort((a: any, b: any) => {
        for (let i = 0; i < tableArg.sortBy.length; ++i) {
          if (tableArg.sortBy[i].id == 'extended_expiry_date') {
            const time1 = a[tableArg.sortBy[i].id].split(' ');
            const time2 = b[tableArg.sortBy[i].id].split(' ');
            const part1 = time1[0].split('-');
            const part2 = time2[0].split('-');
            const d1 = `${part1[2]}-${part1[1]}-${part1[0]} ${time1[1]}`;
            const d2 = `${part2[2]}-${part2[1]}-${part2[0]} ${time2[1]}`;
            const sortVal = Date.parse(d1) > Date.parse(d2) ? 1 : -1;
            return tableArg.sortBy[i].desc ? -1 * sortVal : sortVal;
          } else if (tableArg.sortBy[i].id == 'loan_duration_ticks') {
            const part1 = a[tableArg.sortBy[i].id].split(' ');
            const part2 = b[tableArg.sortBy[i].id].split(' ');
            const t1 =
              part1[1] == 'Days'
                ? '3Days' + part1[0]
                : part1[1] == 'Hours'
                ? '2Hours' + part1[0]
                : '1Minute' + part1[0];
            const t2 =
              part2[1] == 'Days'
                ? '3Days' + part2[0]
                : part2[1] == 'Hours'
                ? '2Hours' + part2[0]
                : '1Minute' + part2[0];
            // console.log(t1, t2);
            const sortVal = t1.localeCompare(t2, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
            return tableArg.sortBy[i].desc ? -1 * sortVal : sortVal;
          } else {
            const sortVal = a[tableArg.sortBy[i].id]
              .toString()
              .localeCompare(b[tableArg.sortBy[i].id].toString(), undefined, {
                numeric: true,
                sensitivity: 'base'
              });
            return tableArg.sortBy[i].desc ? -1 * sortVal : sortVal;
          }
          // if (a[sortBy[i].id] > b[sortBy[i].id])
          //   return sortBy[i].desc ? -1 : 1;
          // if (a[sortBy[i].id] < b[sortBy[i].id])
          //   return sortBy[i].desc ? 1 : -1;
        }
        return 0;
      });
      const startRow = tableArg.pageSize * tableArg.pageIndex;
      const endRow = startRow + tableArg.pageSize;
      setDataCount(sorted.length);
      setFilterdData(sorted.slice(startRow, endRow));
      setLoading(false);
    }
  }, []);
  const handleSearch = useCallback((tableArg: customTableArg) => {
    // const newArray = actualData.filter(function (el: TableData) {
    //   for (let i = 0; i < Object.values(el).length; i++) {
    //     if (
    //       Object.values(el)[i].toLowerCase().includes(search.toLowerCase())
    //     ) {
    //       return 1;
    //     }
    //   }
    // });
    const newArray: TableData[] = searchResult(
      tableArg.actualData,
      tableArg.search,
      tableArg.pageSize
    );
    const startRow = tableArg.pageSize * tableArg.pageIndex;
    const endRow = startRow + tableArg.pageSize;
    setDataCount(newArray.length);
    setFilterdData(newArray.slice(startRow, endRow));
  }, []);
  // console.log(initialData);

  React.useEffect(() => {
    setInitialData(props.data);
  }, [props.data]);
  // console.log(props.data);

  return (
    <>
      {initialData.length ? (
        <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container'>
          <Table
            columns={columns}
            data={filterdData}
            onSort={handleSort}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            onSearch={handleSearch}
            dataCount={dataCount}
            initialData={initialData}
            claimBtn={props.claimBtn}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default ExpiredNFTsList;

export declare class customTableArg {
  actualData: TableData[];
  sortBy: any[] | [];
  pageIndex: number;
  pageSize: number;
  search: string | '';
}
