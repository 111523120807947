import {
  Address,
  AddressValue,
  SmartContract,
  TokenIdentifierValue,
  Transaction
} from '@multiversx/sdk-core';

import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

import SmartContractService from './smart-contract-service';

export default class StakingSmartContract {
  private static contract: SmartContract;
  static async initializeStakingSmartContract(
    contractAddress: string,
    abiJson: any
  ) {
    if (StakingSmartContract.contract == null || undefined) {
      StakingSmartContract.contract = await SmartContractService.createContract(
        contractAddress,
        abiJson,
        ['StakingSmartContract']
      );
    }
  }

  static async getAllCollectionTokens(proxy: ProxyNetworkProvider) {
    if (StakingSmartContract.contract == null || undefined) {
      return Error('Mint NFT contract not initialized.');
    }

    const smartContract = StakingSmartContract.contract;
    const interaction = smartContract.methods.getAllCollectionTokens();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getStakedPassesForUser(
    walletAddress: string,
    collection_ticker: string,
    proxy: ProxyNetworkProvider
  ) {
    if (StakingSmartContract.contract == null || undefined) {
      return Error('Mint NFT contract not initialized.');
    }

    const smartContract = StakingSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getStakedPassesForAddress(
      [
        new AddressValue(new Address(walletAddress)),
        new TokenIdentifierValue(collection_ticker)
      ]
    );

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      //console.log('error occured quering the smart contract');
      return null;
    }
  }

  static async getNFTsCollection(proxy: ProxyNetworkProvider) {
    if (StakingSmartContract.contract == null || undefined) {
      return Error('Bonez NFT contract not initialized.');
    }

    const smartContract = StakingSmartContract.contract;
    const interaction = smartContract.methods.getNFTsCollection();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.log('error occured while fetching query.');
      return null;
    }
  }

  static async sendTransaction(tx: Transaction, proxy: ProxyNetworkProvider) {
    // const smartContract = StakingSmartContract.contract;
    // const interaction = smartContract.methods.buy([]);
    // interaction.
    // const smartContract = PittzSmartContract.contract;
    return await SmartContractService.sendTransaction(tx, proxy);
  }
}
