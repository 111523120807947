import * as React from 'react';
// import ConfirmModal from 'components/ConfirmModal';
// import { useNavigate } from 'react-router';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
// import axios from 'axios';
// import { baseURL } from 'config';
import CommonHelper from 'Helper/common-helper';
import { routeNames } from 'routes';
import PawnedNftCard from './pawnedNftCard';
import { PawnNFT } from './types/PawnLoanType';
import { maxNftSelection } from 'config';
import { useNavigate } from 'react-router-dom';
// import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';

const Pawned = () => {
  sessionStorage.setItem('currentRoute', 'pawned');
  const { address } = useGetAccountInfo();
  // const address =
  //   'erd1vmwx0uuy3h8uvsg7cmf4f34mg20ut5ys0jtq44nymmsywlp32kpskq5cxl';
  const isLoggedIn = Boolean(address);
  if (!isLoggedIn) {
    //navigate(routeNames.unlock);
    window.location.href = routeNames.unlock;
  }
  const { network } = useGetNetworkConfig();
  const [pawnedNFTs, setPawnedNFTs] = React.useState<PawnNFT[]>([]);
  const [selectedtab, setSelectedtab] = React.useState<string>('one');
  const [identifierArr, setIdentifierArr] = React.useState<string[]>([]);

  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  async function loadPawnedNFTsForUser() {
    const pawnedNFTsLocal = await CommonHelper.loadPawnedNFTsForUser(
      proxy,
      address
    );

    setPawnedNFTs(pawnedNFTsLocal);
  }
  const tabChanged = (activeTab: string) => {
    setSelectedtab(activeTab);
  };
  React.useEffect(() => {
    loadPawnedNFTsForUser();
  }, []);

  const navigate = useNavigate();
  const claimNow = () => {
    console.log(identifierArr);
    if (identifierArr.length > maxNftSelection) {
      return;
    } else {
      navigate(routeNames.claimNFT, {
        state: {
          selectedNftIdentifiers: identifierArr
        }
      });
    }
  };
  const cardInteraction = (selected: boolean, nftIdentifierStr: string) => {
    let arr = identifierArr;
    if (selected) {
      arr.push(nftIdentifierStr);
    } else {
      arr = arr.filter((e) => e !== nftIdentifierStr);
    }
    setIdentifierArr([...arr]);
  };

  return (
    <div className='pawned-page'>
      <div className='row col-lg-10 col-md-11 col-12 margin-auto nft-with-cta m-t-40'>
        {identifierArr.length ? (
          <div className='loan-btn-container'>
            <button
              className='NFT__CardBody--ButtonStack btn btn-primary pawn-btn col-12'
              onClick={() => claimNow()}
              disabled={identifierArr.length > maxNftSelection}
            >
              PAY LOAN for {identifierArr.length} NFT(s)
            </button>
            {/* {identifierArr.length >= maxNftSelection ? (
              <label className='orange-text'>
                {' '}
                Maximum {maxNftSelection} allowed message here{' '}
              </label>
            ) : (
              ''
            )} */}
          </div>
        ) : (
          ''
        )}
        <article className='tabs'>
          <div className='tab-labels row col-12 m-p-0'>
            <input
              id='one'
              name='tabs'
              type='radio'
              checked={selectedtab === 'one'}
              onChange={() => tabChanged('one')}
            />
            <label className='col-6' htmlFor='one'>
              Active loans
            </label>
            <input
              id='two'
              name='tabs'
              type='radio'
              value='Two'
              checked={selectedtab === 'two'}
              onChange={() => tabChanged('two')}
            />
            <label className='col-6' htmlFor='two'>
              Expired Loans
            </label>
            <div className='panels'>
              <div className='panel'>
                <div className='nft_container col-12'>
                  {pawnedNFTs.length ? (
                    pawnedNFTs.map((pawnedNFT: any) => {
                      return new Date().getTime() -
                        pawnedNFT.extendedExpiryDate * 1000 <
                        -2 ? (
                        <PawnedNftCard
                          pawnedNFT={pawnedNFT}
                          key={pawnedNFT.nftCounter}
                          action='active'
                          selectedCount={identifierArr.length}
                          isSelected={(
                            selected: boolean,
                            nftIdentifierStr: string
                          ) => cardInteraction(selected, nftIdentifierStr)}
                        ></PawnedNftCard>
                      ) : (
                        ''
                      );
                    })
                  ) : (
                    <div className='nftListLabel text-center'>
                      No Pawned NFT Available
                    </div>
                  )}
                </div>
              </div>
              <div className='panel'>
                <div className='nft_container col-12'>
                  {pawnedNFTs.length ? (
                    pawnedNFTs.map((pawnedNFT: any) => {
                      return new Date().getTime() -
                        pawnedNFT.extendedExpiryDate * 1000 >
                        -2 ? (
                        <PawnedNftCard
                          pawnedNFT={pawnedNFT}
                          key={pawnedNFT.nftCounter}
                          action='expired'
                        ></PawnedNftCard>
                      ) : (
                        ''
                      );
                    })
                  ) : (
                    <div className='nftListLabel text-center'>
                      No Pawned NFT Available
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};
export default Pawned;
