import * as React from 'react';
// import ConfirmModal from 'components/ConfirmModal';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { Col } from 'react-bootstrap';
// import { useNavigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import CommonHelper from 'Helper/common-helper';
import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';
import { routeNames } from 'routes';
import { ComapnyLoanIssuedInfo, PawnShopStats } from './types/PawnLoanType';

const AdminStats = () => {
  sessionStorage.setItem('currentRoute', 'AdminStats');
  const navigate = useNavigate();
  const { address } = useGetAccountInfo();
  // const isLoggedIn = Boolean(address);
  const { network } = useGetNetworkConfig();
  const [permissionAllowed, setPermissionAllowed] =
    React.useState<boolean>(false);
  const [pawnShopStats, setPawnShopStats] = React.useState<PawnShopStats>();
  const [loanIssuedToCompaniesInfo, setLoanIssuedToCompaniesInfo] =
    React.useState<ComapnyLoanIssuedInfo[]>([]);
  const [showAdminStats, setShowAdminStats] = React.useState<boolean>(true);
  const [showLoanCompany, setShowLoanCompany] = React.useState<boolean>(true);

  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });

  async function loadOperatorAddresses() {
    const operatorAddresses = await PawnShopSmartContract.getOperatorAddresses(
      proxy
    );
    // console.log(operatorAddresses);
    if (address) {
      const addressFound = operatorAddresses.find(
        (x: any) => x.bech32() == address
      );
      if (!addressFound) {
        navigate(routeNames.home, {
          state: {}
        });
      } else {
        setPermissionAllowed(true);
      }
    } else {
      navigate(routeNames.home, {
        state: {}
      });
    }
  }

  async function loadSystemStats() {
    const stats = await CommonHelper.loadSystemStats(proxy);
    setPawnShopStats(stats);
    const loanIssuedToCompaniesInfoLocal =
      await CommonHelper.getAllLoanIssuedToCompanies(proxy);
    setLoanIssuedToCompaniesInfo(loanIssuedToCompaniesInfoLocal);
  }

  React.useEffect(() => {
    loadOperatorAddresses();
  }, []);

  React.useEffect(() => {
    if (permissionAllowed) {
      loadSystemStats();
      setInterval(function () {
        loadSystemStats();
      }, 60000);
    }
  }, [permissionAllowed]);
  console.log(loanIssuedToCompaniesInfo);
  return (
    <>
      <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container pb-24'>
        {pawnShopStats ? (
          <Col xs={12} className='row margin-auto'>
            <div className='nftListLabel m-p-0'>
              <h2
                className='row cur-pointer m-p-0'
                onClick={() => setShowAdminStats(!showAdminStats)}
              >
                Admin Statistics
                <i
                  className={showAdminStats ? 'chevron' : 'chevron rotate'}
                ></i>
              </h2>
            </div>
            {showAdminStats ? (
              <>
                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Pool Amount:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.totalPoolAmount / 10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Borrowed Amount:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.totalBorrowedAmount / 10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Current Borrowed Amount:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.currentBorrowedAmount / 10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Loan Repaid:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.totalLoanRepaid / 10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Fee Collected:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.totalFeeCollected / 10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Active Loans:</label>
                    <label>{pawnShopStats.totalActiveLoans}</label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Number of Good Loans:</label>
                    <label>{pawnShopStats.totalNumberOfGoodLoans}</label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Number of Bad Loans:</label>
                    <label>{pawnShopStats.totalNumberOfBadLoans}</label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Defaulted Fee:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.totalDefaultedFee / 10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Recovered Defaulted Loan:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.recoveredDefaultedLoan / 10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Recovered Defaulted Fee:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.recoveredDefaultedFee / 10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Defaulted Loan:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.totalDefaultedLoan / 10 ** 6,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>To Be Recovered:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.totalLoanToRecover / 10 ** 6,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>SC EGLD Profit:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.totalEGLDProfit / 10 ** 6,
                        6
                      )}
                    </label>
                  </h4>
                </div>

                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>User added Amount In Pool:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.userAddedPoolAmount / 10 ** 6,
                        6
                      )}
                    </label>
                  </h4>
                </div>
                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Passes In Use:</label>
                    <label>{pawnShopStats.totalUsedStakedPasses}</label>
                  </h4>
                </div>
                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Withdrawn Amount:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        pawnShopStats.totalAmountWithdrawn / 10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>
                <div className='nft-details col-5 col-md-3'>
                  <h4 className='text-white'>
                    <label>Total Balance In SC:</label>
                    <label>
                      {CommonHelper.fixedNum(
                        (pawnShopStats.totalPoolAmount -
                          pawnShopStats.currentBorrowedAmount -
                          pawnShopStats.totalLoanToRecover) /
                          10 ** 18,
                        6
                      )}
                    </label>
                  </h4>
                </div>
                <br />
              </>
            ) : (
              ''
            )}
          </Col>
        ) : (
          <div className='nftListLabel'>Loading Stats. . . </div>
        )}
      </div>

      <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container pb-24'>
        <Col xs={12} className='row margin-auto'>
          <div className='nftListLabel m-p-0'>
            <h2
              className='row cur-pointer m-p-0'
              onClick={() => setShowLoanCompany(!showLoanCompany)}
            >
              Loan Issued To Companies(in EGLDs)
              <i className={showLoanCompany ? 'chevron' : 'chevron rotate'}></i>
            </h2>
          </div>
          {showLoanCompany &&
            loanIssuedToCompaniesInfo.map(
              (item: ComapnyLoanIssuedInfo, index: number) => (
                <div className='nft-details col-5 col-md-3' key={index}>
                  <h4 className='text-white'>
                    <label>{item.companyName}</label>
                    <label>
                      {CommonHelper.fixedNum(item.loanIssued / 10 ** 18, 6)}
                    </label>
                  </h4>
                </div>
              )
            )}
        </Col>
      </div>
    </>
  );
};

export default AdminStats;

//
//
//
//
//
//
//
//
// Total Defaulted Loan = total_borrowed_amount - (total_loan_repaid + current_borrowed_amount)
// To Be Recovered = Total_Defaulted_Loan - Recovered_defaulted_loan
// SC EGLD Profit = (Recovered_defaulted_fee + total_fee_collected) - TO_Be_Recovered;
// sted Amount In Pool = Total_pool_amount - (total_fee_collected + Recovered_defaulted_fee)
