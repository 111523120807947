import * as React from 'react';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import {
  useGetAccountInfo,
  useGetLoginInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { LoginMethodsEnum } from '@multiversx/sdk-dapp/types';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from 'components/ConfirmModal';
import { CollectionStatsSelectFields, XOXNOClient } from '@xoxno/sdk-js';
import { CollectionModule } from '@xoxno/sdk-js';

import {
  explorer_url,
  pawnShopContractAddress,
  pawnShopOwnerAddress
} from 'config';
import CommonHelper from 'Helper/common-helper';
import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';
import { routeNames } from 'routes';
import loader from '../assets/loader.gif';
import TrackTransaction from './TrackTransaction';
import { NFTmessage } from './types/NFTMessage';
import {
  BoostInfo,
  NFTCollectionAdmin,
  NFTCompany,
  PawnFeeInfoAdmin,
  PawnShopTradingInfo,
  TierLoanInfo
} from './types/PawnLoanType';

const AdminSettings = () => {
  XOXNOClient.init();
  sessionStorage.setItem('currentRoute', 'AdminSettings');
  const { address } = useGetAccountInfo();
  // const isLoggedIn = Boolean(address);
  const navigate = useNavigate();
  const { network } = useGetNetworkConfig();

  const { loginMethod } = useGetLoginInfo();
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  const [permissionAllowed, setPermissionAllowed] =
    React.useState<boolean>(false);
  const [transactionPending, setTransactionPending] =
    React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [msgObj, setMsgObj] = React.useState<NFTmessage>();
  const [pawnShopTradingInfo, setPawnShopTradingInfo] = React.useState<{
    [collectionIdentifier: string]: PawnShopTradingInfo;
  }>();
  const [tradingInfoAvailable, setTradingInfoAvailable] =
    React.useState<boolean>(false);
  const [IsOwnerWallet, setIsOwnerWallet] = React.useState<boolean>(false);
  const [sessionStateId, setSessionId] = React.useState<string>('');

  async function loadOperatorAddresses() {
    const operatorAddresses = await PawnShopSmartContract.getOperatorAddresses(
      proxy
    );
    // console.log(operatorAddresses);
    if (address) {
      const addressFound = operatorAddresses.find(
        (x: any) => x.bech32() == address
      );
      if (!addressFound) {
        navigate(routeNames.home, {
          state: {}
        });
      } else {
        if (pawnShopOwnerAddress == address) {
          setIsOwnerWallet(true);
        }
        setPermissionAllowed(true);
      }
    } else {
      navigate(routeNames.home, {
        state: {}
      });
    }
  }

  React.useEffect(() => {
    const currentSessionId = sessionStorage.getItem('SessionId');
    if (currentSessionId) {
      setTransactionPending(true);
      setSessionId(currentSessionId);
    } else {
      setTransactionPending(false);
    }
  }, []);

  React.useEffect(() => {
    loadOperatorAddresses();
  }, [address]);

  // Hide show Section start
  const [showNFTCompany, setShowNFTCompany] = React.useState<boolean>(false);
  const [showNFTCollection, setShowNFTCollection] =
    React.useState<boolean>(false);
  const [showTierLoan, setShowTierLoan] = React.useState<boolean>(false);
  const [showBoostInfo, setShowBoostInfo] = React.useState<boolean>(false);
  const [showPawnFee, setShowPawnFee] = React.useState<boolean>(false);
  const [showOtherSettings, setShowOtherSettings] =
    React.useState<boolean>(false);
  // Hide show Section End

  // Section 1 States
  const [companyInfo, setCompanyInfo] = React.useState<NFTCompany[]>([]);
  const [selectedCompanyS1, setSelectedCompanyS1] =
    React.useState<NFTCompany>();
  const [inputS1, setInputS1] = React.useState<string>('');
  const [checkBoxS1, setCheckBoxS1] = React.useState<boolean>(false);
  const [disableActionS1, setDisableActionS1] = React.useState<boolean>(true);
  // Section 2 States
  const [nftCollections, setNftCollections] = React.useState<
    NFTCollectionAdmin[]
  >([]);
  const [tiers, setTiers] = React.useState([]);
  const [selectedCompanyS2, setSelectedCompanyS2] =
    React.useState<NFTCompany>();
  const [selectedCollectionS2, setSelectedCollectionS2] =
    React.useState<NFTCollectionAdmin>();
  const [selectedTierS2, setSelectedTierS2] = React.useState<string>('');
  const [inputS2, setInputS2] = React.useState<string>('');
  const [checkBoxS2, setCheckBoxS2] = React.useState<boolean>(false);
  const [disableActionS2, setDisableActionS2] = React.useState<boolean>(true);
  // Section 3 States
  const [tierLoanInfo, setTierLoanInfo] = React.useState<TierLoanInfo[]>([]);
  const [selectedTierS3, setSelectedTierS3] = React.useState<TierLoanInfo>();
  const [inputS3, setInputS3] = React.useState<string>('');
  const [disableActionS3, setDisableActionS3] = React.useState<boolean>(true);
  // Section 4 States
  const [boostersInfo, setBoostersInfo] = React.useState<BoostInfo[]>([]);
  const [selectedBoostLevelS4, setSelectedBoostLevelS4] =
    React.useState<BoostInfo>();
  const [inputBoostPercentageS4, setInputBoostPercentageS4] =
    React.useState<string>('');
  const [inputFeeDiscountS4, setInputFeeDiscountS4] =
    React.useState<string>('');
  const [disableActionS4, setDisableActionS4] = React.useState<boolean>(true);
  // Section 5 States
  const [allPawnFeeInfo, setAllPawnFeeInfo] = React.useState<
    PawnFeeInfoAdmin[]
  >([]);
  const [selectedLoanDurationS5, setSelectedLoanDurationS5] =
    React.useState<PawnFeeInfoAdmin>();
  const [inputS5, setInputS5] = React.useState<string>('');
  const [disableActionS5, setDisableActionS5] = React.useState<boolean>(true);
  // Section 6 states
  const [scFrozenState, setScState] = React.useState<boolean>();
  const [scPawnEnableState, setScPawnEnableState] = React.useState<boolean>();

  const [inputS6, setInputS6] = React.useState<string>('');

  // Section 1 Methods
  const checkChangeS1 = () => {
    if (selectedCompanyS1 == undefined) {
      return;
    }
    const actionObj: NFTCompany = {
      companyName: '',
      loansAllowedForCompany: false,
      maxLoanPoolLimitPercentageForCompany: 0
    };
    actionObj.companyName = selectedCompanyS1.companyName;
    actionObj.loansAllowedForCompany = checkBoxS1;
    actionObj.maxLoanPoolLimitPercentageForCompany = parseFloat(inputS1);
    //for (let i = 0; i < companyInfo.length; i++) {
    if (
      selectedCompanyS1.companyName == actionObj.companyName &&
      selectedCompanyS1.loansAllowedForCompany ==
        actionObj.loansAllowedForCompany &&
      selectedCompanyS1.maxLoanPoolLimitPercentageForCompany ==
        actionObj.maxLoanPoolLimitPercentageForCompany
    ) {
      setDisableActionS1(true);
      // return;
    }
    //}
    else {
      setDisableActionS1(false);
    }
  };
  React.useEffect(() => {
    checkChangeS1();
  }, [selectedCompanyS1, inputS1, checkBoxS1]);
  React.useEffect(() => {
    if (selectedCompanyS1) {
      setInputS1('' + selectedCompanyS1.maxLoanPoolLimitPercentageForCompany);
      setCheckBoxS1(selectedCompanyS1.loansAllowedForCompany);
    }
  }, [selectedCompanyS1]);
  // Section 2 Methods
  const checkChangeS2 = () => {
    if (selectedCompanyS2 == undefined || selectedTierS2 == 'none') {
      return;
    }
    const actionObj: NFTCollectionAdmin = {
      companyName: '',
      active: false,
      collectionName: '',
      collectionTier: '',
      collectionTokenIdentifier: '',
      floorPrice: 0
    };
    if (selectedCompanyS2 && selectedCollectionS2) {
      actionObj.companyName = selectedCompanyS2.companyName;
      actionObj.collectionName = selectedCollectionS2.collectionName;
      actionObj.active = checkBoxS2;
      actionObj.collectionTier = selectedTierS2;
      actionObj.collectionTokenIdentifier =
        selectedCollectionS2.collectionTokenIdentifier;
      actionObj.floorPrice = parseFloat(inputS2);
    }
    //for (let i = 0; i < nftCollections.length; i++) {
    if (
      selectedCompanyS2 &&
      selectedCollectionS2 &&
      selectedCompanyS2.companyName == actionObj.companyName &&
      selectedCollectionS2.collectionName == actionObj.collectionName &&
      selectedCollectionS2.active == actionObj.active &&
      selectedCollectionS2.collectionTier == actionObj.collectionTier &&
      selectedCollectionS2.collectionTokenIdentifier ==
        actionObj.collectionTokenIdentifier &&
      selectedCollectionS2.floorPrice == actionObj.floorPrice
    ) {
      setDisableActionS2(true);
      // return;
    }
    // }
    else {
      setDisableActionS2(false);
    }
  };
  React.useEffect(() => {
    checkChangeS2();
  }, [
    selectedCompanyS2,
    selectedCollectionS2,
    inputS2,
    checkBoxS2,
    selectedTierS2
  ]);
  React.useEffect(() => {
    setSelectedTierS2('none');
    setInputS2('');
  }, [selectedCompanyS2]);
  React.useEffect(() => {
    if (selectedCollectionS2 && pawnShopTradingInfo) {
      if (
        pawnShopTradingInfo[selectedCollectionS2.collectionTokenIdentifier] !=
        undefined
      ) {
        setInputS2(
          '' +
            pawnShopTradingInfo[selectedCollectionS2.collectionTokenIdentifier]
              .newFloorPrice
        );
      } else {
        setInputS2('' + selectedCollectionS2.floorPrice);
      }
      setCheckBoxS2(selectedCollectionS2.active);
      setSelectedTierS2(selectedCollectionS2.collectionTier);
    }
  }, [selectedCollectionS2]);
  React.useEffect(() => {
    if (selectedBoostLevelS4) {
      setInputBoostPercentageS4('' + selectedBoostLevelS4.loanBoostPercentage);
      setInputFeeDiscountS4(
        '' + selectedBoostLevelS4.loanFeeDiscountPercentage
      );
    }
  }, [selectedBoostLevelS4]);
  // Section 3 Methods
  const checkChangeS3 = () => {
    if (selectedTierS3 == undefined) {
      return;
    }
    const actionObj: any = {
      collectionTier: '',
      collectionMaxLoanRatePercentage: 0
    };
    if (selectedTierS3) {
      actionObj.collectionTier = selectedTierS3.collectionTier;
      actionObj.collectionMaxLoanRatePercentage = inputS3;
    }
    // for (let i = 0; i < tierLoanInfo.length; i++) {
    if (
      selectedTierS3.collectionTier == actionObj.collectionTier &&
      selectedTierS3.collectionMaxLoanRatePercentage ==
        actionObj.collectionMaxLoanRatePercentage
    ) {
      setDisableActionS3(true);
      // return;
    }
    // }
    else {
      setDisableActionS3(false);
    }
  };
  React.useEffect(() => {
    if (selectedTierS3) {
      setInputS3('' + selectedTierS3.collectionMaxLoanRatePercentage);
    }
  }, [selectedTierS3]);
  React.useEffect(() => {
    checkChangeS3();
  }, [selectedTierS3, inputS3]);
  // Section 4 Methods
  const checkChangeS4 = () => {
    if (selectedBoostLevelS4 == undefined) {
      return;
    }
    const actionObj: any = {
      level: '',
      loanBoostPercentage: 0,
      loanFeeDiscountPercentage: 0
    };
    if (selectedBoostLevelS4) {
      actionObj.level = selectedBoostLevelS4.level;
      actionObj.loanBoostPercentage = inputBoostPercentageS4;
      actionObj.loanFeeDiscountPercentage = inputFeeDiscountS4;
    }
    // for (let i = 0; i < boostersInfo.length; i++) {
    if (
      selectedBoostLevelS4.level == actionObj.level &&
      selectedBoostLevelS4.loanBoostPercentage ==
        actionObj.loanBoostPercentage &&
      selectedBoostLevelS4.loanFeeDiscountPercentage ==
        actionObj.loanFeeDiscountPercentage
    ) {
      setDisableActionS4(true);
      // return;
    }
    // }
    else {
      setDisableActionS4(false);
    }
  };
  React.useEffect(() => {
    checkChangeS4();
  }, [selectedBoostLevelS4, inputBoostPercentageS4, inputFeeDiscountS4]);
  // Section 5 Methods
  const durationDisplay = (duration: number) => {
    let durationToDisplay = '';
    if (duration) {
      if (duration % (24 * 60 * 60) == 0) {
        durationToDisplay = `${duration / (24 * 60 * 60)} Days`;
      } else if (duration % (60 * 60) == 0) {
        durationToDisplay = `${duration / (60 * 60)} Hours`;
      } else if (duration % 60 == 0) {
        durationToDisplay = `${duration / 60} Minute`;
      }
    }
    return durationToDisplay;
  };
  const checkChangeS5 = () => {
    if (selectedLoanDurationS5 == undefined) {
      return;
    }
    const actionObj: PawnFeeInfoAdmin = {
      feePercentage: 0,
      loanDurationTicks: 0,
      maxAllowedLoanExtensionTicks: 0
    };
    if (selectedLoanDurationS5) {
      actionObj.feePercentage = parseFloat(inputS5);
      actionObj.loanDurationTicks = selectedLoanDurationS5.loanDurationTicks;
      actionObj.maxAllowedLoanExtensionTicks =
        selectedLoanDurationS5.maxAllowedLoanExtensionTicks;
    }
    // for (let i = 0; i < allPawnFeeInfo.length; i++) {
    if (
      selectedLoanDurationS5.feePercentage == actionObj.feePercentage &&
      selectedLoanDurationS5.loanDurationTicks == actionObj.loanDurationTicks &&
      selectedLoanDurationS5.maxAllowedLoanExtensionTicks ==
        actionObj.maxAllowedLoanExtensionTicks
    ) {
      setDisableActionS5(true);
      // return;
    }
    // }
    else {
      setDisableActionS5(false);
    }
  };
  React.useEffect(() => {
    checkChangeS5();
  }, [inputS5]);
  React.useEffect(() => {
    if (selectedLoanDurationS5) {
      setInputS5('' + selectedLoanDurationS5.feePercentage);
    }
  }, [selectedLoanDurationS5]);
  //Load Data
  async function loadSettings() {
    // Section 1
    const companyInfoData = await CommonHelper.getAllCompaniesInfo(proxy);
    setCompanyInfo(companyInfoData);
    //Section 2
    const nftCollectionsData = await CommonHelper.getAllCollectionsInfo(proxy);
    const tierSort = (a: any, b: any) => {
      const tierArr: any = {
        Diamond: 1,
        Platinum: 2,
        Gold: 3,
        Silver: 4,
        Bronze: 5
      };
      return tierArr[a.collectionTier] - tierArr[b.collectionTier];
    };
    nftCollectionsData.sort((a: any, b: any) => tierSort(a, b));
    setNftCollections(nftCollectionsData);
    const tiersData = await PawnShopSmartContract.getAllTiers(proxy);
    setTiers(tiersData);
    //Section 3
    const tierLoanData = await CommonHelper.getAllTiersLoanInfo(proxy);
    tierLoanData.sort((a: any, b: any) => tierSort(a, b));
    setTierLoanInfo(tierLoanData);
    //Section 4
    const boostersInfoData = await CommonHelper.getAllBoostersInfo(proxy);
    setBoostersInfo(boostersInfoData);
    //Section 5
    const allPawnFeeInfoData = await CommonHelper.getAllPawnFeeInfo(proxy);
    setAllPawnFeeInfo(allPawnFeeInfoData);

    const frozenStatus = await PawnShopSmartContract.getSCFrozenStatus(proxy);
    setScState(frozenStatus);

    const pawnEnable = await PawnShopSmartContract.getSCPawnEnableStatus(proxy);
    setScPawnEnableState(pawnEnable);
  }
  React.useEffect(() => {
    if (!transactionPending && permissionAllowed) {
      loadSettings();
    }
  }, [permissionAllowed]);

  React.useEffect(() => {
    getFloorPrice();
  }, [nftCollections]);

  async function getFloorPrice() {
    const collection = new CollectionModule();
    // const tradingStats : PawnShopTradingInfo[] = [];
    const tradingStats: {
      [collectionIdentifier: string]: PawnShopTradingInfo;
    } = {};
    for (let index = 0; index < nftCollections.length; index++) {
      const collectionInfo = nftCollections[index];
      const tokenStats = await collection.getCollectionsStatistics({
        filters: {
          collection: [collectionInfo.collectionTokenIdentifier]
        },
        select: [CollectionStatsSelectFields.TradingStats],
        top: 25,
        skip: 0
      });

      const currentMktFloorPriceInfo = await collection.getCollectionFloorPrice(
        collectionInfo.collectionTokenIdentifier
      );

      let newFloorPrice =
        tokenStats.resources[0].tradingStats.week.minPrice === undefined
          ? 0
          : tokenStats.resources[0].tradingStats.week.minPrice;
      if (tokenStats.resources[0].tradingStats.week.trades < 2) {
        if (tokenStats.resources[0].tradingStats.month.trades < 2) {
          newFloorPrice = 0;
        } else {
          newFloorPrice =
            tokenStats.resources[0].tradingStats.month.minPrice === undefined
              ? 0
              : tokenStats.resources[0].tradingStats.month.minPrice;
        }
      }

      newFloorPrice =
        currentMktFloorPriceInfo < newFloorPrice
          ? currentMktFloorPriceInfo
          : newFloorPrice;

      const info: PawnShopTradingInfo = {
        tokenIdentifier: collectionInfo.collectionTokenIdentifier,
        weeklyTradeCount: tokenStats.resources[0].tradingStats.week.trades,
        weekMinSalePrice:
          tokenStats.resources[0].tradingStats.week.minPrice === undefined
            ? 0
            : tokenStats.resources[0].tradingStats.week.minPrice,
        monthlyTradeCount: tokenStats.resources[0].tradingStats.month.trades,
        monthMinSalePrice:
          tokenStats.resources[0].tradingStats.month.minPrice === undefined
            ? 0
            : tokenStats.resources[0].tradingStats.month.minPrice,
        newFloorPrice: newFloorPrice,
        currentMktFloorPrice: currentMktFloorPriceInfo
      };

      tradingStats[collectionInfo.collectionTokenIdentifier] = info;
    }

    setPawnShopTradingInfo(tradingStats);

    //For finding the lowest sale in last 7 days, check getTradingActivity method

    // var floor = collection.getCollectionFloor('', '2023-08-17', '2023-09-08', '7d');
  }

  async function loaderHandler(
    status: boolean,
    refreshData: boolean,
    response?: any,
    message?: NFTmessage
  ) {
    // console.log('empty function');

    if (refreshData) {
      await loadSettings();
      setTransactionPending(false);
    }

    if (response) {
      if (response.status === 'success') {
        // if (response.operations && response.operations.length > 0) {
        message = {
          msg: `Transaction Successful.To see the transaction on blockchain explorer. <br/> <a target='_blank' href=${explorer_url}/transactions/${response.txHash}>Click Here</a>`,
          type: 'message',
          title: ''
        };

        //}
      }
    }

    setIsLoading(status);
    if (message) {
      setMsgObj(message);
    }
  }
  const okFunc = () => {
    setMsgObj({ msg: '', type: '', title: '' });
  };

  // Action Methods
  const nftCompanyAction = async () => {
    if (selectedCompanyS1 == undefined) {
      return;
    }
    loaderHandler(true, false);
    const actionObj: NFTCompany = {
      companyName: selectedCompanyS1.companyName,
      maxLoanPoolLimitPercentageForCompany: parseFloat(inputS1),
      loansAllowedForCompany: checkBoxS1
    };
    const companyNameInHex = CommonHelper.convertToHex(actionObj.companyName);
    let maxLoanPoolLimitPercentageForCompanyInHex =
      actionObj.maxLoanPoolLimitPercentageForCompany.toString(16);
    if (maxLoanPoolLimitPercentageForCompanyInHex.length % 2 == 1) {
      maxLoanPoolLimitPercentageForCompanyInHex =
        '0' + maxLoanPoolLimitPercentageForCompanyInHex;
    }
    let loanAllowedForCompany = '00';
    if (actionObj.loansAllowedForCompany) {
      loanAllowedForCompany = '01';
    }
    const updateCompanyInfoTransaction = {
      value: 0,
      data: `update_nft_company_info@${companyNameInHex}@${maxLoanPoolLimitPercentageForCompanyInHex}@${loanAllowedForCompany}`,
      receiver: pawnShopContractAddress,
      gasLimit: 50000000
    };

    const plainUpdateNFTCompanyObject = {
      action: 'update_nft_company_info',
      sender: address,
      scAddress: pawnShopContractAddress
    };

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: updateCompanyInfoTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'claim defaulted nfts transaction is in progress',
        errorMessage:
          'An error has occured during claim defaulted nfts process',
        successMessage: 'claim defaulted nfts transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      // loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = plainUpdateNFTCompanyObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setTransactionPending(true);
        setSessionId(sessionId);
      }
    }
  };
  const nftCollectionAction = async () => {
    loaderHandler(true, false);
    const actionObj: NFTCollectionAdmin = {
      collectionName: '',
      companyName: '',
      floorPrice: 0,
      collectionTokenIdentifier: '',
      active: false,
      collectionTier: ''
    };
    if (selectedCompanyS2 && selectedCollectionS2) {
      actionObj.companyName = selectedCompanyS2.companyName;
      actionObj.active = checkBoxS2;
      actionObj.collectionName = selectedCollectionS2.collectionName;
      actionObj.collectionTier = selectedTierS2;
      actionObj.collectionTokenIdentifier =
        selectedCollectionS2.collectionTokenIdentifier;
      actionObj.floorPrice = parseFloat(inputS2);
    }

    const amountInProperDecimal = Math.floor(actionObj.floorPrice * 10 ** 5);
    const amountInEGLDUnit = amountInProperDecimal * 10 ** 13;
    let floorPriceInHex = amountInEGLDUnit.toString(16);
    debugger;
    if (floorPriceInHex.length % 2 == 1) {
      floorPriceInHex = '0' + floorPriceInHex;
    }

    const updateCollectionInfoTransaction = {
      value: 0,
      data: `update_nft_collection@${CommonHelper.convertToHex(
        actionObj.collectionTokenIdentifier
      )}@${CommonHelper.convertToHex(
        actionObj.collectionName
      )}@${floorPriceInHex}@${
        actionObj.active ? '01' : '00'
      }@${CommonHelper.getTierHexCode(
        actionObj.collectionTier
      )}@${CommonHelper.convertToHex(actionObj.companyName)}`,
      receiver: pawnShopContractAddress,
      gasLimit: 50000000
    };

    const plainUpdateNFTCompanyObject = {
      action: 'update_nft_collection',
      sender: address,
      scAddress: pawnShopContractAddress
    };

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: updateCollectionInfoTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'claim defaulted nfts transaction is in progress',
        errorMessage:
          'An error has occured during claim defaulted nfts process',
        successMessage: 'claim defaulted nfts transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      // loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = plainUpdateNFTCompanyObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setTransactionPending(true);
        setSessionId(sessionId);
      }
    }
  };
  const tierLoanAction = async () => {
    loaderHandler(true, false);
    const actionObj: any = {
      collectionTier: '',
      collectionMaxLoanRatePercentage: 0
    };
    if (selectedTierS3 && inputS3) {
      actionObj.collectionTier = selectedTierS3.collectionTier;
      actionObj.collectionMaxLoanRatePercentage = inputS3;
    }

    let maxLoanRateInHex = parseInt(
      actionObj.collectionMaxLoanRatePercentage
    ).toString(16);
    if (maxLoanRateInHex.length % 2 == 1) {
      maxLoanRateInHex = '0' + maxLoanRateInHex;
    }

    const updateTierLoanInfoTransaction = {
      value: 0,
      data: `update_tier_loan_info@${CommonHelper.getTierHexCode(
        actionObj.collectionTier
      )}@${maxLoanRateInHex}`,
      receiver: pawnShopContractAddress,
      gasLimit: 50000000
    };

    const plainUpdateTierLoanObject = {
      action: 'update_tier_loan_info',
      sender: address,
      scAddress: pawnShopContractAddress
    };

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: updateTierLoanInfoTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'claim defaulted nfts transaction is in progress',
        errorMessage:
          'An error has occured during claim defaulted nfts process',
        successMessage: 'claim defaulted nfts transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      // loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = plainUpdateTierLoanObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setTransactionPending(true);
        setSessionId(sessionId);
      }
    }
  };
  const BoostInfoAction = async () => {
    loaderHandler(true, false);
    const actionObj: any = {
      level: '',
      loanBoostPercentage: 0,
      loanFeeDiscountPercentage: 0
    };
    if (selectedBoostLevelS4) {
      actionObj.level = selectedBoostLevelS4.level;
      actionObj.loanBoostPercentage = inputBoostPercentageS4;
      actionObj.loanFeeDiscountPercentage = inputFeeDiscountS4;
    }

    let loanBoostPercentageInHex = parseInt(
      actionObj.loanBoostPercentage
    ).toString(16);
    if (loanBoostPercentageInHex.length % 2 == 1) {
      loanBoostPercentageInHex = '0' + loanBoostPercentageInHex;
    }

    let loanFeeDiscountPercentageInHex = parseInt(
      actionObj.loanFeeDiscountPercentage
    ).toString(16);
    if (loanFeeDiscountPercentageInHex.length % 2 == 1) {
      loanFeeDiscountPercentageInHex = '0' + loanFeeDiscountPercentageInHex;
    }

    const updateBoostInfoTransaction = {
      value: 0,
      data: `update_booster_info@${CommonHelper.getLevelHexCode(
        actionObj.level
      )}@${loanBoostPercentageInHex}@${loanFeeDiscountPercentageInHex}`,
      receiver: pawnShopContractAddress,
      gasLimit: 50000000
    };

    const plainUpdateBoostInfonObject = {
      action: 'update_booster_info',
      sender: address,
      scAddress: pawnShopContractAddress
    };

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: updateBoostInfoTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'claim defaulted nfts transaction is in progress',
        errorMessage:
          'An error has occured during claim defaulted nfts process',
        successMessage: 'claim defaulted nfts transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      // loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = plainUpdateBoostInfonObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setTransactionPending(true);
        setSessionId(sessionId);
      }
    }
  };
  const pawnFeeAction = async () => {
    loaderHandler(true, false);
    const actionObj: PawnFeeInfoAdmin = {
      feePercentage: 0,
      loanDurationTicks: 0,
      maxAllowedLoanExtensionTicks: 0
    };
    if (selectedLoanDurationS5) {
      actionObj.feePercentage = parseFloat(inputS5);
      actionObj.loanDurationTicks = selectedLoanDurationS5.loanDurationTicks;
      actionObj.maxAllowedLoanExtensionTicks =
        selectedLoanDurationS5.maxAllowedLoanExtensionTicks;
    }
    let feePercentageInHex = actionObj.feePercentage.toString(16);
    if (feePercentageInHex.length % 2 == 1) {
      feePercentageInHex = '0' + feePercentageInHex;
    }

    let loanDurationTicksInHex = actionObj.loanDurationTicks.toString(16);
    if (loanDurationTicksInHex.length % 2 == 1) {
      loanDurationTicksInHex = '0' + loanDurationTicksInHex;
    }

    const updateBoostInfoTransaction = {
      value: 0,
      data: `update_pawn_fee_info@${loanDurationTicksInHex}@${feePercentageInHex}@00`,
      receiver: pawnShopContractAddress,
      gasLimit: 50000000
    };

    const plainUpdateBoostInfonObject = {
      action: 'update_pawn_fee_info',
      sender: address,
      scAddress: pawnShopContractAddress
    };

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: updateBoostInfoTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'claim defaulted nfts transaction is in progress',
        errorMessage:
          'An error has occured during claim defaulted nfts process',
        successMessage: 'claim defaulted nfts transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      // loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = plainUpdateBoostInfonObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setTransactionPending(true);
        setSessionId(sessionId);
      }
    }
  };

  const freezeUnfreezeContract = async () => {
    loaderHandler(true, false);
    let action = 'freeze_contract';
    if (scFrozenState) {
      action = 'unfreeze_contract';
    }

    const updateFrozenStatusTransaction = {
      value: 0,
      data: `${action}`,
      receiver: pawnShopContractAddress,
      gasLimit: 50000000
    };

    const plainFrozenInfonObject = {
      action: `${action}`,
      sender: address,
      scAddress: pawnShopContractAddress
    };

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: updateFrozenStatusTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'claim defaulted nfts transaction is in progress',
        errorMessage:
          'An error has occured during claim defaulted nfts process',
        successMessage: 'claim defaulted nfts transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      // loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = plainFrozenInfonObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setTransactionPending(true);
        setSessionId(sessionId);
      }
    }
  };

  const recoveryAmountAction = async () => {
    console.log('Recovery Amount is :', inputS6);
    loaderHandler(true, false);
    const action = 'add_recovery_amount';
    const amountInProperDecimal = Math.floor(parseFloat(inputS6) * 10 ** 5);

    const amountInEGLDUnit = amountInProperDecimal * 10 ** 13;
    // const amount = parseFloat(inputS6) * 10 ** 18;
    const addRecoveryAmountTransaction = {
      value: amountInEGLDUnit,
      data: `${action}`,
      receiver: pawnShopContractAddress,
      gasLimit: 50000000
    };

    const plainRecoveryAmountInfonObject = {
      action: `${action}`,
      sender: address,
      scAddress: pawnShopContractAddress
    };

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: addRecoveryAmountTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'claim defaulted nfts transaction is in progress',
        errorMessage:
          'An error has occured during claim defaulted nfts process',
        successMessage: 'claim defaulted nfts transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      // loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = plainRecoveryAmountInfonObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setTransactionPending(true);
        setSessionId(sessionId);
      }
    }
  };
  const PawnEnableAction = async () => {
    loaderHandler(true, false);
    const action = 'set_pawn_enable';
    let enable = '01';
    if (scPawnEnableState) {
      enable = '00';
    }
    const pawnEnableTransaction = {
      value: 0,
      data: `${action}@${enable}`,
      receiver: pawnShopContractAddress,
      gasLimit: 50000000
    };

    const pawnEnableInfoObject = {
      action: `${action}`,
      sender: address,
      scAddress: pawnShopContractAddress
    };

    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: pawnEnableTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Pawn Enable transaction is in progress',
        errorMessage: 'An error has occured during Pawn Enable process',
        successMessage: 'Pawn Enable transaction successful',
        transactionDuration: 20000
      },
      signWithoutSending: true
    });

    if (error != undefined || null) {
      // loaderHandler(false, false);
      console.log(error);
    } else if (sessionId != null) {
      const sessionTransactionObject: { [name: string]: any } = {};
      sessionTransactionObject[sessionId] = pawnEnableInfoObject;
      sessionStorage.setItem(
        'currentTransaction',
        JSON.stringify(sessionTransactionObject)
      );
      if (loginMethod == LoginMethodsEnum.wallet) {
        sessionStorage.setItem('SessionId', sessionId);
      } else {
        setTransactionPending(true);
        setSessionId(sessionId);
      }
    }
  };
  const enforceMinMax = (
    value: string,
    min: string,
    max: string,
    prevVal: string
  ) => {
    const check: any = value.match(/^[0-9]*(\.[0-9]{0,4})?$/);
    let val: string;
    if (!value) {
      return '0';
    }
    if (check) {
      val = value;
      while (val.charAt(0) === '0' && val.charAt(1) !== '.' && val.length > 1) {
        val = val.substring(1);
      }
      if (parseFloat(value) < parseFloat(min)) {
        val = min;
      }
      if (parseFloat(value) > parseFloat(max)) {
        val = max;
      }
      return val ? val : '0';
    }
    return prevVal;
  };

  return (
    <>
      {isLoading ? (
        <div className='loader'>
          <div className='loader_content'>
            {/* <img src={loader} /> */}
            <svg
              aria-hidden='true'
              focusable='false'
              data-prefix='fat'
              data-icon='spinner-third'
              className='svg-inline--fa fa-spinner-third fa-5x primary fa-spin fast-spin modal-layout-loader-icon'
              role='img'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 576 512'
            >
              <path
                fill='currentColor'
                d='M280 8C280 3.582 283.6 0 288 0C429.4 0 544 114.6 544 256C544 302.6 531.5 346.4 509.7 384C507.5 387.9 502.6 389.2 498.8 386.9C494.1 384.7 493.7 379.8 495.9 376C516.3 340.7 528 299.7 528 256C528 123.5 420.5 16 288 16C283.6 16 280 12.42 280 8V8z'
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        ''
      )}
      {sessionStateId ? (
        <TrackTransaction
          sessionId={sessionStateId}
          loaderHandler={loaderHandler}
          status={true}
        ></TrackTransaction>
      ) : (
        ''
      )}
      {msgObj && msgObj.msg ? (
        <ConfirmModal data={msgObj}>
          <button
            className='ok-btn'
            onClick={() => {
              okFunc();
            }}
          >
            Ok
          </button>
        </ConfirmModal>
      ) : (
        ''
      )}
      {transactionPending ? (
        ''
      ) : (
        <div className='admin-settings'>
          <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container pb-24'>
            {companyInfo ? (
              <>
                <div className='nftListLabel m-p-0'>
                  <h2
                    className='row cur-pointer m-p-0'
                    onClick={() => setShowNFTCompany(!showNFTCompany)}
                  >
                    NFT Company
                    <i
                      className={showNFTCompany ? 'chevron' : 'chevron rotate'}
                    ></i>
                  </h2>
                </div>
                <div className={showNFTCompany ? 'row col-12' : 'd-none'}>
                  <div className='col-12'>
                    <table className='tb-transaction table table-responsive table-striped text-white'>
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Loan % </th>
                          <th> Active </th>
                        </tr>
                      </thead>
                      <tbody>
                        {companyInfo.map((item: NFTCompany, index: number) => (
                          <tr key={index}>
                            <td>{item.companyName}</td>
                            <td>{item.maxLoanPoolLimitPercentageForCompany}</td>
                            <td>
                              {item.loansAllowedForCompany ? 'True' : 'False'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {IsOwnerWallet ? (
                    <>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>Company</label>
                        <select
                          className='col-12'
                          onChange={(e: any) => {
                            setSelectedCompanyS1(companyInfo[e.target.value]);
                          }}
                        >
                          <option value='0' style={{ display: 'none' }}>
                            Select Company
                          </option>
                          {companyInfo.map(
                            (item: NFTCompany, index: number) => (
                              <option key={index} value={index}>
                                {item.companyName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>
                          Loan % :{' '}
                          <span className='orange-text'>
                            {
                              selectedCompanyS1?.maxLoanPoolLimitPercentageForCompany
                            }
                          </span>
                        </label>
                        <input
                          type='text'
                          min='0'
                          max='100'
                          className='m-w-100 w-100 reqLoan text-right'
                          onChange={(event: any) =>
                            setInputS1(
                              enforceMinMax(
                                event.target.value,
                                event.target.min,
                                event.target.max,
                                inputS1
                              )
                            )
                          }
                          value={inputS1 ? inputS1 : ''}
                          disabled={!selectedCompanyS1}
                        ></input>
                      </div>
                      <div className='col-6 col-sm-2 m-b-15 text-center'>
                        <label className='text-white'>
                          Active :{' '}
                          <span className='orange-text'>
                            {selectedCompanyS1
                              ? selectedCompanyS1.loansAllowedForCompany
                                ? 'True'
                                : 'False'
                              : ''}
                          </span>
                        </label>
                        <br></br>
                        <input
                          type='checkbox'
                          className='h-w-20'
                          onChange={(e) => setCheckBoxS1(e.target.checked)}
                          checked={checkBoxS1 ? checkBoxS1 : false}
                          disabled={!selectedCompanyS1}
                        ></input>
                      </div>
                      <div className='col-6 col-sm-2 m-b-15'>
                        <label>&nbsp;</label>
                        <button
                          className='NFT__CardBody--ButtonStack btn btn-primary col-12'
                          onClick={nftCompanyAction}
                          disabled={disableActionS1}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              <div className='nftListLabel'>Company Info Missing</div>
            )}
          </div>

          <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container pb-24'>
            {nftCollections && pawnShopTradingInfo ? (
              <>
                <div className='nftListLabel m-p-0'>
                  <h2
                    className='row cur-pointer m-p-0'
                    onClick={() => setShowNFTCollection(!showNFTCollection)}
                  >
                    NFT Collection
                    <i
                      className={
                        showNFTCollection ? 'chevron' : 'chevron rotate'
                      }
                    ></i>
                  </h2>
                </div>
                <div className={showNFTCollection ? 'row col-12' : 'd-none'}>
                  <div className='col-12'>
                    <table className='tb-transaction table table-responsive table-striped text-white'>
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Collection</th>
                          <th> Tier</th>
                          <th> PW Floor Price</th>
                          <th> Active</th>
                          <th> Mkt Floor Price</th>
                          <th> New Recommended Price</th>
                          <th> Weekly Trades</th>
                          <th> Week Min Sale</th>
                          <th> Monthly Trades</th>
                          <th> Month Min Sale</th>
                        </tr>
                      </thead>
                      <tbody>
                        {nftCollections.map(
                          (item: NFTCollectionAdmin, index: number) => (
                            <tr key={index}>
                              <td>{item.companyName}</td>
                              <td>{item.collectionName}</td>
                              <td>{item.collectionTier}</td>
                              <td>{item.floorPrice}</td>
                              <td>{item.active ? 'True' : 'False'}</td>
                              <td>
                                {
                                  pawnShopTradingInfo[
                                    item.collectionTokenIdentifier
                                  ]?.currentMktFloorPrice
                                }
                              </td>
                              <td>
                                {
                                  pawnShopTradingInfo[
                                    item.collectionTokenIdentifier
                                  ]?.newFloorPrice
                                }
                              </td>
                              <td>
                                {
                                  pawnShopTradingInfo[
                                    item.collectionTokenIdentifier
                                  ]?.weeklyTradeCount
                                }
                              </td>
                              <td>
                                {
                                  pawnShopTradingInfo[
                                    item.collectionTokenIdentifier
                                  ]?.weekMinSalePrice
                                }
                              </td>
                              <td>
                                {
                                  pawnShopTradingInfo[
                                    item.collectionTokenIdentifier
                                  ]?.monthlyTradeCount
                                }
                              </td>
                              <td>
                                {
                                  pawnShopTradingInfo[
                                    item.collectionTokenIdentifier
                                  ]?.monthMinSalePrice
                                }
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  {IsOwnerWallet ? (
                    <>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>Company</label>
                        <select
                          className='col-12'
                          onChange={(e: any) => {
                            setSelectedCompanyS2(companyInfo[e.target.value]);
                          }}
                        >
                          <option value='0' style={{ display: 'none' }}>
                            Select Company
                          </option>
                          {companyInfo.map(
                            (item: NFTCompany, index: number) => (
                              <option key={index} value={index}>
                                {item.companyName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>Collection</label>
                        <select
                          className='col-12'
                          onChange={(e: any) => {
                            setSelectedCollectionS2(
                              nftCollections[e.target.value]
                            );
                          }}
                          disabled={!selectedCompanyS2}
                        >
                          <option value='0' style={{ display: 'none' }}>
                            Select collection
                          </option>
                          {nftCollections.map(
                            (item: NFTCollectionAdmin, index: number) =>
                              item.companyName ==
                              selectedCompanyS2?.companyName ? (
                                <option key={index} value={index}>
                                  {item.collectionName}
                                </option>
                              ) : (
                                ''
                              )
                          )}
                        </select>
                      </div>
                      <div className='col-sm-4 d-none d-sm-block'></div>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>
                          Tier :{' '}
                          <span className='orange-text'>
                            {selectedCollectionS2?.collectionTier}
                          </span>
                        </label>

                        <select
                          className='col-12'
                          onChange={(e: any) => {
                            setSelectedTierS2(e.target.value);
                          }}
                          value={selectedTierS2}
                          disabled={!selectedCollectionS2}
                        >
                          <option value='none' style={{ display: 'none' }}>
                            Select Tier
                          </option>
                          {tiers.map((item: any, index: number) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>
                          Floor Price :{' '}
                          <span className='orange-text'>
                            {selectedCollectionS2?.floorPrice}
                          </span>
                        </label>
                        <input
                          type='text'
                          min='0'
                          max='100'
                          className='m-w-100 w-100 reqLoan text-right'
                          onChange={(e: any) =>
                            setInputS2(
                              enforceMinMax(
                                e.target.value,
                                e.target.min,
                                e.target.max,
                                inputS2
                              )
                            )
                          }
                          value={inputS2 ? inputS2 : ''}
                          disabled={selectedTierS2 === 'none'}
                        ></input>
                      </div>
                      <div className='col-6 col-sm-2 m-b-15 text-center'>
                        <label className='text-white'>
                          Active :{' '}
                          <span className='orange-text'>
                            {selectedCollectionS2
                              ? selectedCollectionS2.active
                                ? 'True'
                                : 'False'
                              : ''}
                          </span>
                        </label>
                        <br></br>
                        <input
                          type='checkbox'
                          className='h-w-20'
                          onChange={(e) => setCheckBoxS2(e.target.checked)}
                          checked={checkBoxS2 ? checkBoxS2 : false}
                          disabled={!selectedCollectionS2}
                        ></input>
                      </div>
                      <div className='col-6 col-sm-2 m-b-15'>
                        <label>&nbsp;</label>
                        <button
                          className='NFT__CardBody--ButtonStack btn btn-primary col-12'
                          onClick={nftCollectionAction}
                          disabled={disableActionS2}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              <div className='nftListLabel'>Company Info Missing</div>
            )}
          </div>

          <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container pb-24'>
            {tierLoanInfo ? (
              <>
                <div className='nftListLabel m-p-0'>
                  <h2
                    className='row cur-pointer m-p-0'
                    onClick={() => setShowTierLoan(!showTierLoan)}
                  >
                    Tier Loan
                    <i
                      className={showTierLoan ? 'chevron' : 'chevron rotate'}
                    ></i>
                  </h2>
                </div>
                <div className={showTierLoan ? 'row col-12' : 'd-none'}>
                  <div className='col-12'>
                    <table className='tb-transaction table table-responsive table-striped text-white'>
                      <thead>
                        <tr>
                          <th>Tier</th>
                          <th>Loan %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tierLoanInfo.map((item: any, index: number) => (
                          <tr key={index}>
                            <td>{item.collectionTier}</td>
                            <td>{item.collectionMaxLoanRatePercentage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {IsOwnerWallet ? (
                    <>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>Tier</label>
                        <select
                          className='col-12'
                          onChange={(e: any) => {
                            setSelectedTierS3(tierLoanInfo[e.target.value]);
                          }}
                        >
                          <option value='none' style={{ display: 'none' }}>
                            Select Tier
                          </option>
                          {tierLoanInfo.map((item: any, index: number) => (
                            <option key={index} value={index}>
                              {item.collectionTier}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>
                          Loan % :{' '}
                          <span className='orange-text'>
                            {selectedTierS3?.collectionMaxLoanRatePercentage}
                          </span>
                        </label>
                        <input
                          type='text'
                          min='0'
                          max='60'
                          className='m-w-100 w-100 reqLoan text-right'
                          value={inputS3 ? inputS3 : ''}
                          onChange={(e: any) =>
                            setInputS3(
                              enforceMinMax(
                                e.target.value,
                                e.target.min,
                                e.target.max,
                                inputS3
                              )
                            )
                          }
                          disabled={!selectedTierS3}
                        ></input>
                      </div>
                      <div className='col-6 d-block col-sm-2'></div>

                      <div className='col-6 col-sm-2 m-b-15'>
                        <label>&nbsp;</label>
                        <button
                          className='NFT__CardBody--ButtonStack btn btn-primary col-12'
                          onClick={tierLoanAction}
                          disabled={disableActionS3}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              <div className='nftListLabel'>Tier Info Missing</div>
            )}
          </div>

          <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container pb-24'>
            {boostersInfo ? (
              <>
                <div className='nftListLabel m-p-0'>
                  <h2
                    className='row cur-pointer m-p-0'
                    onClick={() => setShowBoostInfo(!showBoostInfo)}
                  >
                    Boost Info
                    <i
                      className={showBoostInfo ? 'chevron' : 'chevron rotate'}
                    ></i>
                  </h2>
                </div>
                <div className={showBoostInfo ? 'row col-12' : 'd-none'}>
                  <div className='col-12'>
                    <table className='tb-transaction table table-responsive table-striped text-white'>
                      <thead>
                        <tr>
                          <th>Level</th>
                          <th>Boost %</th>
                          <th>Fee Discount %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {boostersInfo.map((item: BoostInfo, index: number) => (
                          <tr key={index}>
                            <td>{item.level}</td>
                            <td>{item.loanBoostPercentage}</td>
                            <td>{item.loanFeeDiscountPercentage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {IsOwnerWallet ? (
                    <>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>Level</label>
                        <select
                          className='col-12'
                          onChange={(e: any) => {
                            setSelectedBoostLevelS4(
                              boostersInfo[e.target.value]
                            );
                          }}
                        >
                          <option value='0' style={{ display: 'none' }}>
                            Select Level
                          </option>
                          {boostersInfo.map(
                            (item: BoostInfo, index: number) => (
                              <option key={index} value={index}>
                                {item.level}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>
                          Boost % :{' '}
                          <span className='orange-text'>
                            {selectedBoostLevelS4?.loanBoostPercentage}
                          </span>
                        </label>
                        <input
                          type='text'
                          min='0'
                          max='30'
                          className='m-w-100 w-100 reqLoan text-right'
                          onChange={(e: any) =>
                            setInputBoostPercentageS4(
                              enforceMinMax(
                                e.target.value,
                                e.target.min,
                                e.target.max,
                                inputBoostPercentageS4
                              )
                            )
                          }
                          value={
                            inputBoostPercentageS4 ? inputBoostPercentageS4 : ''
                          }
                          disabled={!selectedBoostLevelS4}
                        ></input>
                      </div>
                      <div className='col-4 d-none d-sm-block'></div>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>
                          Fee Discount % :{' '}
                          <span className='orange-text'>
                            {selectedBoostLevelS4?.loanFeeDiscountPercentage}
                          </span>
                        </label>
                        <input
                          type='text'
                          min='0'
                          max='90'
                          className='m-w-100 w-100 reqLoan text-right'
                          onChange={(e: any) =>
                            setInputFeeDiscountS4(
                              enforceMinMax(
                                e.target.value,
                                e.target.min,
                                e.target.max,
                                inputFeeDiscountS4
                              )
                            )
                          }
                          value={inputFeeDiscountS4 ? inputFeeDiscountS4 : ''}
                          disabled={!selectedBoostLevelS4}
                        ></input>
                      </div>
                      <div className='col-6 d-none d-sm-block'></div>

                      <div className='col-6 col-sm-2 m-b-15'>
                        <label>&nbsp;</label>
                        <button
                          className='NFT__CardBody--ButtonStack btn btn-primary col-12'
                          onClick={BoostInfoAction}
                          disabled={disableActionS4}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              <div className='nftListLabel'>Boost Info Missing</div>
            )}
          </div>

          <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container pb-24'>
            {allPawnFeeInfo ? (
              <>
                <div className='nftListLabel m-p-0'>
                  <h2
                    className='row cur-pointer m-p-0'
                    onClick={() => setShowPawnFee(!showPawnFee)}
                  >
                    Pawn Fee
                    <i
                      className={showPawnFee ? 'chevron' : 'chevron rotate'}
                    ></i>
                  </h2>
                </div>
                <div className={showPawnFee ? 'row col-12' : 'd-none'}>
                  <div className='col-12'>
                    <table className='tb-transaction table table-responsive table-striped text-white'>
                      <thead>
                        <tr>
                          <th>Loan Duration</th>
                          <th>Fee %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allPawnFeeInfo.map(
                          (item: PawnFeeInfoAdmin, index: number) => (
                            <tr key={index}>
                              <td>{durationDisplay(item.loanDurationTicks)}</td>
                              <td>{item.feePercentage}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  {IsOwnerWallet ? (
                    <>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>Loan Duration</label>
                        <select
                          className='col-12'
                          onChange={(e: any) => {
                            setSelectedLoanDurationS5(
                              allPawnFeeInfo[e.target.value]
                            );
                          }}
                        >
                          <option value='0' style={{ display: 'none' }}>
                            Loan Duration
                          </option>
                          {allPawnFeeInfo.map(
                            (item: PawnFeeInfoAdmin, index: number) => (
                              <option key={index} value={index}>
                                {durationDisplay(item.loanDurationTicks)}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className='col-6 col-sm-4 m-b-15'>
                        <label className='text-white'>
                          Fee % :{' '}
                          <span className='orange-text'>
                            {selectedLoanDurationS5?.feePercentage}
                          </span>
                        </label>
                        <input
                          type='text'
                          min='0'
                          max='30'
                          className='m-w-100 w-100 reqLoan text-right'
                          onChange={(e: any) =>
                            setInputS5(
                              enforceMinMax(
                                e.target.value,
                                e.target.min,
                                e.target.max,
                                inputS5
                              )
                            )
                          }
                          value={inputS5 ? inputS5 : ''}
                          disabled={!selectedLoanDurationS5}
                        ></input>
                      </div>
                      <div className='col-6 col-sm-2'></div>

                      <div className='col-6 col-sm-2 m-b-15'>
                        {/* <label className='text-white'>Loan Duration</label> */}
                        <label>&nbsp;</label>
                        <button
                          className='NFT__CardBody--ButtonStack btn btn-primary col-12'
                          onClick={pawnFeeAction}
                          disabled={disableActionS5}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              <div className='nftListLabel'>Pawn Fee Info Missing</div>
            )}
          </div>

          <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container pb-24'>
            <div className='nftListLabel m-p-0'>
              <h2
                className='row cur-pointer m-p-0'
                onClick={() => setShowOtherSettings(!showOtherSettings)}
              >
                Other Settings
                <i
                  className={showOtherSettings ? 'chevron' : 'chevron rotate'}
                ></i>
              </h2>
            </div>
            <div
              className={showOtherSettings ? 'col-12 m-b-15 m-p-0' : 'd-none'}
            >
              <div className='col-12 row m-b-15'>
                <label className='text-white m-0 col-6 col-sm-4'>
                  Smart Contract Frozen :
                  <span className='orange-text'>
                    {scFrozenState ? 'True' : 'False'}
                  </span>
                </label>
                {IsOwnerWallet ? (
                  <div className='col-6 col-sm-2'>
                    <button
                      className='NFT__CardBody--ButtonStack btn btn-primary col-12'
                      onClick={freezeUnfreezeContract}
                    >
                      {scFrozenState ? 'Unfreeze' : 'Freeze'}
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='col-12 row m-b-15'>
                <label className='text-white m-0 col-6 col-sm-4'>
                  Pawn Loan Enabled :
                  <span className='orange-text'>
                    {scPawnEnableState ? 'True' : 'False'}
                  </span>
                </label>
                {IsOwnerWallet ? (
                  <div className='col-6 col-sm-2'>
                    <button
                      className='NFT__CardBody--ButtonStack btn btn-primary col-12'
                      onClick={PawnEnableAction}
                    >
                      {scPawnEnableState ? 'Disable Pawn' : 'Enable Pawn'}
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {IsOwnerWallet ? (
                <div className='col-12 row m-b-15'>
                  <label className='text-white col-12'>
                    Add Recovery Amount:
                  </label>
                  <div className='col-6 col-sm-4'>
                    <input
                      type='text'
                      min='0'
                      max='100'
                      className='m-w-100 w-100 reqLoan text-right'
                      onChange={(e: any) =>
                        setInputS6(
                          enforceMinMax(
                            e.target.value,
                            e.target.min,
                            e.target.max,
                            inputS6
                          )
                        )
                      }
                      value={inputS6 ? inputS6 : ''}
                    ></input>
                  </div>
                  <div className='col-6 col-sm-2'>
                    <button
                      className='NFT__CardBody--ButtonStack btn btn-primary col-12'
                      onClick={recoveryAmountAction}
                      disabled={!inputS6}
                    >
                      Add
                    </button>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminSettings;
