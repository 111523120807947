import {
  Address,
  AddressValue,
  SmartContract,
  StringValue,
  TokenIdentifierValue,
  Transaction,
  U32Value,
  U64Value,
  U8Value
} from '@multiversx/sdk-core/out';

import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import { pawnShopContractAddress } from 'config';

import SmartContractService from './smart-contract-service';

export default class PawnShopSmartContract {
  private static contract: SmartContract;
  static async initializePawnShopSmartContract(
    contractAddress: string,
    abiJson: any
  ) {
    if (PawnShopSmartContract.contract == null || undefined) {
      PawnShopSmartContract.contract =
        await SmartContractService.createContract(contractAddress, abiJson, [
          'PawnShopContract'
        ]);
    }
  }

  static async getAllCompaniesInfo(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getAllNFTCompaniesInfo(
      []
    );

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getNFTCompanyInfoByName(
    company_name: string,
    proxy: ProxyNetworkProvider
  ) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methods.getNFTCompanyInfoByName([
      new StringValue(company_name)
    ]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getNFTCollectionByTokenIdentifier(
    collection_ticker: string,
    proxy: ProxyNetworkProvider
  ) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction =
      smartContract.methodsExplicit.getNFTCollectionByTokenIdentifier([
        new TokenIdentifierValue(collection_ticker)
      ]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async GetABIJsonAndInitialize() {
    const pawnShopAbiJson = await axios.get(
      './pawn_shop_smart_contract.abi.json'
    );

    await PawnShopSmartContract.initializePawnShopSmartContract(
      pawnShopContractAddress,
      pawnShopAbiJson.data
    );
  }

  static async getNFTCollectionByTier(
    tier: string,
    proxy: ProxyNetworkProvider
  ) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getNFTCollectionsByTier([
      new StringValue(tier)
    ]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getAllNFTCollections(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methods.getAllNFTCollections();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getAllTiers(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methods.getCollectionTiers();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getAllBoostLevels(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methods.getAllBoostLevels();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getOperatorAddresses(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methods.getOperatorAddresses();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getOwnerAddress(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methods.get_owner_address();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getAllBoostersInfo(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methods.getAllBoostersInfo();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getBoostInfoByBoostLevel(
    proxy: ProxyNetworkProvider,
    boostLevel: number
  ) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getBoostInfoByBoostLevel([
      new U8Value(boostLevel)
    ]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getAllPawnFeeInfo(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methods.getAllPawnFeeInfo();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getPawnFeeInfo(proxy: ProxyNetworkProvider, duration: number) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getPawnFeeInfo([
      new U64Value(duration)
    ]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getAllTiersLoanInfo(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methods.getAllTiersLoanInfo();

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getLoanInfoByTier(proxy: ProxyNetworkProvider, tier: number) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getLoanInfoByTier([
      new U8Value(tier)
    ]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getAllPawnedNFTsForUser(
    proxy: ProxyNetworkProvider,
    address: string
  ) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getAllPawnedNFTsForUser([
      new AddressValue(new Address(address))
    ]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getAllExpiredLoansInfo(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getAllExpiredLoansInfo(
      []
    );

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getPawnNFT(
    proxy: ProxyNetworkProvider,
    identifier: string,
    nonce: number,
    nft_counter: number
  ) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getPawnNFT([
      new TokenIdentifierValue(identifier),
      new U64Value(nonce),
      new U32Value(nft_counter)
    ]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getPawnHistoryForAddress(
    proxy: ProxyNetworkProvider,
    address: string
  ) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getPawnHistoryForAddress([
      new AddressValue(new Address(address))
    ]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getPawnShopStats(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.getPawnShopStats([]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getSCFrozenStatus(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.sc_frozen([]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getSCPawnEnableStatus(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction = smartContract.methodsExplicit.pawn_enable([]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async getAllLoanIssuedToCompanies(proxy: ProxyNetworkProvider) {
    if (PawnShopSmartContract.contract == null || undefined) {
      await PawnShopSmartContract.GetABIJsonAndInitialize();
    }

    const smartContract = PawnShopSmartContract.contract;
    const interaction =
      smartContract.methodsExplicit.getAllLoanIssuedToCompanies([]);

    const { firstValue } = await SmartContractService.getContractResult(
      interaction,
      proxy
    );

    if (firstValue) {
      //console.log(JSON.stringify(queryResult.firstValue.valueOf(), null, 2));
      return firstValue.valueOf();
    } else {
      console.error('getCollectionTokens query faild.');
      return null;
    }
  }

  static async sendTransaction(tx: Transaction, proxy: ProxyNetworkProvider) {
    // const smartContract = PawnShopSmartContract.contract;
    // const interaction = smartContract.methods.buy([]);
    // interaction.
    // const smartContract = PittzSmartContract.contract;
    return await SmartContractService.sendTransaction(tx, proxy);
  }
}
