import StepsModule from 'components/stepsModule';
import React from 'react';
// import { NFTmessage } from 'pages/Nft/types';

export interface MyProps {
  data: any;
  children: any;
}

const ConfirmModal = (props: React.PropsWithChildren<MyProps>) => {
  const { msg, title, type, activeStep } = props.data;
  return (
    <>
      {msg ? (
        <div className='confirm-modal'>
          <div className='backdrop'></div>
          <div className='confirm-modal-content'>
            <div className='confirm-modal-header'>
              {type === 'error' ? (
                <div className='error-icon'>
                  <span>X</span>
                </div>
              ) : type === 'warning' ? (
                <div className='warn-icon'>
                  <span>!</span>
                </div>
              ) : type === 'message' ? (
                <div className='msg-icon'>
                  <span>i</span>
                </div>
              ) : (
                ''
              )}
              {activeStep ? (
                <StepsModule activeStep={activeStep}></StepsModule>
              ) : (
                ''
              )}
              <div className='modal-label'>{title}</div>
            </div>
            <div className='confirm-modal-message'>
              <span className={type === 'message' ? 'text-white' : ''}>
                <span dangerouslySetInnerHTML={{ __html: msg }}></span>
              </span>
            </div>
            <div className='confirm-modal-footer'>{props.children}</div>
          </div>
        </div>
      ) : (
        ' '
      )}
    </>
  );
};

ConfirmModal.defaultProps = {
  type: 'error'
};

export default ConfirmModal;
