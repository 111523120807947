import React from 'react';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import { baseURL } from 'config';
import StakingSmartContract from 'Helper/staking-smart-contract';
import NftCard from './NftCard';

function StakedNFTs(props: any) {
  const { loaderHandler, OnTransactionSent } = props;
  const [stakedNFTsInfoFromSC, setStakedNFTsInfoFromSC] = React.useState<any[]>(
    []
  );
  const [stakedNFTsInfo, setStakedNFTsInfo] = React.useState<any[]>([]);
  const { network } = useGetNetworkConfig();
  const { address } = useGetAccountInfo();
  const [tags, setTags] = React.useState<{ [name: string]: string[] }>({});
  const pageId = 2;
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  async function loadWalletData() {
    const isLoggedIn = Boolean(address);
    if (isLoggedIn) {
      const tokens = await StakingSmartContract.getAllCollectionTokens(proxy);
      //get wallet's staked NFTs info
      const nfts = await StakingSmartContract.getStakedPassesForUser(
        address,
        tokens[0].toString(),
        proxy
      );
      if (nfts == null) {
        throw Error('nfts info error from smart contract');
      }

      setStakedNFTsInfoFromSC(nfts);
      let totalNFTsToProcess = nfts.length;

      let nftIndex = 0;

      const fetchedNFTs = [];
      while (totalNFTsToProcess > 0) {
        let allNFTsCommaSaperated = '';
        let upperBound = totalNFTsToProcess;
        if (totalNFTsToProcess > 25) {
          upperBound = 25;
          totalNFTsToProcess -= 25;
        } else {
          totalNFTsToProcess = 0;
        }
        for (let index = 0; index < upperBound; index++) {
          const nft = nfts[nftIndex];
          nftIndex++;
          const boosterTicker = nft.token_identifier.toString();
          let nonce = `${nft.nft_nonce.toString(16)}`;
          if (nonce.length % 2 !== 0) {
            nonce = `0${nonce}`;
          }

          allNFTsCommaSaperated += `${boosterTicker}-${nonce},`;
        }

        allNFTsCommaSaperated = allNFTsCommaSaperated.substring(
          0,
          allNFTsCommaSaperated.length - 1
        );

        const stakedNFTsUrl = `${baseURL}nfts?identifiers=${allNFTsCommaSaperated}`;
        const stakedNFTs = await axios.get(stakedNFTsUrl);
        if (stakedNFTs == null || undefined) {
          throw Error('nfts info could not found');
        }

        fetchedNFTs.push(...stakedNFTs.data);
        allNFTsCommaSaperated = '';
      }

      const nftTags: { [name: string]: string[] } = {};
      for (let nft_index = 0; nft_index < fetchedNFTs.length; nft_index++) {
        const nft = fetchedNFTs[nft_index];
        if (nft.attributes) {
          const attributes = atob(nft.attributes);
          const tagStartIndex = attributes.indexOf('tags:');
          if (tagStartIndex > 0) {
            const lastSemicolonIndex = attributes.indexOf(';', tagStartIndex);
            const tagsData = attributes.substring(
              tagStartIndex,
              lastSemicolonIndex
            );
            const tagsRawData = tagsData.split(':').pop();
            if (tagsRawData) {
              const tagsArray = tagsRawData.split(',');
              nftTags[nft.identifier] = tagsArray;
            }
          }
        } else {
          nftTags[nft.identifier] = [];
        }
      }

      setTags(nftTags);

      setStakedNFTsInfo(fetchedNFTs);
    }
  }

  React.useEffect(() => {
    loadWalletData();
  }, []);

  return (
    <>
      <div className='NFTContainerBody nft-with-cta'>
        {stakedNFTsInfoFromSC && stakedNFTsInfoFromSC.length === 0 ? (
          <div>
            <div className='nftListLabel'>No NFTs Available</div>
          </div>
        ) : (
          ''
        )}
        <div className='nft_container col-lg-10 col-md-11 col-12 margin-auto'>
          {stakedNFTsInfo ? (
            stakedNFTsInfo.length ? (
              stakedNFTsInfo.map((nftInfo: any, index: number) => {
                const stakedNFTInfo = stakedNFTsInfoFromSC.find(
                  (element) => element.nft_nonce.toNumber() == nftInfo.nonce
                );
                if (stakedNFTInfo)
                  return (
                    <NftCard
                      nftInfo={nftInfo}
                      stakedNFTInfoFromSC={stakedNFTInfo}
                      key={index}
                      action={'unstake'}
                      loaderHandler={loaderHandler}
                      pageId={pageId}
                      OnTransactionSent={OnTransactionSent}
                      tags={
                        tags[nftInfo.identifier] ? tags[nftInfo.identifier] : []
                      }
                    />
                  );
              })
            ) : (
              ''
            )
          ) : (
            <div>
              <div className='nftListLabel'>Loading NFTs...</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

StakedNFTs.defaultProps = {
  refresh: ''
};
export default StakedNFTs;
