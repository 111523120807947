import * as React from 'react';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import {
  useGetAccountInfo,
  useGetLoginInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { LoginMethodsEnum } from '@multiversx/sdk-dapp/types';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import { Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import ConfirmModal from 'components/ConfirmModal';
import Timer from 'components/timer';
import { baseURL, explorer_url, pawnShopContractAddress } from 'config';
import CommonHelper from 'Helper/common-helper';
import PawnShopSmartContract from 'Helper/pawn-shop-smart-contract';
import { routeNames } from 'routes';
import loader from '../assets/loader.gif';
import TrackTransaction from './TrackTransaction';
import { NFTmessage } from './types/NFTMessage';
import { PawnNFT } from './types/PawnLoanType';
import ModalCard from 'components/ModalCard';

const ClaimNFT = () => {
  const { address } = useGetAccountInfo();
  const { loginMethod } = useGetLoginInfo();
  const { network } = useGetNetworkConfig();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [selectedPawnedNFTs, setSelectedPawnedNFTs] = React.useState<PawnNFT[]>(
    []
  );
  const [isFlipped, setIsFlipped] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [loanExpired, setLoanExpired] = React.useState<boolean>(false);
  const [sessionID, setSessionId] = React.useState<string>('');
  const [totalLoanAmount, setTotalLoanAmount] = React.useState<number>(0);
  const [totalLoanFee, setTotalLoanFee] = React.useState<number>(0);
  const [totalAmountDue, setTotalAmountDue] = React.useState<number>(0);
  const [msgObj, setMsgObj] = React.useState<NFTmessage>();
  const [durationToDisplay, setDurationToDisplay] = React.useState<string>('');
  const claimRenewOperationCompletedRef = React.useRef(false);
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });

  const location: any = useLocation();
  const [confirmPawn, setConfirmPawn] = React.useState<NFTmessage>();
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  let { selectedNftIdentifiers } = location.state ? location.state : '';
  if (selectedNftIdentifiers == undefined || null) {
    selectedNftIdentifiers = urlParams
      .get('selectedNftIdentifiers')
      ?.split(',');
  }
  if (
    selectedNftIdentifiers == undefined ||
    null ||
    selectedNftIdentifiers.length < 1
  ) {
    window.location.href = routeNames.pawned;
    //Page redirect to collections page
  }

  const selectedNFTsObject: any[] = [];

  for (let index = 0; index < selectedNftIdentifiers.length; index++) {
    const element = selectedNftIdentifiers[index];
    // let nftIdentifier = selectedNftIdentifiers.length
    // ? selectedNftIdentifiers[0]
    // : '';
    const nftCollection = `${element.split('-')[0]}-${element.split('-')[1]}`;
    const nftNonce = element.split('-')[2];
    const nftCounter = element ? element.split('-')[3] : '';

    selectedNFTsObject.push({
      token_identifier: nftCollection,
      nonce: nftNonce,
      nftCounter: nftCounter
    });
  }

  async function loadPawnedNFTForUser() {
    const pawnedNFTsLocal = await CommonHelper.loadPawnedNFTsForUser(
      proxy,
      address
    );

    let totalLoanAmountValue = 0;
    let totalLoanFeeValue = 0;
    let totalAmountDueValue = 0;
    const filterPawnedNFTs: PawnNFT[] = [];
    for (let index = 0; index < selectedNFTsObject.length; index++) {
      const selectedNftData = selectedNFTsObject[index];
      const filteredData = pawnedNFTsLocal.find(
        (x) =>
          x.tokenIdentifier === selectedNftData.token_identifier &&
          x.nonce === parseInt(selectedNftData.nonce, 16) &&
          x.nftCounter === parseInt(selectedNftData.nftCounter)
      );
      if (filteredData) {
        filterPawnedNFTs.push(filteredData);
        totalLoanAmountValue += filteredData.loanAmount;

        totalLoanFeeValue += filteredData.pawnFee;

        totalAmountDueValue += filteredData.loanAmount + filteredData.pawnFee;
      }
    }

    setTotalLoanAmount(
      CommonHelper.fixedNum(totalLoanAmountValue / 10 ** 18, 6)
    );
    setTotalLoanFee(CommonHelper.fixedNum(totalLoanFeeValue / 10 ** 18, 6));
    setTotalAmountDue(CommonHelper.fixedNum(totalAmountDueValue / 10 ** 18, 6));
    setSelectedPawnedNFTs(filterPawnedNFTs);
    if (filterPawnedNFTs.length > 0) {
      const duration =
        filterPawnedNFTs && filterPawnedNFTs[0].loanDurationTicks;
      let durationToDisplaylocal = '';
      if (duration) {
        if (duration % (24 * 60 * 60) == 0) {
          durationToDisplaylocal = `${duration / (24 * 60 * 60)} Days`;
        } else if (duration % (60 * 60) == 0) {
          durationToDisplaylocal = `${duration / (60 * 60)} Hours`;
        } else if (duration % 60 == 0) {
          durationToDisplaylocal = `${duration / 60} Minute`;
        }
      }

      setDurationToDisplay(durationToDisplaylocal);
    }

    // console.log(pawnedNFTData);
  }

  React.useEffect(() => {
    const currentSessionId = sessionStorage.getItem('SessionId');
    if (currentSessionId) {
      setSessionId(currentSessionId);
    }
    loadPawnedNFTForUser();
  }, []);

  const isloanExpired = (data: boolean) => {
    setLoanExpired(data);
  };

  async function loaderHandler(
    status: boolean,
    refreshData: boolean,
    response?: any,
    message?: NFTmessage
  ) {
    // console.log('empty function');
    if (response) {
      if (response.action.name == 'renew_loan') {
        claimRenewOperationCompletedRef.current = false;
        if (response.status === 'success') {
          message = {
            msg: `Loan Renewal Successful. <br/> To see the transaction on blockchain explorer. <a target='_blank' href=${explorer_url}/transactions/${response.txHash}>Click Here</a>`,
            type: 'message',
            title: ''
          };
        }
      } else if (response.status === 'success') {
        claimRenewOperationCompletedRef.current = true;
        let properMsgCreated = false;
        if (response.operations && response.operations.length > 0) {
          for (let index = 0; index < response.operations.length; index++) {
            const operationResult = response.operations[index];
            if (
              operationResult.action.toUpperCase() === 'TRANSFER' &&
              operationResult.receiver == address &&
              operationResult.type.toUpperCase() == 'NFT'
            ) {
              message = {
                msg: `NFT Successfully transferred to account ${address}. <br/> To see the transaction on blockchain explorer. <a target='_blank' href=${explorer_url}/transactions/${response.txHash}>Click Here</a>`,
                type: 'message',
                title: ''
              };
              properMsgCreated = true;
            }
          }
        }
        if (!properMsgCreated) {
          message = {
            msg: `Transaction Successful.To see the transaction on blockchain explorer. <br/> <a target='_blank' href=${explorer_url}/transactions/${response.txHash}>Click Here</a>`,
            type: 'message',
            title: ''
          };
        }
        //}
      }
    }

    setIsLoading(status);
    if (message) {
      message = CommonHelper.CreateReadableMsg(message);
      setMsgObj(message);
    }
  }

  const claimNFTAction = async () => {
    if (selectedPawnedNFTs) {
      let claimCommand = 'claim_nfts';
      let amount = 0;
      const nftsDetailsPlainObject = [];
      for (let index = 0; index < selectedPawnedNFTs.length; index++) {
        const pawnedNFT = selectedPawnedNFTs[index];
        amount += pawnedNFT.loanAmount + pawnedNFT.pawnFee;
        const collectionInHex = CommonHelper.convertToHex(
          pawnedNFT.tokenIdentifier
        );
        let nftCounterInHex = pawnedNFT.nftCounter.toString(16);
        if (nftCounterInHex.length % 2 == 1) {
          nftCounterInHex = `0${nftCounterInHex}`;
        }

        let nonceInHex = pawnedNFT.nonce.toString(16);
        if (nonceInHex.length % 2 == 1) {
          nonceInHex = `0${nonceInHex}`;
        }

        claimCommand += `@${collectionInHex}@${nonceInHex}@${nftCounterInHex}`;

        nftsDetailsPlainObject.push({
          token_identifier: collectionInHex,
          nonce: nonceInHex,
          nftCounter: pawnedNFT.nftCounter
        });
      }
      let gasAmount;
      if (selectedNftIdentifiers.length < 3) {
        gasAmount = 40000000;
      } else {
        gasAmount = 12000000 * selectedNftIdentifiers.length;
      }
      const claimTransaction = {
        value: amount,
        data: claimCommand,
        receiver: pawnShopContractAddress,
        gasLimit: gasAmount
      };

      const plainClaimObject = {
        action: 'claim_nfts',
        nftDetails: nftsDetailsPlainObject,
        sender: address,
        scAddress: pawnShopContractAddress,
        loanAmountToPay: amount
      };

      await refreshAccount();
      let callbackRoute;
      if (loginMethod == LoginMethodsEnum.wallet) {
        callbackRoute = `/claimNFT?selectedNftIdentifiers=${selectedNftIdentifiers.join()}`;
      }
      const { sessionId, error } = await sendTransactions({
        transactions: claimTransaction,
        callbackRoute: callbackRoute,
        transactionsDisplayInfo: {
          processingMessage: 'claim transaction is in progress',
          errorMessage: 'An error has occured during claim process',
          successMessage: 'claim transaction successful',
          transactionDuration: 20000
        },
        signWithoutSending: true
      });

      if (error != undefined || null) {
        loaderHandler(false, false);
        console.log(error);
      } else if (sessionId != null) {
        sessionStorage.setItem('SessionId', sessionId);
        const sessionTransactionObject: { [name: string]: any } = {};
        sessionTransactionObject[sessionId] = plainClaimObject;
        sessionStorage.setItem(
          'currentTransaction',
          JSON.stringify(sessionTransactionObject)
        );
        if (loginMethod == LoginMethodsEnum.wallet) {
          sessionStorage.setItem('SessionId', sessionId);
        } else {
          setSessionId(sessionId);
        }
      }
    }
  };

  const openConfirmRenew = () => {
    const legalDisclaimer = `
    <div>        
        <p>
            Are you sure you want to renew your loan for an additional fee of "${totalLoanFee}" egld.
        </p>        
    </div>`;
    setConfirmPawn({
      msg: legalDisclaimer,
      type: '',
      title: 'Pawn Whale Renew Loan Terms and Conditions'
    });
  };
  const cancelPawn = () => {
    setConfirmPawn({ msg: '', type: '', title: '' });
  };
  const renewNFTAction = async () => {
    setConfirmPawn({ msg: '', type: '', title: '' });
    if (selectedPawnedNFTs) {
      const collectionInHex = CommonHelper.convertToHex(
        selectedPawnedNFTs[0].tokenIdentifier
      );
      let renewCommand = 'renew_loans';
      let amount = 0;
      const nftsDetailsPlainObject = [];
      for (let index = 0; index < selectedPawnedNFTs.length; index++) {
        const pawnedNFT = selectedPawnedNFTs[index];
        amount += pawnedNFT.pawnFee;
        const collectionInHex = CommonHelper.convertToHex(
          pawnedNFT.tokenIdentifier
        );
        let nftCounterInHex = pawnedNFT.nftCounter.toString(16);
        if (nftCounterInHex.length % 2 == 1) {
          nftCounterInHex = `0${nftCounterInHex}`;
        }

        let nonceInHex = pawnedNFT.nonce.toString(16);
        if (nonceInHex.length % 2 == 1) {
          nonceInHex = `0${nonceInHex}`;
        }

        renewCommand += `@${collectionInHex}@${nonceInHex}@${nftCounterInHex}`;

        nftsDetailsPlainObject.push({
          token_identifier: collectionInHex,
          nonce: nonceInHex,
          nftCounter: pawnedNFT.nftCounter
        });
      }
      let gasAmount;
      if (selectedNftIdentifiers.length < 3) {
        gasAmount = 40000000;
      } else {
        gasAmount = 12000000 * selectedNftIdentifiers.length;
      }
      // const gasAmount = 12000000 * selectedNftIdentifiers.length;
      const renewTransaction = {
        value: amount,
        data: renewCommand,
        receiver: pawnShopContractAddress,
        gasLimit: gasAmount
      };

      const plainRenewObject = {
        action: 'renew_loans',
        nftDetails: nftsDetailsPlainObject,
        sender: address,
        scAddress: pawnShopContractAddress,
        loanAmountToPay: amount
      };

      await refreshAccount();
      let callbackRoute;
      if (loginMethod == LoginMethodsEnum.wallet) {
        callbackRoute = `/claimNFT?selectedNftIdentifiers=${selectedNftIdentifiers.join()}`;
      }
      const { sessionId, error } = await sendTransactions({
        transactions: renewTransaction,
        callbackRoute: callbackRoute,
        transactionsDisplayInfo: {
          processingMessage: 'renew transaction is in progress',
          errorMessage: 'An error has occured during renew process',
          successMessage: 'renew transaction successful',
          transactionDuration: 20000
        },
        signWithoutSending: true
      });

      if (error != undefined || null) {
        loaderHandler(false, false);
        console.log(error);
      } else if (sessionId != null) {
        sessionStorage.setItem('SessionId', sessionId);
        const sessionTransactionObject: { [name: string]: any } = {};
        sessionTransactionObject[sessionId] = plainRenewObject;
        sessionStorage.setItem(
          'currentTransaction',
          JSON.stringify(sessionTransactionObject)
        );
        if (loginMethod == LoginMethodsEnum.wallet) {
          sessionStorage.setItem('SessionId', sessionId);
        } else {
          setSessionId(sessionId);
        }
      }
    }
  };

  const okFunc = async () => {
    setMsgObj({ msg: '', type: '', title: '' });
    // if (claimRenewOperationCompletedRef.current) {
    navigate(routeNames.pawned, {
      state: {}
    });
    // }
    // else{
    //   await loadPawnedNFTForUser();
    // }
  };
  const [cardSelected, setCardSelected] = React.useState(false);
  const cn =
    (cardSelected ? 'selected' : '') + (isFlipped ? ' is-flipped' : '');

  console.log(selectedPawnedNFTs);
  return (
    <>
      {isLoading ? (
        <div className='loader'>
          <div className='loader_content'>
            {/* <img src={loader} /> */}
            <svg
              aria-hidden='true'
              focusable='false'
              data-prefix='fat'
              data-icon='spinner-third'
              className='svg-inline--fa fa-spinner-third fa-5x primary fa-spin fast-spin modal-layout-loader-icon'
              role='img'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 576 512'
            >
              <path
                fill='currentColor'
                d='M280 8C280 3.582 283.6 0 288 0C429.4 0 544 114.6 544 256C544 302.6 531.5 346.4 509.7 384C507.5 387.9 502.6 389.2 498.8 386.9C494.1 384.7 493.7 379.8 495.9 376C516.3 340.7 528 299.7 528 256C528 123.5 420.5 16 288 16C283.6 16 280 12.42 280 8V8z'
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        ''
      )}
      {selectedPawnedNFTs.length > 0 ? (
        <div className='row col-lg-10 col-md-11 col-12 margin-auto pawnNft-container m-t-40'>
          <Col
            xs={12}
            sm={5}
            md={4}
            className='pawn-display-card nft-with-cta margin-auto'
          >
            <div className='scene scene--card col-lg-3 col-md-4 col-6'>
              {selectedNftIdentifiers.length > 1 ? (
                <label className='nft-counter'>
                  {selectedNftIdentifiers.length}
                </label>
              ) : (
                ''
              )}
              <Card border='info' className={cn}>
                <div className='NFT__Card card__face card__face--front note'>
                  <div
                    className='NFT__Card--Img_Container cur-pointer'
                    onClick={() => setOpenModal(!openModal)}
                  >
                    <video
                      poster={selectedPawnedNFTs[0].url}
                      autoPlay
                      playsInline
                      muted
                      loop
                      className='NFT__Card--Img'
                    >
                      <source
                        src={selectedPawnedNFTs[0].url}
                        type='video/mp4'
                      />
                    </video>
                  </div>
                  <Card.Body className='NFT__CardBody'>
                    <div className='NFT__CardBody-details'>
                      <Card.Title className='NFT__CardBody--Title'>
                        {/* {pawnedNFT.tokenIdentifier}-{nonceInHex} */}
                        {selectedPawnedNFTs[0].nftCommonName}
                      </Card.Title>
                    </div>
                  </Card.Body>
                </div>
                <div
                  className='card__face card__face--back '
                  onClick={() => setIsFlipped(!isFlipped)}
                >
                  <div className='card__face--back-container note'>
                    <Card.Title className='orange-text font-18 text-center'>
                      Tags:
                    </Card.Title>
                    {selectedPawnedNFTs[0].tags.length ? (
                      <div className='nft-tags-container'>
                        {selectedPawnedNFTs[0].tags.map(
                          (tag: string, index: number) => (
                            <span className='nft-tags' key={index}>
                              {tag}
                            </span>
                          )
                        )}
                      </div>
                    ) : (
                      <Card.Title className='text-white'>
                        No Tags Available
                      </Card.Title>
                    )}
                  </div>
                </div>
              </Card>
              {selectedNftIdentifiers.length > 1 ? (
                <Card border='info' className={cn + ' card-clone '}>
                  <div className='NFT__Card card__face card__face--front note'>
                    <div
                      className='NFT__Card--Img_Container cur-pointer'
                      onClick={() => setOpenModal(!openModal)}
                    >
                      <video
                        poster={selectedPawnedNFTs[0].url}
                        autoPlay
                        playsInline
                        muted
                        loop
                        className='NFT__Card--Img'
                      >
                        <source
                          src={selectedPawnedNFTs[0].url}
                          type='video/mp4'
                        />
                      </video>
                    </div>
                    <Card.Body className='NFT__CardBody'>
                      <div className='NFT__CardBody-details'>
                        {/* <Card.Title className='NFT__CardBody--Title'>{name}</Card.Title> */}
                        <Card.Title className='NFT__CardBody--Title'>
                          {/* {identifier} */}
                          &nbsp;
                        </Card.Title>
                      </div>
                    </Card.Body>
                  </div>
                </Card>
              ) : (
                ''
              )}
            </div>
          </Col>
          <Col xs={11} sm={6} md={7} className='row margin-auto p-0'>
            <div className='pawnNft-container m-b-15'>
              {selectedNftIdentifiers.length === 1 ? (
                <>
                  <div className='new-nft-details col-12'>
                    <h4 className='text-white'>
                      <label className='blue-text'>Loan Duration:</label>
                      <label>{durationToDisplay}</label>
                    </h4>
                  </div>

                  <div className='new-nft-details col-12'>
                    <h4 className='text-white'>
                      <label className='blue-text'>Loan Due by:</label>
                      <label className={loanExpired ? 'timer-expired' : ''}>
                        <Timer
                          startTime={new Date().getTime()}
                          endTime={
                            selectedPawnedNFTs[0].extendedExpiryDate * 1000
                          }
                          isloanExpired={(data: boolean) => isloanExpired(data)}
                        ></Timer>
                      </label>
                    </h4>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className='new-nft-details col-12'>
                <h4 className='text-white'>
                  <label className='blue-text'>Loan Amount:</label>
                  <label>{totalLoanAmount}</label>
                </h4>
              </div>
              <div className='new-nft-details col-12'>
                <h4 className='text-white'>
                  <label className='blue-text'>Fee:</label>
                  <label>{totalLoanFee}</label>
                </h4>
              </div>
              <div className='new-nft-details col-12'>
                <h4 className='text-white'>
                  <label className='blue-text'>Amount Due:</label>
                  <label>{totalAmountDue}</label>
                </h4>
              </div>

              {/* <div className='nft-details col-5 col-md-3'>
              <h4 className='text-white'>
                <label className='blue-text'>Boost Level:</label>
                <label>{pawnedNFT.boostLevel}</label>
              </h4>
            </div>

            <div className='nft-details col-5 col-md-3'>
              <h4 className='text-white'>
                <label className='blue-text'>Boost Amount:</label>
                <label>
                  {CommonHelper.fixedNum(
                    pawnedNFT.loanBoostAmount / 10 ** 18,
                    4
                  )}
                </label>
              </h4>
            </div>

            <div className='nft-details col-5 col-md-3'>
              <h4 className='text-white'>
                <label className='blue-text'>Fee Discount:</label>
                <label>
                  {CommonHelper.fixedNum(pawnedNFT.feeDiscount / 10 ** 18, 6)}
                </label>
              </h4>
            </div> */}
            </div>
          </Col>
          <Col xs={12} className='row margin-auto'>
            <div className='nftListLabel orange-text egld-text'>
              **All Values are in EGLD**
            </div>
          </Col>
          <Col xs={12} className='claim-renew-btn-section'>
            <button
              className='NFT__CardBody--ButtonStack btn btn-primary pawn-btn col-12'
              disabled={loanExpired}
              onClick={() => claimNFTAction()}
            >
              PAY LOAN
            </button>
            <button
              className='NFT__CardBody--ButtonStack btn btn-primary pawn-btn col-12'
              disabled={loanExpired}
              onClick={() => openConfirmRenew()}
            >
              RENEW LOAN
            </button>
          </Col>
        </div>
      ) : (
        ''
      )}
      {sessionID ? (
        <TrackTransaction
          sessionId={sessionID}
          loaderHandler={loaderHandler}
          status={true}
        ></TrackTransaction>
      ) : (
        ''
      )}
      {msgObj && msgObj.msg ? (
        <ConfirmModal data={msgObj}>
          <button
            className='ok-btn'
            onClick={() => {
              okFunc();
            }}
          >
            Ok
          </button>
        </ConfirmModal>
      ) : (
        ''
      )}
      {confirmPawn && confirmPawn.msg ? (
        <div className='pawn-modal'>
          <ConfirmModal data={confirmPawn}>
            <button className='ok-btn' onClick={() => renewNFTAction()}>
              Ok
            </button>
            <button className='ok-btn' onClick={() => cancelPawn()}>
              Cancel
            </button>
          </ConfirmModal>
        </div>
      ) : (
        ''
      )}
      {selectedNftIdentifiers && openModal ? (
        <div className='pawn-modal'>
          <div className='confirm-modal'>
            <div className='backdrop'></div>
            <div className='confirm-modal-content p-0'>
              <div className='custom-modal-header orange-text nftListLabel'>
                Selected NFT(s)
                <span
                  className='close orange-text'
                  onClick={() => setOpenModal(false)}
                >
                  X
                </span>
              </div>
              <div className='row nft_container nft-with-cta scroll-container'>
                {selectedPawnedNFTs.map((nftInfo: any, index: number) => {
                  return (
                    <ModalCard key={index} selectedNFTs={nftInfo}></ModalCard>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default ClaimNFT;
