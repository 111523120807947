import React from 'react';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { useLocation, useSearchParams } from 'react-router-dom';
import CommonHelper from 'Helper/common-helper';
import { routeNames } from 'routes';
import { NFTCollection } from './types/PawnLoanType';
import WalletNFTs from './WalletNFTs';
import { Unlock } from 'pages/unlock';
import StepsModule from 'components/stepsModule';

function CollectionNFTs(props: any) {
  sessionStorage.setItem('currentRoute', 'collectionNFTs');
  const currentRoute = 'collectionNFTs';
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const location: any = useLocation();

  if (!isLoggedIn) {
    // navigate(routeNames.unlock);
    //window.location.href = routeNames.unlock;
  }
  const { network } = useGetNetworkConfig();
  const [nftCollectionInfo, setNFTCollectionInfo] =
    React.useState<NFTCollection>();
  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  const [urlParams] = useSearchParams();

  const selectedCollection: string =
    location.state || urlParams.get('selectedCollection');
  if (!selectedCollection) {
    window.location.href = routeNames.collections;
  }
  // if (!location.state) {
  //   window.location.href = routeNames.collections;
  // }
  async function loadCollectionDetails() {
    const collectionInfoFromSC = await CommonHelper.loadCollectionDetails(
      proxy,
      selectedCollection
    );
    // console.log(collectionInfoFromSC);
    if (collectionInfoFromSC !== null || undefined) {
      setNFTCollectionInfo(collectionInfoFromSC);
    }
  }

  React.useEffect(() => {
    loadCollectionDetails();
  }, [props.refresh]);

  return (
    <>
      {!isLoggedIn ? (
        <Unlock />
      ) : (
        <>
          <StepsModule activeStep={1}></StepsModule>
          <div className='row col-lg-10 col-md-11 col-12 margin-auto'>
            {nftCollectionInfo ? (
              <>
                <div className='collection-info'>
                  <span>{nftCollectionInfo.collectionName}</span>
                  <span>{nftCollectionInfo.collectionTier}</span>
                  <span>Floor Price: {nftCollectionInfo.floorPrice}</span>
                  <span>
                    Base Loan:{' '}
                    {CommonHelper.fixedNum(
                      (nftCollectionInfo.floorPrice *
                        nftCollectionInfo.collection_max_loan_percentage) /
                        100,
                      4
                    )}
                  </span>
                </div>
                <div className='pawnNft-container'>
                  <WalletNFTs
                    action={'pawn'}
                    collectionIdentifiers={[
                      nftCollectionInfo.collectionTokenIdentifier
                    ]}
                    collectionInfo={nftCollectionInfo}
                    msg={'Loading NFTs...'}
                  ></WalletNFTs>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </>
  );
}

export default CollectionNFTs;
