import * as React from 'react';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import ConfirmModal from 'components/ConfirmModal';
import { baseURL } from 'config';
import StakingSmartContract from 'Helper/staking-smart-contract';
import { routeNames } from 'routes';
import loader from '../assets/loader.gif';
import StakedNFTs from './StakedNFTs';
import TrackTransaction from './TrackTransaction';
import { NFTmessage } from './types/NFTMessage';
import WalletNFTs from './WalletNFTs';

const Overview = () => {
  sessionStorage.setItem('currentRoute', 'overview');
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const [sessionId, setSessionId] = React.useState<string>('');
  const [msgObj, setMsgObj] = React.useState<NFTmessage>();
  if (!isLoggedIn) {
    window.location.href = routeNames.unlock;
  }

  const { network } = useGetNetworkConfig();
  const [unstakedNFTCount, setUnStakedNFTCount] = React.useState<number>(0);
  const [stakedNFTsFromSC, setStakeNFTsFromSC] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [selectedtab, setSelectedtab] = React.useState<string>('one');
  const [transactionPending, setTransactionPending] =
    React.useState<boolean>(true);

  const proxy = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 15000
  });
  const [collectionTokens, setCollectionTokens] = React.useState<string[]>([]);

  async function loadCollectionTokens() {
    const collectionTokensFromSC =
      await StakingSmartContract.getAllCollectionTokens(proxy);
    const collectionTokensArr = [];
    for (let index = 0; index < collectionTokensFromSC.length; index++) {
      collectionTokensArr.push(collectionTokensFromSC[index].toString());
    }
    setCollectionTokens(collectionTokensArr);
  }

  async function startTrackTrasaction() {
    const currentSessionId = sessionStorage.getItem('SessionId');
    // console.log('session Id first');
    if (currentSessionId) {
      setTransactionPending(true);
      setSessionId(currentSessionId);
    } else {
      setTransactionPending(false);
    }
  }
  React.useEffect(() => {
    loadCollectionTokens();
    startTrackTrasaction();
  }, []);

  async function loadStakedData() {
    // console.log('loadStakedData');
    if (isLoggedIn) {
      //get wallet's staked NFTs info
      const nfts = await StakingSmartContract.getStakedPassesForUser(
        address,
        collectionTokens[0].toString(),
        proxy
      );
      if (nfts == null) {
        throw Error('nfts info error from smart contract');
      }

      setStakeNFTsFromSC(nfts); //Staked NFT Count on Overview page
    }
  }

  async function loadUnStakedNFTTotalCount() {
    // console.log('loadUnStakedNFTTotalCount');
    //Get Passes in the wallet
    const unStakedNFTCountURL = `${baseURL}accounts/${address}/nfts/count?collections=${collectionTokens[0].toString()}`;
    const walletNFTsInfo = await axios.get(unStakedNFTCountURL);
    setUnStakedNFTCount(walletNFTsInfo.data);
  }
  React.useEffect(() => {
    if (!transactionPending && collectionTokens.length > 0) {
      loadUnStakedNFTTotalCount();
    }
  }, [collectionTokens, transactionPending]);

  React.useEffect(() => {
    if (!transactionPending && collectionTokens.length > 0) {
      loadStakedData();
    }
  }, [collectionTokens, transactionPending]);

  async function loaderHandler(
    status: boolean,
    refreshData: boolean,
    response: any,
    message?: NFTmessage
  ) {
    if (refreshData) {
      setTransactionPending(false);
    }

    if (response) {
      if (response.status === 'success') {
        if (response.function === 'stake_pawn_pass' && response.operations) {
          message = {
            msg: 'Pass Staked Successfully.',
            type: 'message',
            title: ''
          };
        } else if (
          response.function === 'unstake_pawn_pass' &&
          response.operations
        ) {
          message = {
            msg: 'Pass Unstaked Successfully',
            type: 'message',
            title: ''
          };
        }
      }
    }
    setIsLoading(status);
    if (message) {
      setMsgObj(message);
    }
  }

  async function OnTransactionSent() {
    // setTimeout(startTrackTrasaction, 10000);
    startTrackTrasaction();
  }

  const okFunc = () => {
    setMsgObj({ msg: '', type: '', title: '' });
    // console.log(e);
  };
  const tabChanged = (activeTab: string) => {
    setSelectedtab(activeTab);
    sessionStorage.setItem('activeTab', activeTab);
  };
  React.useEffect(() => {
    const getSelTab = sessionStorage.getItem('activeTab');
    if (getSelTab) {
      setSelectedtab(getSelTab);
    }
  }, []);
  return (
    <div>
      {isLoading ? (
        <div className='loader'>
          <div className='loader_content'>
            {/* <img src={loader} /> */}
            <svg
              aria-hidden='true'
              focusable='false'
              data-prefix='fat'
              data-icon='spinner-third'
              className='svg-inline--fa fa-spinner-third fa-5x primary fa-spin fast-spin modal-layout-loader-icon'
              role='img'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 576 512'
            >
              <path
                fill='currentColor'
                d='M280 8C280 3.582 283.6 0 288 0C429.4 0 544 114.6 544 256C544 302.6 531.5 346.4 509.7 384C507.5 387.9 502.6 389.2 498.8 386.9C494.1 384.7 493.7 379.8 495.9 376C516.3 340.7 528 299.7 528 256C528 123.5 420.5 16 288 16C283.6 16 280 12.42 280 8V8z'
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='nftListLabel orange-text text-center font-size-22 row col-lg-10 col-md-11 col-12 margin-auto m-t-40'>
        **Stake Pawn Whale Passes to borrow more money and pay less fees.**
      </div>
      <section className='staking-overview'>
        {/* <h2
          className='row col-lg-10 col-md-11 col-12 orange-text page-heading cur-pointer margin-auto'
          onClick={() => setShowStaking(!showStaking)}
        >
          Staking Overview{' '}
          <i className={showStaking ? 'chevron' : 'chevron rotate'}></i>
        </h2> */}

        <br />
        <div className='row col-lg-10 col-md-11 col-12 margin-auto'>
          <article className='tabs'>
            <div className='tab-labels row col-12 m-p-0'>
              <input
                id='one'
                name='tabs'
                type='radio'
                checked={selectedtab === 'one'}
                onChange={() => tabChanged('one')}
              />
              <label className='col-6' htmlFor='one'>
                Stake Passes ({unstakedNFTCount})
              </label>
              <input
                id='two'
                name='tabs'
                type='radio'
                value='Two'
                checked={selectedtab === 'two'}
                onChange={() => tabChanged('two')}
              />
              <label className='col-6' htmlFor='two'>
                Unstake Passes ({stakedNFTsFromSC.length})
              </label>
              <div className='panels'>
                <div className='panel'>
                  {transactionPending ? (
                    ''
                  ) : collectionTokens.length > 0 ? (
                    <WalletNFTs
                      loaderHandler={loaderHandler}
                      OnTransactionSent={OnTransactionSent}
                      collectionIdentifiers={collectionTokens}
                      action='stake'
                      msg={'Loading NFTs...'}
                    ></WalletNFTs>
                  ) : (
                    <div className='nftListLabel'>Loading NFTs...</div>
                  )}
                </div>
                <div className='panel'>
                  {transactionPending ? (
                    ''
                  ) : (
                    <StakedNFTs
                      loaderHandler={loaderHandler}
                      OnTransactionSent={OnTransactionSent}
                    ></StakedNFTs>
                  )}
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
      {sessionId ? (
        <TrackTransaction
          sessionId={sessionId}
          loaderHandler={loaderHandler}
          status={true}
        ></TrackTransaction>
      ) : (
        ''
      )}
      {msgObj && msgObj.msg ? (
        <ConfirmModal data={msgObj}>
          <button
            className='ok-btn'
            onClick={() => {
              okFunc();
            }}
          >
            Ok
          </button>
        </ConfirmModal>
      ) : (
        ''
      )}
    </div>
  );
};

export default Overview;
